import { createReducer } from './utils';

import {
  SLACK_CHANNELS_REQUEST,
  SLACK_CHANNELS_AVAILABLE,
  SLACK_CHANNELS_UNAVAILABLE,
} from './constants';

const defaultState = {
  loading_channels: false,
  slackChannels: false,
  slackChannelsList: [],
};

export const reducer = createReducer(defaultState, {
  [SLACK_CHANNELS_REQUEST]: handleSlackChannelsRequest,
  [SLACK_CHANNELS_AVAILABLE]: handleSlackChannelsAvailable,
  [SLACK_CHANNELS_UNAVAILABLE]: handleSlackChannelsUnavailable,
});

function handleSlackChannelsRequest(state, _) {
  return {
    ...state,
    loading_channels: true,
  };
}

function handleSlackChannelsAvailable(state, { payload: { channels } }) {
  return {
    ...state,
    loading_channels: false,
    slackChannelsList: channels,
    slackChannels: true,
  };
}

function handleSlackChannelsUnavailable(state, _) {
  return defaultState;
}

export function getSlackChannels() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: SLACK_CHANNELS_REQUEST });
    try {
      const channels = await dataSource.getSlackChannels();
      dispatch({
        type: SLACK_CHANNELS_AVAILABLE,
        payload: { channels },
      });
    } catch (error) {
      dispatch({
        type: SLACK_CHANNELS_UNAVAILABLE,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
