import { Box, Paper, CircularProgress, Typography, Stack, Link, Alert, AlertTitle } from "@mui/material";
import MDEditor from '@uiw/react-md-editor';
import moment from 'moment';
import CodeMirror from '@uiw/react-codemirror';
import { dracula } from '@uiw/codemirror-themes-all';
import { langs } from '@uiw/codemirror-extensions-langs';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as ColorIcon } from '../../../static/images/gitlab-logo-500.svg';
export default function FileDisplay(props) {
  const { fileData, isLoadingFileData, selectedFile } = props;
  const { content, short_id, date, url, file_name } = fileData;
  return (
    <Box sx={{ maxWidth: "90vw" }} data-color-mode="light">
      <Paper elevation={0}
        sx={{
          paddingY: 2,
          paddingX: 3,
          borderRadius: 3,
          minHeight: "550px",
        }}>

        {isLoadingFileData ?
          <Box sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "grid",
            minHeight: "550px",
          }}>
            <CircularProgress
              color="info"
              size={100}
            />
          </Box>
          :
          file_name ?
            <Box>
              <Box sx={{ padding: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
                  <Typography fontWeight={"bold"}>
                    {selectedFile}
                  </Typography>
                </Box>

                <Stack direction={"row-reverse"} spacing={0} alignItems={"center"} >
                  <SvgIcon fontSize="large"><ColorIcon /></SvgIcon>
                  <Link href={url} underline="hover">Commit {short_id}</Link>
                  <Typography ml={0} mr={"auto"}>
                    {moment(date).format('Do MMMM YYYY HH:MM').toString()}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                {
                  content ?
                    file_name.includes(".md") ? <MDEditor.Markdown source={atob(content)} /> :
                      <CodeMirror value={atob(content)} minHeight="700px" theme={dracula} extensions={[langs.toml()]} editable={false} />
                    :
                    <Box sx={{ width: "100%", height: "auto", mt: 20 }}>

                      <Typography align="center" variant="h3">
                        Empty file
                      </Typography>
                    </Box>
                }
              </Box>
            </Box> :
            <Box sx={{
              alignItems: "center",
              display: "flex",
              minHeight: "550px",
              justifyContent: "center"
            }}>
              <Alert severity="info">
                <AlertTitle>
                  Please select a file
                </AlertTitle>
              </Alert>
            </Box>
        }


      </Paper>
    </Box>
  )
}