import { connect } from 'react-redux';

import { postRelease } from 'core/slices/newsletter';
import { getGTags as getGitlabTags } from 'core/slices/softwares';
import {
  isLoadingTeams,
  getTeamList,
  isPostingRelease,
  getPostedRelease,
  getSlackChannelsList,
  getSoftwareList,
  getLabelList,
  getGTags,
  isLoadingGitlabTags,
} from 'core/selectors';

import NewReleaseModal from '../../../components/newsletter/NewReleaseModal';

export default connect(
  state => ({
    isLoadingTeams: isLoadingTeams(state),
    getTeamList: getTeamList(state),
    isPostingRelease: isPostingRelease(state),
    getPostedRelease: getPostedRelease(state),
    getSoftwareList: getSoftwareList(state),
    getSlackChannelsList: getSlackChannelsList(state),
    labelList: getLabelList(state),
    gitlabTags: getGTags(state),
    loading_tags: isLoadingGitlabTags(state),
  }),
  { postRelease, getGitlabTags },
)(NewReleaseModal);
