import { connect } from 'react-redux';

import { getReleases, requestReleasesDatesList, requestLabelList } from 'core/slices/newsletter';
import { getTeams } from 'core/slices/teams';
import { getSoftwares } from 'core/slices/softwares';
import { getSlackChannels } from 'core/slices/slack_channels';

import {
  isLoadingReleases,
  getReleasesList,
  isLoadingTeams,
  getTeamList,
  getSoftwareList,
  getPostedRelease,
  isPostingRelease,
  getSlackChannelsList,
  isLoadingSlackChannels,
  isUpdatingRelease,
  getUpdatedRelease,
  getReleasesDatesList,
  isLoadingLabels,
  getLabelList,
} from 'core/selectors';

import NewsletterView from '../../../components/newsletter/NewsletterView';

export default connect(
  state => ({
    getReleasesList: getReleasesList(state),
    isLoadingReleases: isLoadingReleases(state),
    isLoadingTeams: isLoadingTeams(state),
    getSoftwareList: getSoftwareList(state),
    getTeamList: getTeamList(state),
    getPostedRelease: getPostedRelease(state),
    isPostingRelease: isPostingRelease(state),
    getSlackChannelsList: getSlackChannelsList(state),
    isLoadingSlackChannels: isLoadingSlackChannels(state),
    isUpdatingRelease: isUpdatingRelease(state),
    getUpdatedRelease: getUpdatedRelease(state),
    releaseDatesList: getReleasesDatesList(state),
    isLoadingLabels: isLoadingLabels(state),
    labelList: getLabelList(state),
  }),
  {
    getReleases,
    getSoftwares,
    getTeams,
    getSlackChannels,
    requestReleasesDatesList,
    requestLabelList
  },
)(NewsletterView);
