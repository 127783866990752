import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { SpeedDial } from '@mui/material';

// Redux wrappers
import AddProjectDialog from '../../redux/containers/home/AddProjectDialog';

class FloatingButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  // Open/close dialog

  openDialog = () => {
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <>
        <SpeedDial
          ariaLabel="SpeedDial"
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 60,
            height: 320,
            top: 'auto',
            left: 'auto',
          }}
          icon={<AddIcon />}
          onClick={() => this.openDialog()}
        />
        <AddProjectDialog
          {...this.state}
          email={this.props.email}
          closeDialog={() => this.closeDialog()}
        />
      </>
    );
  }
}
export default FloatingButton;
