import * as React from 'react';
import { Card, CardContent } from '@mui/material';

//my components
import UserComponent from './UserComponent';

export default function UsersCards(props) {
  const { getUsers, getUserList, updateUser, deleteUser, loadingUpdate, getProjects, admin, user_id } = props;
  let users = getUserList;

  React.useEffect(() => {
    const handleUsers = async () => {
      if (getUserList.length === 0) {
        loadingUpdate(true);
        await getUsers();
        loadingUpdate(false);
        return getUserList
      } else return getUserList
    };
    handleUsers();
  }, []);

  if(!admin){
    users = users.filter(user => user.value === user_id);
  }
  users.sort((a, b) => a.first_name.localeCompare(b.first_name));
  return (
    <Card>
      <CardContent>
        {/*sx={{ overflow: 'auto', height: '400px' }}*/}
        {users.map(user => (
          <UserComponent
            user={user}
            getUsers={getUsers}
            updateUser={updateUser}
            deleteUser={deleteUser}
            loadingUpdate={loadingUpdate}
            getProjects={getProjects}
            admin = {admin}
          />
        ))}
      </CardContent>
    </Card>
  );
}
