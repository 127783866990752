import { getRequirementsInfo, isLoadingRequirements } from 'core/selectors';
import { getRequirements } from 'core/slices/repositories';
import { connect } from 'react-redux';
import RepositoriesMain from '../../../components/repositories/RepositoriesMain';

export default connect(
    state => ({
        requirements: getRequirementsInfo(state),
        isLoadingRequirements: isLoadingRequirements(state),
    }),
    {
        getRequirements,
    },
)(RepositoriesMain);