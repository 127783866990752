import { Box, Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';

import DocumentationView from '../../redux/containers/documentation/DocumentationView';
import PackagesMain from './PackagesMain';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function RepositoriesMain(props) {
    const {
        getRequirements,
        requirements,
        isLoadingRequirements,
    } = props;
    const [value, setValue] = useState(1);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        if (newValue === 1) {
            getRequirements();
        }
    };

    useEffect(() => {
        if (value === 1) {
            getRequirements();
        }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Documentation" {...a11yProps(0)} />
                    <Tab label="Packages" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Grid container spacing={2} direction={"column"}>
                    <DocumentationView />
                </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <PackagesMain
                    requirements={requirements}
                    isLoadingRequirements={isLoadingRequirements}
                />
            </CustomTabPanel>
        </>
    );
}

export default RepositoriesMain;
