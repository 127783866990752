export default function DataSourceService({ baseUrl, http }) {
  const PATH_PROFILE = '/profile';
  const PATH_PROJECTS = '/projects';
  const PATH_ROLES = '/roles';
  const PATH_TAGS = '/tags';
  const PATH_USERS = '/users';
  const PATH_SCOPES = '/scopes';
  const PATH_SNAPSHOTS = '/snapshots';
  const PATH_TASKS = '/tasks';
  const PATH_BOOKMARKS = '/bookmarks';
  const PATH_TEAMS = '/teams';
  const PATH_SOFTWARES = '/softwares';
  const PATH_TEAM_MEMBERS = '/team_members';
  const PATH_RELEASES = '/newsletter';
  const PATH_RELEASES_DATES = '/newsletter/dates';
  const PATH_RELEASES_LABELS = '/newsletter/labels';
  const PATH_USER_ROLES = '/user/roles';
  const PATH_SLACK_CHANNELS = '/newsletter/slack_channels';
  const PATH_PROJECTS_COVERAGE = '/coverage';
  const PATH_GITLAB_GROUPS = '/groups';
  const PATH_GITLAB_TAGS = '/gitlab/tags';
  const PATH_GROUPS_AND_PROJECTS = '/groups/projects';
  const PATH_FILES = '/gitlab/repository/files';
  const PATH_SEARCH_FILES = '/gitlab/repository/files/search';
  const PATH_REPOSITORIES_REQUIREMENTS = '/gitlab/repositories/requirements';

  // Personal stats
  const PATH_PERSONAL_STATS = '/personal_stats';
  const PATH_PERSONAL_STATS_CREATED_SCOPES = `/${PATH_PERSONAL_STATS}/created_scopes`;
  const PATH_PERSONAL_STATS_CREATED_TASKS = `/${PATH_PERSONAL_STATS}/created_tasks`;
  const PATH_PERSONAL_STATS_FINISHED_CYCLES_TIMES = `/${PATH_PERSONAL_STATS}/finished_cycles_times`;
  const PATH_PERSONAL_STATS_MEMBERS_BY_TEAM = `/${PATH_PERSONAL_STATS}/members_by_team`;
  const PATH_PERSONAL_STATS_NEWSLETTERS_TYPES_PUBLISHED = `/${PATH_PERSONAL_STATS}/newsletters_types_published`;
  const PATH_PERSONAL_STATS_OWN_CYCLES = `/${PATH_PERSONAL_STATS}/own_cycles`;
  const PATH_PERSONAL_STATS_POPULAR_TAGS = `/${PATH_PERSONAL_STATS}/popular_tags`;
  const PATH_PERSONAL_STATS_TOP_TEAMMATES = `/${PATH_PERSONAL_STATS}/top_teammates`;
  const PATH_PERSONAL_STATS_TOTAL_MEMBERS = `/${PATH_PERSONAL_STATS}/total_members`;
  const PATH_PERSONAL_STATS_TOTAL_PROJECTS_PARTICIPATION = `/${PATH_PERSONAL_STATS}/total_projects_participation`;
  const PATH_PERSONAL_STATS_TOTAL_RELEASES = `/${PATH_PERSONAL_STATS}/total_releases`;
  const PATH_PERSONAL_STATS_MOVEMENTS_PER_WEEK = `/${PATH_PERSONAL_STATS}/movements_per_week`;

  return {
    getProfile,
    getProjects,
    getUsers,
    getRoles,
    getTags,
    getScopes,
    postProject,
    postScope,
    postSnapshots,
    postTasks,
    deleteProject,
    updateTask,
    deleteScope,
    updateScopes,
    deleteTask,
    updateProject,
    postTags,
    updateTags,
    deleteTags,
    updateUser,
    deleteUser,
    getBookmarks,
    postBookmark,
    deleteBookmark,
    getTeams,
    getSoftwares,
    getReleases,
    postRelease,
    deleteRelease,
    updateRelease,
    getUserRoles,
    updateTeamManager,
    postTeamMembers,
    deleteTeamMember,
    getSlackChannels,
    getProjectsCoverage,
    getGitlabGroups,
    getReleasesDates,
    getReleaseLabels,
    getGitlabTags,
    getGroupsAndProjects,
    getRepositoryFile,
    getRepositorySearchFile,
    getPersonalStatsCreatedScopes,
    getPersonalStatsCreatedTasks,
    getPersonalStatsFinishedCyclesTimes,
    getPersonalStatsMembersByTeam,
    getPersonalStatsNewslettersTypesPublished,
    getPersonalStatsOwnCycles,
    getPersonalStatsPopularTags,
    getPersonalStatsTopTeammates,
    getPersonalStatsTotalMembers,
    getPersonalStatsTotalProjectsParticipation,
    getPersonalStatsTotalReleases,
    getPersonalStatsMovementsPerWeek,
    getRequirements,
  };

  function getProfile() {
    return http.get({
      url: `${AUTH_URL}${PATH_PROFILE}`,
      credentials: 'include',
    });
  }

  function getProjects(showMyProjects, hideFinishedProjects, showBookmarked) {
    return http.get({
      url: `${baseUrl}${PATH_PROJECTS}`,
      query: {
        ignore_finished: hideFinishedProjects,
        only_own_projects: showMyProjects,
        include_bookmarked: showBookmarked,
      },
    });
  }

  function postProject(project) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_PROJECTS}`,
      body: project,
    });
  }

  function deleteProject(projectId) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_PROJECTS}?project_id=${projectId}`,
    });
  }

  function updateProject(project) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_PROJECTS}`,
      body: project,
    });
  }

  function getUsers() {
    return http.get({
      url: `${baseUrl}${PATH_USERS}`,
    });
  }

  function getRoles() {
    return http.get({
      url: `${baseUrl}${PATH_ROLES}`,
    });
  }

  function getTags() {
    return http.get({
      url: `${baseUrl}${PATH_TAGS}`,
    });
  }

  function getScopes(project_id) {
    return http.get({
      url: `${baseUrl}${PATH_SCOPES}?project_id=${project_id}`,
    });
  }

  function postScope(scope) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SCOPES}`,
      body: scope,
    });
  }

  function postSnapshots(snapshots) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SNAPSHOTS}`,
      body: snapshots,
    });
  }

  function postTasks(tasks) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_TASKS}`,
      body: tasks,
    });
  }

  function updateTask(task) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_TASKS}`,
      body: task,
    });
  }

  function deleteScope(ScopeId) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_SCOPES}?scope_id=${ScopeId}`,
    });
  }

  function updateScopes(scopes) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_SCOPES}`,
      body: scopes,
    });
  }

  function deleteTask(taskId) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_TASKS}?task_id=${taskId}`,
    });
  }

  function postTags(tags) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_TAGS}`,
      body: tags,
    });
  }

  function updateTags(tags) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_TAGS}`,
      body: tags,
    });
  }

  function deleteTags(tags) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_TAGS}?tag_id=${tags}`,
    });
  }

  function updateUser(user) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_USERS}`,
      body: user,
    });
  }

  function deleteUser(userId) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_USERS}?user_id=${userId}`,
    });
  }

  function postBookmark(userId, projectId) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_BOOKMARKS}`,
      body: {
        user_id: userId,
        project_id: projectId,
      },
    });
  }

  function deleteBookmark(userId, projectId) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_BOOKMARKS}?user_id=${userId}&project_id=${projectId}`,
    });
  }

  function getBookmarks() {
    return http.get({
      url: `${baseUrl}${PATH_BOOKMARKS}`,
    });
  }

  function getTeams(user_id) {
    return http.get({
      url: `${baseUrl}${PATH_TEAMS}`,
    });
  }

  function getSoftwares() {
    return http.get({
      url: `${baseUrl}${PATH_SOFTWARES}`,
    });
  }

  function getReleases(page, labels, softwares, text, date) {
    return http.get({
      url: `${baseUrl}${PATH_RELEASES}`,
      query: { page, labels, softwares, text, date },
    });
  }

  function postRelease(release) {
    return http.post({
      url: `${baseUrl}${PATH_RELEASES}`,
      body: release,
    });
  }

  function deleteRelease(releaseId) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_RELEASES}`,
      query: { release_id: releaseId },
    });
  }

  function updateRelease(releaseId, newRelease) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_RELEASES}`,
      body: newRelease,
      query: { release_id: releaseId },
    });
  }

  function getReleasesDates(historyValue) {
    return http.get({
      url: `${baseUrl}${PATH_RELEASES_DATES}`,
      query: { history: historyValue },
    });
  }

  function getReleaseLabels() {
    return http.get({
      url: `${baseUrl}${PATH_RELEASES_LABELS}`,
    });
  }

  function getUserRoles() {
    return http.get({
      url: `${baseUrl}${PATH_USER_ROLES}`,
    });
  }

  function updateTeamManager(team_id, user_id) {
    return http.putAuthenticated({
      url: `${baseUrl}${PATH_TEAM_MEMBERS}`,
      body: { team_id, user_id },
    });
  }

  function postTeamMembers(team_id, new_members) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_TEAM_MEMBERS}`,
      body: { team_id, new_members },
    });
  }

  function deleteTeamMember(team_id, member_id) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_TEAM_MEMBERS}`,
      query: { team_id, member_id },
    });
  }

  function getSlackChannels() {
    return http.get({
      url: `${baseUrl}${PATH_SLACK_CHANNELS}`,
    });
  }
  function getProjectsCoverage() {
    return http.get({
      url: `${baseUrl}${PATH_PROJECTS_COVERAGE}`,
    });
  }
  function getGitlabGroups() {
    return http.get({
      url: `${baseUrl}${PATH_GITLAB_GROUPS}`,
    });
  }
  function getGitlabTags(repository_id) {
    return http.get({
      url: `${baseUrl}${PATH_GITLAB_TAGS}`,
      query: { repository_id },
    });
  }
  function getGroupsAndProjects() {
    return http.get({
      url: `${baseUrl}${PATH_GROUPS_AND_PROJECTS}`,
    });
  }
  function getRepositoryFile(repository_id, path) {
    return http.get({
      url: `${baseUrl}${PATH_FILES}`,
      query: { repository_id, path },
    });
  }
  function getRepositorySearchFile(query, fileFormat) {
    return http.get({
      url: `${baseUrl}${PATH_SEARCH_FILES}`,
      query: { query, file_format: fileFormat },
    });
  }

  function getPersonalStatsCreatedScopes() {
    return http.get({
      url: `${baseUrl}${PATH_PERSONAL_STATS_CREATED_SCOPES}`,
    });
  }

  function getPersonalStatsCreatedTasks() {
    return http.get({
      url: `${baseUrl}${PATH_PERSONAL_STATS_CREATED_TASKS}`,
    });
  }

  function getPersonalStatsFinishedCyclesTimes() {
    return http.get({
      url: `${baseUrl}${PATH_PERSONAL_STATS_FINISHED_CYCLES_TIMES}`,
    });
  }

  function getPersonalStatsMembersByTeam() {
    return http.get({
      url: `${baseUrl}${PATH_PERSONAL_STATS_MEMBERS_BY_TEAM}`,
    });
  }

  function getPersonalStatsNewslettersTypesPublished() {
    return http.get({
      url: `${baseUrl}${PATH_PERSONAL_STATS_NEWSLETTERS_TYPES_PUBLISHED}`,
    });
  }

  function getPersonalStatsOwnCycles() {
    return http.get({
      url: `${baseUrl}${PATH_PERSONAL_STATS_OWN_CYCLES}`,
    });
  }

  function getPersonalStatsPopularTags() {
    return http.get({
      url: `${baseUrl}${PATH_PERSONAL_STATS_POPULAR_TAGS}`,
    });
  }

  function getPersonalStatsTopTeammates() {
    return http.get({
      url: `${baseUrl}${PATH_PERSONAL_STATS_TOP_TEAMMATES}`,
    });
  }

  function getPersonalStatsTotalMembers() {
    return http.get({
      url: `${baseUrl}${PATH_PERSONAL_STATS_TOTAL_MEMBERS}`,
    });
  }

  function getPersonalStatsTotalProjectsParticipation() {
    return http.get({
      url: `${baseUrl}${PATH_PERSONAL_STATS_TOTAL_PROJECTS_PARTICIPATION}`,
    });
  }

  function getPersonalStatsTotalReleases() {
    return http.get({
      url: `${baseUrl}${PATH_PERSONAL_STATS_TOTAL_RELEASES}`,
    });
  }

  function getPersonalStatsMovementsPerWeek() {
    return http.get({
      url: `${baseUrl}${PATH_PERSONAL_STATS_MOVEMENTS_PER_WEEK}`,
    });
  }
  function getRequirements(payload) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_REPOSITORIES_REQUIREMENTS}`,
      body: payload,
    });
  }

}
