import { connect } from 'react-redux';

import { getTeams, updateTeamManager } from 'core/slices/teams';
import { getUsers } from 'core/slices/users';
import { getTeamList, getRole, getUserList } from 'core/selectors';

import Teams from '../../../components/teams/Teams';
export default connect(
  state => ({
    getTeamList: getTeamList(state),
    getRole: getRole(state),
    getUserList: getUserList(state),
  }),
  {
    getTeams,
    updateTeamManager,
    getUsers,
  },
)(Teams);
