import { connect } from 'react-redux';

import { postTeamMembers, deleteTeamMember } from 'core/slices/teams';

import {
  isAddingNewMembers,
  getNewMembersList,
  isDeletingMember,
  userInfo,
  getRole,
} from 'core/selectors';

import TeamFormated from '../../../components/teams/TeamFormated';
export default connect(
  state => ({
    isAddingNewMembers: isAddingNewMembers(state),
    getNewMembersList: getNewMembersList(state),
    isDeletingMember: isDeletingMember(state),
    userInfo: userInfo(state),
    getRole: getRole(state),
  }),
  { postTeamMembers, deleteTeamMember },
)(TeamFormated);
