import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: '#e9e9f1',
    },
    primary: {
      main: '#1a242d',
    },
    secondary: {
      main: '#FF0266',
    },
    success: {
      main: '#46bf83',
    },
    info: {
      main: '#0070B7',
    },
    warning: {
      main: '#ffa400',
    },
    error: {
      main: '#fe2d38',
    },
    white: {
      main: '#ffffff',
    },

    slate: {
      main: '#64748b',
    },
    gray: {
      main: '#6b7280',
    },
    zinc: {
      main: '#71717a',
    },
    neutral: {
      main: '#737373',
    },
    stone: {
      main: '#78716c',
    },
    red: {
      main: '#ef4444',
    },
    orange: {
      main: '#f97316',
    },
    amber: {
      main: '#f59e0b',
    },
    yellow: {
      main: '#eab308',
    },
    lime: {
      main: '#84cc16',
    },
    green: {
      main: '#22c55e',
    },
    emerald: {
      main: '#10b981',
    },
    teal: {
      main: '#14b8a6',
    },
    cyan: {
      main: '#06b6d4',
    },
    sky: {
      main: '#0ea5e9',
    },
    blue: {
      main: '#3b82f6',
    },
    indigo: {
      main: '#6366f1',
    },
    violet: {
      main: '#8b5cf6',
    },
    purple: {
      main: '#a855f7',
    },
    fuchsia: {
      main: '#d946ef',
    },
    pink: {
      main: '#ec4899',
    },
    rose: {
      main: '#f43f5e',
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
