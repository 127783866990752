import { BarChart, Title, Subtitle, Card, Text } from "@tremor/react";
import { Box, Grid } from "@mui/material";

export default function CoverageBar(props) {
  const dataFormatter = (number) => {
    return `${number}% `;


  };

  const { chartdata, date, categories } = props;
  return (
    <Card className="w-full h-full card-animation" style={{ maxWidth: "100vw" }}>
      <Title>
        Testing Coverage Percent
      </Title>
      <Subtitle>
        Only displaying projects with CI/CD pipelines
      </Subtitle>
      <BarChart
        className="w-full capitalize"
        style={{ overflowX: "scroll" }}
        data={chartdata.filter((data) => data.coverage !== null)}
        index="name"
        categories={["coverage"]}
        colors={["blue"]}
        showLegend={false}
        stack={true}
        valueFormatter={(number) => dataFormatter(number)}
        yAxisWidth={80}
        maxValue={100}
      />
      <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
        <Text>
          Last Update: {date}
        </Text>
      </Box>
    </Card>
  )
}