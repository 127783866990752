import { connect } from 'react-redux';
import CoverageView from '../../../components/dashboard/CoverageView';
import { getCoverageList, isLoadingCoverageList, getTeamList, getGGroups, getCoverageDate } from 'core/selectors';
import { getCoverageProjectList, getGitlabGroups } from 'core/slices/coverage';
export default connect(
  state => ({
    teamList: getTeamList(state),
    loading: isLoadingCoverageList(state),
    coverageProjectList: getCoverageList(state),
    gitlabGroups: getGGroups(state),
    coverageDate: getCoverageDate(state),
  }),
  {
    getCoverageProjectList,
    getGitlabGroups
  },
)(CoverageView);
