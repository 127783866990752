import { createReducer } from './utils';

import {
  PROJECT_REQUEST,
  PROJECT_AVAILABLE,
  PROJECT_UNAVAILABLE,
  PROJECT_POST_FAILED,
  PROJECT_POST,
  PROJECT_POSTED,
  PROJECT_DELETE,
  PROJECT_DELETED,
  PROJECT_DELETE_FAILED,
  PROJECT_SET,
  SNACK_SET,
} from './constants';

const defaultState = {
  loading_projects: false,
  projects: false,
  projectList: [],
  sent: false,
  posting_projects: false,
  deleting_projects: false,
  project: {},
};

export const reducer = createReducer(defaultState, {
  [PROJECT_REQUEST]: handleProjectsRequest,
  [PROJECT_AVAILABLE]: handleProjectsAvailable,
  [PROJECT_UNAVAILABLE]: handleProjectsUnavailable,
  [PROJECT_POST_FAILED]: handleProjectFailure,
  [PROJECT_POST]: handleProjectPost,
  [PROJECT_POSTED]: handleProjectsPostSuccess,
  [PROJECT_DELETE]: handleProjectDelete,
  [PROJECT_DELETED]: handleProjectDeleted,
  [PROJECT_DELETE_FAILED]: handleProjectDeleteFailure,
  [PROJECT_SET]: handleSetProject,
});

function handleSetProject(state, { payload: { project } }) {
  return {
    ...state,
    project,
  };
}

function handleProjectDelete(state, _) {
  return {
    ...state,
    deleting_projects: true,
  };
}

function handleProjectDeleted(state, _) {
  return {
    ...state,
    deleting_projects: false,
  };
}

function handleProjectDeleteFailure(state, _) {
  return defaultState;
}

function handleProjectFailure(state, _) {
  return {
    ...state,
    sent: false,
  };
}

function handleProjectPost(state, _) {
  return {
    ...state,
    posting_projects: true,
  };
}

function handleProjectsPostSuccess(state, _) {
  return {
    ...state,
    posting_projects: false,
  };
}

function handleProjectsRequest(state, _) {
  return {
    ...state,
    loading_projects: true,
  };
}

function handleProjectsUnavailable(state, _) {
  return defaultState;
}

function handleProjectsAvailable(state, { payload: { projects } }) {
  return {
    ...state,
    loading_projects: false,
    projects: true,
    projectList: projects,
  };
}

export function getProjects() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: PROJECT_REQUEST });
    const {
      users: { myProjects, hideFinished, showBookmarked }

    } = getState();
    try {
      const projects = await dataSource.getProjects(myProjects, hideFinished, showBookmarked);
      dispatch({
        type: PROJECT_AVAILABLE,
        payload: { projects },
      });
    } catch (error) {
      dispatch({
        type: PROJECT_UNAVAILABLE,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function postProject(project) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: PROJECT_POST });
      await dataSource.postProject(project);
      dispatch({ type: PROJECT_POSTED });
      const snack = {
        open: true,
        message: 'Project posted',
        severity: 'success',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: PROJECT_POST_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function deleteProject(projectId) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: PROJECT_DELETE });
      await dataSource.deleteProject(projectId);
      dispatch({ type: PROJECT_DELETED });
      const snack = {
        open: true,
        message: 'Project deleted',
        severity: 'success',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: PROJECT_DELETE_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function setProject(project) {
  return async dispatch => {
    dispatch({
      type: PROJECT_SET,
      payload: { project },
    });
  };
}

export function updateProject(project) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      await dataSource.updateProject(project);
      const snack = {
        open: true,
        message: 'Project updated',
        severity: 'success',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: PROJECT_POST_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
