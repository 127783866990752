import { createReducer } from './utils';

import { COVERAGE_LOADING, COVERAGE_SUCCESS, COVERAGE_FAILURE, SNACK_SET, GITLAB_GROUPS_SUCCESS, GITLAB_GROUPS_LOADING } from './constants';

const defaultState = {
  loading: false,
  coverageProjectList: [],
  gitlabGroups: [],
  coverageDate: null
};

export const reducer = createReducer(defaultState, {
  [COVERAGE_LOADING]: handleCoverageLoading,
  [COVERAGE_SUCCESS]: handleCoverageSuccess,
  [COVERAGE_FAILURE]: handleCoverageFailure,
  [GITLAB_GROUPS_SUCCESS]: handleGitlabGroupsSuccess,
});

function handleCoverageLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

function handleCoverageSuccess(state, { payload: { coverageProjectList, coverageDate } }) {
  return {
    ...state,
    loading: false,
    coverageProjectList: coverageProjectList.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }),
    coverageDate: coverageDate,
  };
}

function handleCoverageFailure(state) {
  return {
    ...state,
    loading: false,
  }
}

function handleGitlabGroupsSuccess(state, { payload: { gitlabGroups } }) {
  return {
    ...state,
    gitlabGroups: gitlabGroups.sort(),
  }
}

export function getCoverageProjectList() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: COVERAGE_LOADING });
    try {
      const response = await dataSource.getProjectsCoverage()
      dispatch({
        type: COVERAGE_SUCCESS,
        payload: { coverageProjectList: response.data, coverageDate: response.date },
      });
    } catch (error) {
      const snack = {
        open: true,
        message: 'There was an error to obtain projects coverage',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
      dispatch({ type: COVERAGE_FAILURE });
    }
  };
}

export function getGitlabGroups() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GITLAB_GROUPS_LOADING });
    try {
      const gitlabGroups = await dataSource.getGitlabGroups()
      dispatch({
        type: GITLAB_GROUPS_SUCCESS,
        payload: { gitlabGroups },
      });
    } catch (error) {
      const snack = {
        open: true,
        message: 'There was an error to obtain Gitlab groups',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}