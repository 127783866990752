import { connect } from 'react-redux';
import { loadingUpdate } from 'core/slices/loading';
import { postProject, getProjects, updateProject } from 'core/slices/projects';
import { getUserList, getRoleList, getTagList } from 'core/selectors';
import EditMemberDialog from '../../../components/project/EditMembersDialog';

export default connect(
  state => ({
    users: getUserList(state),
    roles: getRoleList(state),
    tags: getTagList(state),
  }),
  {
    postProject,
    getProjects,
    loadingUpdate,
    updateProject,
  },
)(EditMemberDialog);
