import { connect } from 'react-redux';

import { getUserRoles } from 'core/slices/users';

import { getTeams } from 'core/slices/teams';
import { getSlackChannels } from 'core/slices/slack_channels';

import {
  getRole,
  isLoadingRole,
  isLoadingProjects,
  isLoadingUsers,
  isLoadingScopes,
  isLoadingTeams,
  isLoadingReleases,
  isLoadingSlackChannels,
  isLoadingCoverageList,
  isLoadingReleaseDates,
  isLoadingGAP,
  isLoadingFindFiles,
} from 'core/selectors';

import Layout from '../../../components/navbar/Layout';

export default connect(
  state => ({
    getRole: getRole(state),
    isLoadingRole: isLoadingRole(state),
    isLoadingProjects: isLoadingProjects(state),
    isLoadingUsers: isLoadingUsers(state),
    isLoadingScopes: isLoadingScopes(state),
    isLoadingTeams: isLoadingTeams(state),
    isLoadingReleases: isLoadingReleases(state),
    isLoadingSlackChannels: isLoadingSlackChannels(state),
    isLoadingCoverageList: isLoadingCoverageList(state),
    isLoadingReleaseDates: isLoadingReleaseDates(state),
    isLoadingGAP: isLoadingGAP(state),
    isLoadingFindFiles: isLoadingFindFiles(state),
  }),
  { getUserRoles, getTeams, getSlackChannels },
)(Layout);
