import { useState } from 'react';
import { Grid, Typography, Box, Button, TextField, Switch, Tooltip, IconButton, Divider } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import EditOffIcon from '@mui/icons-material/EditOff';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

//my components
import DeleteDialog from '../DeleteDialog';

export default function UserComponent(props) {
  const [editMode, setEditMode] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const {
    user,
    getUsers,
    updateUser,
    deleteUser,
    loadingUpdate,
    getProjects,
    admin,
  } = props;
  const { first_name, last_name, email, can_create_projects, value, label } = user;
  const [formValues, setFormValues] = useState({
    first_name,
    last_name,
    username: label,
    email,
    can_create_projects,
    user_id: value,
  });

  const handleDelete = async () => {
    setOpenDeleteDialog(true);
  };
  const handleEditMode = () => {
    setFormValues({
      first_name: first_name,
      last_name: last_name,
      username: label,
      email: email,
      can_create_projects: can_create_projects,
      user_id: value,
    });
    setEditMode(!editMode);
  };
  const handleChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };
  const handleSwitchChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.checked });
  };
  const handleUpdateUser = async () => {
    const newForm = { ...formValues, user_id: value };
    loadingUpdate(true);
    await updateUser(newForm);
    await getUsers();
    await getProjects();
    setFormValues({
      first_name: first_name,
      last_name: last_name,
      username: label,
      email: email,
      can_create_projects: can_create_projects,
      user_id: value,
    });
    loadingUpdate(false);
    handleEditMode();
  };

  if (!editMode) {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={admin?1:3}>
            <Typography variant="subtitle1" align="center" marginTop={3}>
              {user.first_name}
            </Typography>
          </Grid>
          <Grid item xs={admin?2:3}>
            <Typography variant="subtitle1" align="center" marginTop={3}>
              {user.last_name}
            </Typography>
          </Grid>
          <Grid item xs={admin?2:4}>
            <Typography variant="subtitle1" align="center" marginTop={3}>
              {user.label}
            </Typography>
          </Grid>
          {admin ? (
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center" marginTop={3}>
                {user.email}
              </Typography>
            </Grid>
          ) : null}
          {admin ? (
          <Grid item xs={1} container justifyContent="center" alignItems="center">
              <Tooltip title="can create projects">
                <IconButton disabled>
                  <ManageAccountsIcon />
                </IconButton>
              </Tooltip>
              <Switch disabled
                checked={user.can_create_projects}
                color="secondary"
              />
            </Grid>
          ) : null}

          <Grid item xs={1}>
            <Box marginTop={'1em'}>
              <Button variant="outlined" onClick={handleEditMode}>
                <EditIcon />
              </Button>
            </Box>
          </Grid>
          {admin ? (
            <Grid item xs={1}>
              <Box marginTop={'1em'}>
                <Button variant="outlined" onClick={handleDelete}>
                  <CloseIcon />
                </Button>
              </Box>
            </Grid>
          ) : null}
        </Grid>
        <Box mt={1}>
          <Divider light />
        </Box>
        <DeleteDialog
          open={openDeleteDialog}
          toDelete={user.value}
          toDeleteName={'user'}
          deleteFunction={deleteUser}
          getFunction={getUsers}
          loadingUpdate={loadingUpdate}
          closeDialog={setOpenDeleteDialog}
          getSecondary={getProjects}
          goHome={false}
        />
      </>
    );
  } else {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={admin?2:3}>
            <TextField
              margin="dense"
              name="first_name"
              value={formValues.first_name}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={admin?2:3}>
            <TextField
              margin="dense"
              name="last_name"
              value={formValues.last_name}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={admin?2:4}>
            <TextField
              margin="dense"
              name="username"
              value={formValues.username}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          {admin?( <Grid item xs={3}>
            <TextField
              margin="dense"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              fullWidth
            />
          </Grid>):null}
          <Grid item xs={1} container justifyContent="center" alignItems="center">
            <Tooltip title="can create projects">
              <ManageAccountsIcon />
            </Tooltip>
            <Switch 
              name="can_create_projects"
              checked={formValues.can_create_projects}
              onChange={handleSwitchChange}
              color="secondary"
            />
          </Grid>
        
          <Grid item xs={1}>
            <Box marginTop={'1em'}>
              <Button variant="outlined" onClick={handleUpdateUser}>
                <SaveIcon />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box marginTop={'1em'}>
              <Button variant="outlined" onClick={handleEditMode}>
                <EditOffIcon />
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Divider light />
        </Box>
      </>
    );
  }
}
