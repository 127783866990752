import React, { Component } from 'react';
import { Grid, IconButton, Toolbar, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';

// My components
import MyMenu from '../../redux/containers/navbar/MyMenu';

//Icons
import logo_zippedi from '../../../static/images/only_letters_white.png';
import MenuIcon from '@mui/icons-material/Menu';

// Styled components
const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const drawerWidth = 240;

const styles = {
  logo: {
    height: 50,
    width: 120,
  },
};

function Navbar(props) {
  const { open, handleDrawer, isLoading } = props;
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        {/* Menu open button */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawer}
          edge="start"
          sx={{
            marginRight: 4,
            ...(open && { display: 'none' }),
          }}>
          <MenuIcon />
        </IconButton>

        <img style={styles.logo} src={logo_zippedi} alt="logo_zippedi" />

        {/* spacing */}
        <Grid sx={{ flexGrow: 1 }} />

        <MyMenu />
      </Toolbar>
      {isLoading ? <LinearProgress color="error" /> : null}
    </AppBar>
  );
}

export default Navbar;
