import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Divider,
  FormControl,
  Chip,
  CircularProgress,
  Autocomplete,
  Alert,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import MDEditor from '@uiw/react-md-editor';

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
};

const leftInputStyle = {
  ml: '15px',
  mr: '15px',
  my: '5px',
  width: '800px',
  fontWeight: 'bold',
};

const rightInputStyle = {
  ml: '15px',
  mr: '15px',
  my: '6px',
  width: '250px',
  borderRadius: '20px',
  fontWeight: 'bold',
};
const buttonStyle = {
  ml: '15px',
  mr: '15px',
  my: '6px',
  width: '150px',
  borderRadius: '20px',
  borderColor: 'black',
};

const labels = ['Fix', 'Update', 'New', 'Improvement', 'New Feature', 'BugFix'];


function EditReleaseModal(props) {
  const {
    isFormOpen,
    setIsFormOpen,
    getTeamList,
    getSlackChannelsList,
    release,
    getSoftwareList,
    updateRelease,
  } = props;

  const [title, setTitle] = useState(release.release_title);
  const [content, setContent] = useState(release.release_content);
  const [teamSelected, setTeamSelected] = useState(
    getTeamList.find(team => team.team_name === release.team_name),
  );
  const [labelsSelected, setLabelsSelected] = useState(
    release.labels ? release.labels : [],
  );
  const [channelsSelected, setChannelsSelected] = useState(
    release.slack_channels ? release.slack_channels : [],
  );
  const [implicatedSoftwares, setImplicatedSoftwares] = useState(
    release.implicated_softwares ? release.implicated_softwares : [],
  );
  const [repositoryId, setRepositoryId] = useState(
    release.repository_id || null,
  );
  const [version, setVersion] = useState(
    release.software_version ? release.software_version : '',
  );
  const [error, setError] = useState(true);

  const handleChangeImplicatedSoftware = (value) => {
    if (value) {
      setImplicatedSoftwares([value.name])
      setRepositoryId(value.repository_id)
    } else {
      setImplicatedSoftwares([""]);
      setRepositoryId(null);
    }

  }


  const handleUpdateRelease = () => {
    updateRelease(release.release_id, {
      release_title: title,
      release_content: content,
      team_id: teamSelected.team_id,
      labels: JSON.parse(JSON.stringify(labelsSelected)),
      slack_channels: JSON.parse(JSON.stringify(channelsSelected)),
      implicated_softwares: implicatedSoftwares,
      repository_id: repositoryId || null,
      software_version: version,
    });
    setIsFormOpen(false);
  };

  return (
    <Modal
      open={isFormOpen}
      onClose={() => setIsFormOpen(false)}
      sx={{ width: '100%', backdropFilter: 'blur(5px)' }}>
      <Box sx={boxStyle}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              backgroundColor: 'background.paper',
              borderRadius: '7px',
              padding: '5px',
            }}>
            <Typography sx={leftInputStyle}>Edit Release</Typography>
            <TextField
              label="Title"
              value={title}
              sx={leftInputStyle}
              onChange={e => setTitle(e.target.value)}
            />

            <Box sx={leftInputStyle}>
              <MDEditor value={content} onChange={setContent} />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'background.paper',
              borderRadius: '7px',
              padding: '5px',
            }}>
            <Typography sx={rightInputStyle}>Release Settings</Typography>

            <Autocomplete
              value={teamSelected}
              sx={rightInputStyle}
              getOptionLabel={option => option.team_name}
              options={getTeamList}
              onChange={(e, value) => setTeamSelected(value)}
              renderInput={params => <TextField {...params} label="Team" />}
            />

            <Autocomplete
              value={labelsSelected}
              multiple
              freeSolo
              sx={rightInputStyle}
              options={labels}
              onChange={(e, value) => setLabelsSelected(value)}
              renderInput={params => <TextField {...params} label="Labels" />}
            />
            <Autocomplete
              value={channelsSelected}
              multiple
              sx={rightInputStyle}
              options={getSlackChannelsList.map(channel => channel.name)}
              onChange={(e, value) => setChannelsSelected(value)}
              renderInput={params => (
                <TextField {...params} label="Slack Channels" />
              )}
            />
            <Autocomplete
              value={implicatedSoftwares}
              isOptionEqualToValue={(option, value) => value.name ? option.name.includes(value.name) : option.name.includes(value[0])}
              getOptionLabel={(option) => option.name_with_namespace ? option.name_with_namespace : option[0]}
              sx={rightInputStyle}
              renderOption={(props, option) => (
                <Box
                  key={option}
                  sx={{
                    borderRadius: '8px',
                    margin: '5px',
                    flexDirection: 'column',

                  }}
                  component="li"
                  {...props}
                >
                  {option.name}
                  <Typography sx={{ fontSize: 8, opacity: 0.5 }}>
                    {option.name_with_namespace}
                  </Typography>
                </Box>
              )}
              options={getSoftwareList}
              onChange={(e, value) => { handleChangeImplicatedSoftware(value) }}
              renderInput={params => (
                <TextField {...params} label="Implicated Softwares" />
              )}
            />

            <TextField
              value={version}
              label="Version"
              sx={rightInputStyle}
              onChange={e => setVersion(e.target.value)}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            backgroundColor: 'background.paper',
            borderRadius: '7px',
            padding: '5px',
          }}>
          <Button
            onClick={handleUpdateRelease}
            disabled={!teamSelected || !content || !title}
            variant="contained"
            sx={buttonStyle}>
            Update Release
          </Button>
          <Button
            onClick={() => {
              setIsFormOpen(false);
            }}
            variant="outlined"
            sx={buttonStyle}>
            Cancel
          </Button>
          {!teamSelected || !content || !title ? (
            <Alert sx={{ flexGrow: 1, borderRadius: '20px' }} severity="error">
              Title, Content & Team are required!
            </Alert>
          ) : null}
        </Box>
      </Box>
    </Modal>
  );
}

export default EditReleaseModal;
