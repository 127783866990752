export default function AuthSourceService({ baseUrl, http }) {
  const PATH_SIGN_IN = '/profile';
  const PATH_LOG_OUT = '/sessionLogout';
  return {
    checkAuthenticated,
    logOut,
  };

  function checkAuthenticated() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_SIGN_IN}`,
    });
  }
  function logOut() {
    return http.getAuthenticated({
      url: `${baseUrl}${PATH_LOG_OUT}`,
    });
  }
}
