import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Grid,
  Divider,
  Box,
} from '@mui/material';

export default function EditMemberDialog(props) {
  const {
    closeDialog,
    open,
    project,
    roles,
    loadingUpdate,
    updateProject,
    getProjects,
  } = props;
  let users = props.users;
  const [memberValues, setMemberValues] = React.useState([
    {
      member: project.responsible_id,
      role: project.responsible_role,
    },
  ]);
  const [formValues, setFormValues] = React.useState({
    responsible: project.responsible_id,
  });

  React.useEffect(() => {
    roles.sort((a, b) => a.label.localeCompare(b.label));
    let newMemberValues = [...memberValues];
    project.members_ids.forEach((value, index) => {
      newMemberValues.push({
        member: value,
        role: project.members_role_ids[index],
      });
    });
    setMemberValues(
      newMemberValues.filter(
        (v, i, a) => a.findIndex(t => t.member === v.member) === i,
      ),
    );
  }, []);

  const handleClose = () => {
    closeDialog();
    setFormValues({ responsible: project.responsible_id });
    let newMemberValues = [
      {
        member: project.responsible_id,
        role: project.responsible_role,
      },
    ];
    project.members_ids.forEach((value, index) => {
      newMemberValues.push({
        member: value,
        role: project.members_role_ids[index],
      });
    });
    setMemberValues(
      newMemberValues.filter(
        (v, i, a) => a.findIndex(t => t.member === v.member) === i,
      ),
    );
  };

  const handleMemberChange = (index, event) => {
    let newMemberValues = [...memberValues];
    newMemberValues[index][event.target.name] = event.target.value;
    setMemberValues(newMemberValues);
  };

  const addMemberFields = index => {
    let newMemberValues = [...memberValues];
    setMemberValues([...newMemberValues, { member: '', role: '' }]);
  };

  const removeMemberFields = index => {
    let newMemberValues = [...memberValues];
    newMemberValues.splice(index, 1);
    setMemberValues(newMemberValues);
  };

  const handleChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
    if (event.target.name === 'responsible') {
      handleMemberChange(0, {
        target: { name: 'member', value: event.target.value },
      });
    }
  };

  const handleSubmit = async () => {
    loadingUpdate(true);
    let newMemberValues = [...memberValues];
    const members_ids = newMemberValues.map(v => v.member);
    const roles_ids = newMemberValues.map(v => v.role);
    const responsible = formValues.responsible;
    handleClose();
    await updateProject({
      project_id: project.id,
      responsible,
      members_ids,
      roles_ids,
      to_update: 'members',
    });
    await getProjects();
    loadingUpdate(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>Edit project details</DialogTitle>
      <DialogContent>
        <DialogContentText paddingBottom="1em">
          In order to edit the project details, please modify the fields below.
        </DialogContentText>
        <Divider sx={{ margin: 1 }}>Members</Divider>
        <Grid container spacing={2}>
          <Grid item xs={12} marginBottom={'1em'}>
            <TextField
              select
              required
              margin="dense"
              label="Responsible"
              type="text"
              name="responsible"
              fullWidth
              variant="outlined"
              value={formValues.responsible}
              onChange={handleChange}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: '20em',
                    },
                  },
                },
              }}>
              {users.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {memberValues.map((element, index) => (
            <>
              <Grid item xs={6}>
                <TextField
                  select
                  required
                  margin="dense"
                  name="member"
                  label="Member"
                  type="text"
                  key={index === 0 ? formValues.responsible : element.member}
                  value={
                    index === 0 ? formValues.responsible : element.member
                  }
                  fullWidth
                  variant="outlined"
                  onChange={event => handleMemberChange(index, event)}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: '20em',
                        },
                      },
                    },
                  }}>
                  {users.map(option => (
                    <MenuItem
                      disabled={memberValues.some(
                        e => e.member === option.value,
                      )}
                      key={option.value}
                      value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  required
                  name="role"
                  margin="dense"
                  value={element.role}
                  label="Role"
                  type="text"
                  fullWidth
                  key={element.role}
                  variant="outlined"
                  onChange={event => handleMemberChange(index, event)}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: '20em',
                        },
                      },
                    },
                  }}>
                  {roles.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {memberValues.length <= 1 ? (
                <Grid item xs={2}>
                  <Box margin={1.4}>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="success"
                      onClick={() => addMemberFields(index)}>
                      <AddIcon fontSize="large" />
                    </Button>
                  </Box>
                </Grid>
              ) : (
                <>
                  <Grid item xs={2}>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="success"
                      onClick={() => addMemberFields(index)}>
                      <AddIcon />
                    </Button>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="error"
                      onClick={() => removeMemberFields(index)}>
                      <RemoveIcon />
                    </Button>
                  </Grid>
                </>
              )}
            </>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          marginLeft: '1em',
          marginRight: '1em',
        }}>
        <Button onClick={handleClose}>cancel</Button>
        {memberValues.every(e => e.member !== '') &
        memberValues.every(e => e.role !== '') ? (
          <Button onClick={handleSubmit} color="success">
            edit project
          </Button>
        ) : (
          <Button onClick={handleSubmit} disabled color="success">
            edit project
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
