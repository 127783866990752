import { connect } from 'react-redux';

import { getRole } from 'core/selectors';

import MemberFormated from '../../../components/teams/MemberFormated';
export default connect(
  state => ({
    getRole: getRole(state),
  }),
  {},
)(MemberFormated);
