import { useState } from 'react';
import { Grid, Box, Button, Card, CardContent, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

//my components
import TagComponent from './TagComponent';

export default function UsersCards(props) {
  const {
    tags,
    getTags,
    postTags,
    updateTags,
    deleteTags,
    user_id,
    loadingUpdate,
    getProjects,
  } = props;
  const [formValues, setFormValues] = useState({
    tag_name: '',
    created_by: user_id,
  });
  const handleChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };
  const handleAddTag = async () => {
    await postTags(formValues);
    await getTags();
    setFormValues({ tag_name: '', created_by: user_id });
  };
  return (
    <Card>
      <CardContent>
        {/*sx={{ overflow: 'auto', height: '400px' }}*/}
        {tags.map(tag => (
          <TagComponent
            tag={tag}
            getTags={getTags}
            updateTags={updateTags}
            deleteTags={deleteTags}
            loadingUpdate={loadingUpdate}
            getProjects={getProjects}
          />
        ))}
        {/* Get all users from bd, a new endpoint is requiered ir order to get their names and usersnames */}

        <Grid container spacing={3}>
          <Grid item xs={10}>
            <TextField
              name="tag_name"
              margin="dense"
              fullWidth
              value={formValues.tag_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}>
            <Box marginTop={'1em'}>
              <Button variant="outlined" disabled={formValues.tag_name===''? true: false} onClick={handleAddTag}>
                <SaveIcon />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
