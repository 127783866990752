import React, { useState, useEffect } from 'react';
import {
  Card,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Divider,
  Autocomplete,
  TextField,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MemberFormated from './../../redux/containers/teams/MemberFormated';

function TeamFormated(props) {
  let {
    updateTeamManager,
    name,
    members,
    teamId,
    getUserList,
    isAddingNewMembers,
    postTeamMembers,
    isDeletingMember,
    deleteTeamMember,
    isUnique,
    userInfo,
    getRole,
  } = props;

  const [hidden, setHidden] = useState(!isUnique);
  const [teamMembers, setTeamMembers] = useState(members);
  const [newMembers, setNewMembers] = useState([]);
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    const user = members.find(member => member.email === userInfo.email);
    if (user !== undefined) {
      setIsManager(user.is_manager);
    }
  }, []);

  const handleUpdateManager = async (team_id, user_id) => {
    await updateTeamManager(team_id, user_id);
    const updatedMember = teamMembers.filter(
      member => member.user_id === user_id,
    )[0];
    updatedMember.is_manager = !updatedMember.is_manager;
    setTeamMembers(
      teamMembers.map(member =>
        member.user_id !== updatedMember.user_id ? member : updatedMember,
      ),
    );
  };

  const handleAddNewMembers = async () => {
    try {
      const newTeamMembers = await postTeamMembers(
        teamId,
        newMembers.map(member => member.value),
      );
      setTeamMembers(newTeamMembers.members[0]);
    } catch (error) {
      alert(error);
    }
  };

  const handleDeleteTeamMember = async (team_id, member_id) => {
    try {
      const newTeamMembers = await deleteTeamMember(team_id, member_id);
      setTeamMembers(newTeamMembers.members[0]);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Card sx={{ width: '95vh' }}>
      <Box
        sx={{
          marginX: 3,
          marginY: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => setHidden(!hidden)}>
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography sx={{ fontSize: 20 }}>{name}</Typography>
          <Typography sx={{ fontSize: 15, marginLeft: 3, color: 'gray' }}>
            {' '}
            ({members.length} {members.length == 1 ? 'member' : 'members'})
          </Typography>
        </Box>
        <Tooltip title="Show Team Members">
          <IconButton>
            <ExpandMoreIcon sx={{ fontSize: 20, marginLeft: 3 }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: hidden ? 'none' : 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
        hidden={hidden}>
        <Divider />
        {teamMembers.map((member, idx) => {
          return (
            <MemberFormated
              member={member}
              teamId={teamId}
              handleUpdateManager={handleUpdateManager}
              key={idx}
              isDeletingMember={isDeletingMember}
              handleDeleteTeamMember={handleDeleteTeamMember}
              isManager={isManager}
            />
          );
        })}

        <Divider />

        {getRole.admin || isManager ? (
          !isAddingNewMembers ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                marginRight: '10px',
                alignItems: 'center',
              }}>
              <Autocomplete
                sx={{
                  width: '500px',
                  marginLeft: '20px',
                  marginY: '5px',
                  marginBottom: '20px',
                  padding: '3px',
                }}
                onChange={(e, value) => setNewMembers(value)}
                multiple
                options={getUserList}
                getOptionLabel={option =>
                  `${option.first_name} ${option.last_name}`
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="New members"
                    placeholder="Add new member"
                  />
                )}
              />
              <Button
                variant="outlined"
                sx={{ flexGrow: 1, height: '30px' }}
                onClick={handleAddNewMembers}>
                Save
              </Button>
            </Box>
          ) : (
            'Adding new members...'
          )
        ) : null}
      </Box>
    </Card>
  );
}

export default TeamFormated;
