import { connect } from 'react-redux';

import { setHideFinishedProjects, setShowMyProjects } from 'core/slices/users';

import { getTags } from 'core/slices/tags';

import {
  showMyProjects,
  hideFinishedProjects,
  getTagList,
  getRole,
  userInfo,
} from 'core/selectors';

import Home from '../../../components/home/Home';

export default connect(
  state => ({
    getRole: getRole(state),
    showMyProjects: showMyProjects(state),
    hideFinishedProjects: hideFinishedProjects(state),
    userInfo: userInfo(state),
    tags: getTagList(state),
  }),
  {
    setHideFinishedProjects,
    setShowMyProjects,
    getTags,
  },
)(Home);
