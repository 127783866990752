import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

// Icons
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import NewspaperIcon from '@mui/icons-material/Newspaper';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import GroupsIcon from '@mui/icons-material/Groups';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FolderIcon from '@mui/icons-material/Folder';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

// Styled components
const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const drawerWidth = 240;

const styles = {
  itemButton: {
    minHeight: 48,
    px: 2.5,
  },
  itemIcon: {
    minWidth: 0,
    justifyContent: 'center',
  },
  icon: {
    color: 'white',
    fontSize: 24,
  },
  text: {
    color: 'white',
    fontSize: 14,
    fontWeigh: 400,
  },
  divider: {
    background: 'white',
    marginTop: 8,
    marginBottom: 8,
  },
};

export default function SideMenu({ open, handleDrawer, DrawerHeader }) {
  const navigate = useNavigate();

  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: '#1a242d',
        },
      }}>
      {/* Menu close button */}
      <DrawerHeader>
        <IconButton onClick={handleDrawer}>
          {!open ? (
            <ChevronRightIcon style={styles.icon} />
          ) : (
            <ChevronLeftIcon style={styles.icon} />
          )}
        </IconButton>
      </DrawerHeader>

      <Divider style={{ background: open ? 'white' : 'inherit' }} />

      {/* Views navigation */}
      <List>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() => navigate('/newsletter')}>
          <ListItemButton
            sx={[
              styles.itemButton,
              { justifyContent: open ? 'flex-end' : 'center' },
            ]}>
            <ListItemIcon sx={[styles.itemIcon, { mr: open ? 3 : 'auto' }]}>
              <NewspaperIcon style={styles.icon} />
            </ListItemIcon>
            <ListItemText
              style={styles.text}
              primary="Newsletter"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() => navigate('/')}>
          <ListItemButton
            sx={[
              styles.itemButton,
              { justifyContent: open ? 'flex-end' : 'center' },
            ]}>
            <ListItemIcon sx={[styles.itemIcon, { mr: open ? 3 : 'auto' }]}>
              <BusinessCenterIcon style={styles.icon} />
            </ListItemIcon>
            <ListItemText
              style={styles.text}
              primary="Projects"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() => navigate('/teams')}>
          <ListItemButton
            sx={[
              styles.itemButton,
              { justifyContent: open ? 'flex-end' : 'center' },
            ]}>
            <ListItemIcon sx={[styles.itemIcon, { mr: open ? 3 : 'auto' }]}>
              <GroupsIcon style={styles.icon} />
            </ListItemIcon>
            <ListItemText
              style={styles.text}
              primary="My Teams"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() => {
            navigate('/adminView');
          }}>
          <ListItemButton
            sx={[
              styles.itemButton,
              { justifyContent: open ? 'flex-end' : 'center' },
            ]}>
            <ListItemIcon sx={[styles.itemIcon, { mr: open ? 3 : 'auto' }]}>
              <ManageAccountsIcon style={styles.icon} />
            </ListItemIcon>
            <ListItemText
              style={styles.text}
              primary="Users Admin"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() => {
            navigate('/coverage');
          }}>
          <ListItemButton
            sx={[
              styles.itemButton,
              { justifyContent: open ? 'flex-end' : 'center' },
            ]}>
            <ListItemIcon sx={[styles.itemIcon, { mr: open ? 3 : 'auto' }]}>
              < LineAxisIcon style={styles.icon} />
            </ListItemIcon>
            <ListItemText
              style={styles.text}
              primary="Coverage"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() => {
            navigate('/repositories');
          }}>
          <ListItemButton
            sx={[
              styles.itemButton,
              { justifyContent: open ? 'flex-end' : 'center' },
            ]}>
            <ListItemIcon sx={[styles.itemIcon, { mr: open ? 3 : 'auto' }]}>
              < FolderIcon style={styles.icon} />
            </ListItemIcon>
            <ListItemText
              style={styles.text}
              primary="Documentation"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          onClick={() => {
            navigate('/personal_stats');
          }}>
          <ListItemButton
            sx={[
              styles.itemButton,
              { justifyContent: open ? 'flex-end' : 'center' },
            ]}>
            <ListItemIcon sx={[styles.itemIcon, { mr: open ? 3 : 'auto' }]}>
              <IconComponent iconName="fitness" style={styles.icon} />
            </ListItemIcon>
            <ListItemText
              style={styles.text}
              primary="Personal Stats"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
