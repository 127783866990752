import { EncrDecrService } from './crypto';

/**
  * @ngdoc constant
  * @name LOCALSTORAGE_PATHS
  * @description
  * Constant to save the paths where the objects will be saved. All the paths must be written in this constant to have a better control of the paths.
  * @property {string} packages_repo is the key, and value 'p_rp'is the path to save in the local storage
*/
const LOCALSTORAGE_PATHS = {
    'packages_repo': 'p_rp',
}

/**
 * @ngdoc service
 * @name localStorageService
 * @description
 * Service to save and get objects from the local storage
 */
export class LocalStorageService {
    constructor() {
        this.EncrDecr = new EncrDecrService();
        this.paths = LOCALSTORAGE_PATHS;
    }

    /**
     * get the token to decrypt/encrypt the object
     * @return  {string}            Token to decrypt/encrypt the object. null if the token is not found
    */
    getToken() {
        const token = localStorage.getItem('rekol');
        return token ? token : '';
    }

    /**
     * save the object into the local storage
     * @param  	{string} 				pathCode 			Parameter to get the path
     * @param  	{object} 				objectInput 		Object to be saved
     * @param  	{boolean} 				canEncrypt 			Flag to encrypt the object
     * @param  	{string} 				expirationSeconds 	Parameter to set the expiration time of the object
     * @return  {void}
    */
    save(pathCode, objectInput, { canEncrypt = true, expirationSeconds = 'never' } = {}) {
        if (pathCode) {
            const dueTime = expirationSeconds === 'never' ? 'never' : Date.now() + expirationSeconds * 1000;
            const storedObject = {
                dueTime: dueTime,
                objectInput: objectInput,
            }
            if (canEncrypt) {
                const encryptedInput = this.EncrDecr.set_object(this.getToken(), storedObject);
                localStorage.setItem(pathCode, encryptedInput);
            } else {
                localStorage.setItem(pathCode, storedObject);
            }
        }
    }

    /**
     * get the object from the local storage
     * @return  {object}            Object saved in the local storage. null if the object is not found
     * @param  	{boolean} 				canDecrypt 			Flag to decrypt the object
     * @param  	{string} 				pathCode 			Parameter to get the path
     * @return  {object}            Object saved in the local storage. null if the object is not found
    */
    get(pathCode, { canEncrypt = true } = {}) {
        let storedObject = null;
        if (canEncrypt) {
            try {
                storedObject = this.EncrDecr.get_object(this.getToken(), localStorage.getItem(pathCode));
            } catch (error) { }
        } else {
            storedObject = localStorage.getItem(pathCode);
        }

        if (storedObject) {
            const { dueTime, objectInput } = storedObject;
            if (dueTime === 'never' || dueTime > Date.now()) {
                storedObject = objectInput;
            } else {
                localStorage.removeItem(pathCode);
                storedObject = null;
            }
        }
        return storedObject
    }
}
