import { useEffect } from "react";
import { Autocomplete, Box, Grid, Paper, Typography, TextField, RadioGroup, FormControlLabel, Radio, FormControl, Stack, FormLabel, Button } from "@mui/material";
import { TreeView, TreeItem } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FolderIcon from '@mui/icons-material/Folder';
import { useState } from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import FileDisplay from "./FileDisplay";
import { ReactComponent as GitlabIcon } from '../../../static/images/gitlab-logo-600.svg';


export default function DocumentationView(props) {
  const { fileData, softwareList, isLoadingFileData, getFile, groupsAndProjects, getGAPList, getSoftwares, findFiles, getFilesSearch } = props;
  const [selectedNodes, setSelectedNodes] = useState(["Zippedi"]);
  const [findNodes, setFindNodes] = useState(["Zippedi"]);
  const [selectedFileFormat, setSelectedFileFormat] = useState(".md");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [searchFilter, setSearchFilter] = useState(false);
  const renderTree = (nodes) => {
    if (!findNodes.includes(nodes.full_name) && searchFilter && findFiles.count !== 0) {
      return null;
    }
    if (nodes.path) {
      if (!nodes.path.includes(selectedFileFormat)) {
        return null;
      }
    }
    return (
      <TreeItem key={nodes.full_name} nodeId={nodes.full_name} label={nodes.name}
        icon={nodes.type === "group" ? <WorkspacesIcon />
          : nodes.type === "software" ? <GitlabIcon /> :
            nodes.children ? <FolderIcon /> : <DescriptionIcon />}
        onClick={(e) => {
          if (nodes.name.includes(".md") || nodes.name.includes(".toml")) {
            getFile(nodes.repository_id, nodes.path);
            setSelectedFile(nodes.full_name);
          }
        }}
        disabled={nodes.type === "software" && (!nodes.children ? true : nodes.children.filter((node) => node.path.includes(selectedFileFormat)).length === 0) ? true : false}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>)
  };


  useEffect(() => {
    getGAPList();
    getSoftwares();
  }, []);

  useEffect(() => {
    if (findFiles.find_files) {
      const resumen = findFiles.find_files.reduce(function (prev, currentFile) {
        const nodeIds = currentFile.split('/');
        const arrayNodesIds = nodeIds.map((_, idx) => nodeIds.slice(0, idx + 1).join('/'));
        return [...prev, ...arrayNodesIds]
      }, [])
      setFindNodes(resumen);
      setSelectedNodes(resumen);
    }
  }, [findFiles])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchQuery !== "") {
        getFilesSearch(searchQuery, selectedFileFormat);
        setSearchFilter(true);
      } else {
        setSelectedNodes(["Zippedi"]);
        setSearchFilter(false);
      }

    }, 2000); // 1000 milisegundos (1 segundo) de inactividad antes de realizar el log

    return () => clearTimeout(timeout);
  }, [searchQuery]);



  const handleFileFormat = (event, value) => {
    setSelectedFileFormat(value);
  }
  return (
    <>
      <Grid item xs={12}>
        <Paper elevation={0}
          sx={{
            display: 'flex',
            alignItem: 'left',
            maxWidth: '100vw',
            height: 'auto',
            padding: '1em',
            borderRadius: 3,
          }}>
          <Stack direction={"row"} width={"100%"} spacing={2} alignItems={"center"}>
            <Autocomplete
              options={softwareList}
              sx={{ width: "25%" }}
              getOptionLabel={(option) => option.name_with_namespace}
              renderOption={(props, option) => (
                <Box
                  key={option}
                  sx={{
                    borderRadius: '8px',
                    margin: '5px',
                    flexDirection: 'column',

                  }}
                  component="li"
                  {...props}
                >
                  <Typography align='center'>
                    {option.name}
                  </Typography>

                  <Typography align='center' sx={{ fontSize: 15, opacity: 0.5 }}>
                    {option.name_with_namespace}
                  </Typography>
                </Box>
              )}
              onChange={(e, value) => {
                if (value) {

                  const nodeIds = value.name_with_namespace.split('/');

                  const arrayNodesIds = nodeIds.map((_, idx) => nodeIds.slice(0, idx + 1).join('/'));
                  setSelectedNodes(arrayNodesIds);

                }
              }

              }
              renderInput={params => <TextField {...params} label="Repository" />}
            />
            <FormControl>
              <FormLabel>File Format</FormLabel>
              <RadioGroup row value={selectedFileFormat} onChange={handleFileFormat}>
                <FormControlLabel value={".md"} control={<Radio />} label={".md"} />
                <FormControlLabel value={".toml"} control={<Radio />} label={".toml"} />
              </RadioGroup>
            </FormControl>
            <Box sx={{ display: "flex", flexDirection: "row", maxWidth: "30%" }}>
              <TextField
                label="Search..."
                variant="outlined"
                onChange={e => setSearchQuery(e.target.value)}
                sx={{
                  width: 250,
                }}
              />
            </Box>
            <Box sx={{ width: "6%", marginLeft: 2 }}>
              {(findFiles.count || findFiles.count === 0) && searchFilter ?
                (
                  <Typography>
                    {findFiles.count} matches
                  </Typography>
                ) : null
              }
            </Box>
          </Stack>

        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} direction={"row"} >
          <Grid item xs={3}>
            <Box sx={{ position: "sticky", top: 70 }}>
              <Paper elevation={0}
                sx={{
                  display: 'flex',
                  alignItem: 'left',
                  maxWidth: '100vw',
                  height: '80%',
                  padding: '1em',
                  borderRadius: 3,
                }}>
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  onNodeToggle={(e, nodeId) => {
                    setSelectedNodes(nodeId)
                  }}
                  expanded={selectedNodes}

                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ flexGrow: 1, maxWidth: 500, overflowY: 'auto' }}
                >
                  {groupsAndProjects.name ? renderTree(groupsAndProjects) : null}
                </TreeView>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <FileDisplay fileData={fileData} isLoadingFileData={isLoadingFileData} selectedFile={selectedFile} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};