import { connect } from 'react-redux';
import { loadingUpdate } from 'core/slices/loading';
import { postProject, getProjects } from 'core/slices/projects';
import { getScopes, updateScopes } from 'core/slices/scopes';
import EditScopeDialog from '../../../components/project/EditScopeDialog';

export default connect(_ => ({}), {
  postProject,
  getProjects,
  loadingUpdate,
  updateScopes,
  getScopes,
})(EditScopeDialog);
