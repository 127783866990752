import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { reducer as projectsReducer } from './slices/projects';
import { reducer as rolesReducer } from './slices/roles';
import { reducer as usersReducer } from './slices/users';
import { reducer as tagsReducer } from './slices/tags';
import { reducer as scopesReducer } from './slices/scopes';
import { reducer as loadingReducer } from './slices/loading';
import { reducer as snackReducer } from './slices/snack';
import { reducer as bookmarkReducer } from './slices/bookmarks';
import { reducer as teamsReducer } from './slices/teams';
import { reducer as releasesReducer } from './slices/newsletter';
import { reducer as channelsReducer } from './slices/slack_channels';
import { reducer as sessionReducer } from './slices/session';
import { reducer as softwareReducer } from './slices/softwares';
import { reducer as coverageReducer } from './slices/coverage';
import { reducer as documentationReducer } from './slices/documentation';
import { reducer as personalStatsReducer } from './slices/personal_stats';
import { reducer as repositoriesReducer } from './slices/repositories';

export default function _createStore(services) {
  return createStore(
    combineReducers({
      projects: projectsReducer,
      roles: rolesReducer,
      users: usersReducer,
      tags: tagsReducer,
      scopes: scopesReducer,
      loading: loadingReducer,
      snack: snackReducer,
      bookmarks: bookmarkReducer,
      teams: teamsReducer,
      releases: releasesReducer,
      slack_channels: channelsReducer,
      session: sessionReducer,
      softwares: softwareReducer,
      coverage: coverageReducer,
      documentation: documentationReducer,
      personalStats: personalStatsReducer,
      repositories: repositoriesReducer,
    }),
    applyMiddleware(thunk.withExtraArgument({ services })),
  );
}
