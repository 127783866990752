import { connect } from 'react-redux';
import { setSnack } from 'core/slices/snack';
import { getSnack } from 'core/selectors';
import SnackAlert from '../../utils/SnackAlert';

export default connect(
  state => ({
    snack: getSnack(state),
  }),
  {
    setSnack,
  },
)(SnackAlert);
