import React, { useState } from 'react';
import {
  Card,
  Typography,
  Chip,
  Box,
  Avatar,
  Tooltip,
  Paper,
} from '@mui/material';
import MDEditor from '@uiw/react-md-editor';

import { ReactComponent as SlackIcon } from '../../../static/images/slack-new-logo.svg';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import ConfirmDialog from './ConfirmDialog';
import EditReleaseModal from '../../redux/containers/newsletter/EditReleaseModal';

import moment from 'moment';

function ReleaseCard({
  release,
  handleLabelClick,
  selectedLabels,
  handleSoftwareClick,
  selectedSoftwares,
  getRole,
  getTeamList,
  deleteRelease,
  labelList
}) {
  const date = moment(release.created_at);
  const [deleted, setDeleted] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    action: null,
    release: null,
    handle: null,
  });
  const [isFormOpen, setIsFormOpen] = useState(false);

  const closeDialog = () => {
    setDialog({
      open: false,
      action: null,
      release: null,
      handle: null,
    });
  };

  const handleDelete = releaseId => {
    deleteRelease(releaseId);
    setDeleted(true);
  };
  return (
    <Paper
      hidden={deleted}
      elevation={4}
      sx={{
        paddingY: 2,
        paddingX: 3,
        marginTop: 2,
        borderRadius: 3,
      }}>
      { }
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
          <ConfirmDialog dialog={dialog} handleClose={closeDialog} />
          <EditReleaseModal
            isFormOpen={isFormOpen}
            setIsFormOpen={setIsFormOpen}
            release={release}
          />
          <Box>
            <Tooltip
              placement="bottom-start"
              title={`${release.first_name} ${release.last_name}`}>
              <Avatar src={release.photo_link} sx={{ width: 40, height: 40 }} />
            </Tooltip>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 12, color: 'gray' }}>
              {release.team_name}
            </Typography>
            {
              release.implicated_softwares.every(software => software.trim() !== "") ? (
                <Typography
                  sx={{ fontWeight: '700', fontSize: 22, marginRight: 10 }}>
                  {release.release_title} [{release.implicated_softwares}{release.software_version ? ` ${release.software_version}` : ""}]
                </Typography>
              ) : (
                <Typography
                  sx={{ fontWeight: '700', fontSize: 22, marginRight: 10 }}>
                  {release.release_title}
                </Typography>
              )
            }

          </Box>
        </Box>
        <Box>
          <Typography sx={{ fontSize: 12, color: 'gray' }}>
            {date.format('MMMM Do YYYY')}
          </Typography>
          <Typography sx={{ fontSize: 12, color: 'gray' }}>
            {date.fromNow()}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, marginLeft: 1, marginTop: 1 }}>
        {release.labels
          ? release.labels.map((label, idx) => {
            return (
              <Chip
                clickable
                onClick={event => handleLabelClick(event.target.innerText)}
                key={idx}
                label={label}
                color="info"
                size="small"
                variant={
                  selectedLabels.includes(label) || (selectedLabels.includes("Other") && !labelList.includes(label)) ? 'filled' : 'outlined'
                }
                sx={{ fontSize: 12 }}
              />
            );
          })
          : null}

        {release.implicated_softwares.every(software => software.trim() !== "")
          ? release.implicated_softwares.map((software, idx) => {
            return (
              <Chip
                clickable
                onClick={event => handleSoftwareClick({ name: event.target.innerText })}
                key={idx}
                label={software}
                color="success"
                size="small"
                variant={
                  selectedSoftwares.includes(software) ? 'filled' : 'outlined'
                }
                sx={{ fontSize: 12 }}
              />
            );
          })
          : null}
      </Box>
      <Box sx={{ marginY: 2, width: '100%' }}>
        <MDEditor.Markdown source={release.release_content} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Box
          style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
            marginLeft: 5,
          }}>
          <Box sx={{ width: '22px', height: '22px' }}>
            <SlackIcon />
          </Box>
          <Typography sx={{ fontSize: 13, fontWeight: 'bold', color: 'gray' }}>
            {release.slack_channels &&
              release.slack_channels.map((channel, idx) => {
                return `#${channel} `;
              })}
          </Typography>
        </Box>
        {getRole.user_id === release.user_id || getRole.admin ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}>
            <EditIcon
              fontSize="small"
              sx={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => setIsFormOpen(true)}
            />
            <DeleteIcon
              fontSize="small"
              sx={{ cursor: 'pointer', color: 'red' }}
              onClick={() =>
                setDialog({
                  open: true,
                  action: 'Delete Release',
                  release: release.implicated_softwares.every(software => software.trim() !== "") ? `${release.release_title} [${release.implicated_softwares} ${release.software_version}]` : release.release_title,
                  handle: () => handleDelete(release.release_id),
                })
              }
            />
          </Box>
        ) : null}
      </Box>
    </Paper>
  );
}

export default ReleaseCard;
