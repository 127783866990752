import { connect } from 'react-redux';
import { postScope, getScopes } from 'core/slices/scopes';
import { getProjects } from 'core/slices/projects';
import { loadingUpdate } from 'core/slices/loading';
import AddScopeDialog from '../../../components/project/AddScopeDialog';

export default connect(_ => ({}), {
  postScope,
  getScopes,
  getProjects,
  loadingUpdate,
})(AddScopeDialog);
