import { connect } from 'react-redux';
import { getScopes } from 'core/slices/scopes';
import { getProjects } from 'core/slices/projects';
import { postTasks } from 'core/slices/tasks';
import { loadingUpdate } from 'core/slices/loading';
import AddTaskDialog from '../../../components/project/addTaskDialog';

export default connect(_ => ({}), {
  getScopes,
  getProjects,
  postTasks,
  loadingUpdate,
})(AddTaskDialog);
