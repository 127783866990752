import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function DeleteDialog(props) {
  const navigate = useNavigate();
  const {
    closeDialog,
    loadingUpdate,
    open,
    toDelete,
    toDeleteName,
    deleteFunction,
    getFunction,
    optionalGetId,
    goHome,
    getSecondary,
  } = props;
  const handleClose = () => {
    closeDialog();
  };

  const handleSubmit = async () => {
    handleClose();
    loadingUpdate(true);
    await deleteFunction(toDelete);
    if (optionalGetId) {
      await getFunction(optionalGetId);
    } else {
      await getFunction();
    }
    if (getSecondary) {
      await getSecondary();
    }
    loadingUpdate(false);
    if (goHome) {
      navigate('/');
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText paddingBottom="1em">
          Do you really want to delete this {toDeleteName}? This action cannot
          be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No, Cancel!</Button>
        <Button onClick={handleSubmit} color="error">
          Yes, delete it!
        </Button>
      </DialogActions>
    </Dialog>
  );
}
