import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card, CardContent } from '@mui/material';
import { Text, Title } from '@zippeditoolsjs/dashboards';
import { BarListComponent as BarList } from './BarList';
//My components
import CardList from './CardList';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ px: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TEST_DATA = [
  { name: '/home', value: 1230 },
  { name: '/contact', value: 751 },
  { name: '/gallery', value: 471 },
  { name: '/august-discount-offer', value: 280 },
  { name: '/case-studies', value: 78 },
];

export default function NewsletterStats(props) {
  const {
    newsLetterList,
    tagsData = TEST_DATA,
    title = 'Newsletter',
    subtitle = 'Types of newsletters that you have published',
    cardSx,
  } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
  }, [tagsData, newsLetterList]);

  return (
    <Card key={'March_2022_card'} sx={{ mt: 2, ...cardSx }}>
      <CardContent sx={{ overflowY: 'auto', maxHeight: '21.7em' }}>
        <Title>{title}</Title>
        <Text>{subtitle}</Text>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="newsletter tabs">
              <Tab label="Labels" {...a11yProps(0)} />
              <Tab label="Tags" {...a11yProps(1)} />
              {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <CardList listItems={newsLetterList} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <BarList
              data={tagsData}
              categoryName={'Tags'}
              valeName={'times used for you'}
              className="mt-2"
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            Item Three
          </CustomTabPanel>
        </Box>
      </CardContent>
    </Card>
  );
}
