import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import {

  Typography,
  Box,
  Button,
  CircularProgress,
  Chip,
  TextField,
  List,
  ListItemButton,
  ListItemText,
  ListItem,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import NewReleaseModal from '../../redux/containers/newsletter/NewReleaseModal';
import ReleaseCard from '../../redux/containers/newsletter/ReleaseCard';

const labels = ['Fix', 'Hotfix', 'Feature', 'Update', 'Config', 'Rollout', 'Refactor'].sort();


function NewsletterView(props) {
  const {
    getReleases,
    getTeamList,
    getSoftwareList,
    getSoftwares,
    isPostingRelease,
    getPostedRelease,
    getUpdatedRelease,
    isUpdatingRelease,
    releaseDatesList,
    requestReleasesDatesList,
    labelList,
    requestLabelList
  } = props;

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [showingReleases, setShowingReleases] = useState([]);
  const loadingRef = useRef();
  const [prevY, setPrevY] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [selectedHistory, setSelectedHistory] = useState(3);

  let prevYRef = useRef({});
  let releasesRef = useRef({});
  let pageRef = useRef({});
  let hasNextPageRef = useRef({});
  let textRef = useRef('');
  let selectedLabelsRef = useRef([]);
  let selectedSoftwaresRef = useRef([]);
  let selectedDateRef = useRef("");

  prevYRef.current = prevY;
  releasesRef.current = showingReleases;
  hasNextPageRef.current = hasNextPage;

  const fetchReleases = async isFirst => {

    try {
      const { total_count, data, has_next_page, page } = await getReleases(
        pageRef.current,
        selectedLabelsRef.current,
        selectedSoftwaresRef.current,
        textRef.current,
        selectedDateRef.current
      );
      isFirst
        ? setShowingReleases([...data])
        : setShowingReleases([...releasesRef.current, ...data]);
      setHasNextPage(has_next_page);
    } catch (error) {
      console.log(error);
    }
  };

  const firstFetch = async () => {
    pageRef.current = 1;
    await requestReleasesDatesList(selectedHistory)
    await fetchReleases(true);
    pageRef.current += 1;
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }
  };

  const handleSelectDate = (selectedDate) => {
    selectedDateRef.current = selectedDate;
    pageRef.current = 1;
    fetchReleases(true)
    pageRef.current += 1;
  };
  const handleSelectHistory = (value) => {
    setSelectedHistory(value);
    requestReleasesDatesList(value);
  };

  useEffect(() => {
    selectedLabelsRef.current = [];
    selectedSoftwaresRef.current = [];
    textRef.current = '';
    requestLabelList()
    firstFetch();
  }, [getPostedRelease, getUpdatedRelease]);

  useEffect(() => {
    getSoftwares();
  }, [getSoftwares]);

  const handleObserver = (entities, observer) => {
    const y = entities[0].boundingClientRect.y;
    if (prevYRef.current > y && hasNextPageRef.current) {
      fetchReleases(false);
      pageRef.current += 1;
    }
    setPrevY(y);
  };

  const handleLabelClick = title => {
    if (selectedLabelsRef.current.includes(title)) {
      let filteredArray = selectedLabelsRef.current.filter(
        item => item !== title,
      );
      selectedLabelsRef.current = filteredArray;
    } else {
      selectedLabelsRef.current = [...selectedLabelsRef.current, title];
    }
    firstFetch();
  };

  const handleSoftwareClick = title => {

    if (title) {
      if (selectedSoftwaresRef.current.includes(title.name)) {
        let filteredArray = selectedSoftwaresRef.current.filter(
          item => item !== title.name,
        );
        selectedSoftwaresRef.current = filteredArray;
      } else {
        selectedSoftwaresRef.current = [title.name];
      }
    }
    else {
      selectedSoftwaresRef.current = []
    }
    firstFetch();
  };

  return (
    <>
      <NewReleaseModal
        key={new Date().getTime()}
        isFormOpen={isFormOpen}
        setIsFormOpen={setIsFormOpen}
        teams={getTeamList}
      />

      {isPostingRelease || isUpdatingRelease ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100vh',
          }}>
          <CircularProgress color="error" />{' '}
          {isPostingRelease ? 'Posting New Release...' : 'Updating Release...'}
        </Box>
      ) : (
        <div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overFlow: 'auto',
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 5,
                padding: 2,
                borderRadius: 3,
                position: 'sticky',
                backgroundColor: '#e9e9f1',
                zIndex: 100,
                width: '100%',
                alignSelf: 'flex-start',
                top: 70,
              }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Autocomplete
                    sx={{
                      width: 400,
                      marginX: 2,
                      marginBottom: 1,
                    }}
                    size='small'
                    options={getSoftwareList}
                    value={selectedSoftwaresRef.current.length === 0 ? null : selectedSoftwaresRef.current}
                    isOptionEqualToValue={(option, value) => value.name ? option.name.includes(value.name) : option.name.includes(value[0])}
                    getOptionLabel={(option) => option.name_with_namespace ? option.name_with_namespace : option[0]}
                    renderOption={(props, option) => (
                      <Box
                        key={option}
                        sx={{
                          borderRadius: '8px',
                          margin: '5px',
                          flexDirection: 'column',

                        }}
                        component="li"
                        {...props}
                      >
                        <Typography align='center'>
                          {option.name}
                        </Typography>

                        <Typography align='center' sx={{ fontSize: 15, opacity: 0.5 }}>
                          {option.name_with_namespace}
                        </Typography>
                      </Box>
                    )}
                    onChange={(e, value) => handleSoftwareClick(value)}
                    renderInput={params => <TextField {...params} label="Software" />}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", marginRight: 2 }}>
                  <TextField
                    label="Search..."
                    variant="outlined"
                    size="small"
                    onChange={e => (textRef.current = e.target.value)}
                    sx={{
                      marginX: 2,
                      marginBottom: 1,
                      width: 250,

                    }}
                  />
                  <Button
                    variant="contained"
                    startIcon={<SearchIcon />}
                    sx={{ marginBottom: 1 }}
                    onClick={firstFetch}>
                    Search
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  marginX: 2,
                  maxWidth: '100%',
                  overflowX: 'hidden',
                  flexWrap: 'wrap',
                  marginTop: 1,
                }}>
                {labelList.sort().map((label, idx) => {
                  return (
                    <Chip
                      onClick={e => handleLabelClick(e.target.innerText)}
                      clickable
                      key={idx}
                      label={label}
                      size="small"
                      color="info"
                      variant={
                        selectedLabelsRef.current.includes(label)
                          ? 'filled'
                          : 'outlined'
                      }
                      sx={{ fontSize: 15, margin: 0.2 }}
                    />
                  );
                })}
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                borderRadius: 1,
                gap: 2,

              }}>
              {showingReleases.length > 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                  }}>
                  {showingReleases.map((release, i) => {
                    return (
                      <div
                        style={{ width: '100%' }}
                        key={i}
                        id={moment(release.created_at).format('Do MMMM YYYY')}>
                        <ReleaseCard
                          release={release}
                          handleLabelClick={handleLabelClick}
                          handleSoftwareClick={handleSoftwareClick}
                          selectedLabels={selectedLabelsRef.current}
                          selectedSoftwares={selectedSoftwaresRef.current}
                        />
                      </div>
                    );
                  })}
                  <Box
                    ref={loadingRef}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 1,
                      marginY: 2,
                    }}>
                    {hasNextPageRef.current ? (
                      <>
                        <CircularProgress size={25} />
                        <span>Loading more Releases...</span>
                      </>
                    ) : (
                      <>
                        <span>No more releases.</span>
                      </>
                    )}
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    paddingY: 2,
                    paddingX: 3,
                    marginTop: 2,
                    borderRadius: 3,
                    width: "80%"
                  }}>
                  {' '}
                  No matching Releases{' '}
                </Box>
              )}
              {releaseDatesList.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: 5,
                    backgroundColor: 'white',
                    borderRadius: 3,
                    minWidth: 'fit-content',
                    minHeight: '65vh',
                    alignSelf: 'flex-start',
                    top: 219,
                    position: 'sticky',
                  }}>
                  <Button
                    onClick={() => setIsFormOpen(true)}
                    variant="contained"
                    startIcon={<EditIcon />}
                    sx={{
                      marginX: 2,
                      marginTop: 2,
                      marginBottom: 2,
                      borderRadius: 3,
                      align: "center"
                    }}>

                    Write new release...


                  </Button>

                  <Box
                    sx={{
                      marginX: 2,
                      marginTop: 2,
                    }}>
                    <FormControl sx={{ m: 1, minWidth: 170 }} size="small">
                      <InputLabel id="demo-select-small-label">History</InputLabel>
                      <Select
                        value={selectedHistory}
                        label="History"
                        onChange={(event) => handleSelectHistory(event.target.value)}
                      >
                        <MenuItem value={3}>Last 3 Months</MenuItem>
                        <MenuItem value={6}>Last 6 Months</MenuItem>
                        <MenuItem value={12}>Last Year</MenuItem>
                      </Select>
                    </FormControl>

                    <Box
                      sx={{
                        overflowY: 'scroll',
                        maxHeight: "50vh",
                      }}>
                      <List>
                        <ListItem disablePadding >
                          <ListItemButton
                            selected={selectedDateRef.current === ""}
                            onClick={() => handleSelectDate("")}>
                            <ListItemText >
                              <Box sx={{ color: "gray", fontSize: 5, display: "inline-flex" }}>
                                <KeyboardArrowRightIcon />
                                <Typography>All releases
                                </Typography>
                              </Box>

                            </ListItemText>
                          </ListItemButton>
                        </ListItem>

                        {releaseDatesList.map((date, i) => {
                          const formatedTime = moment(date).format('Do MMMM YYYY').toString()
                          return (
                            <ListItem disablePadding key={i}>
                              <ListItemButton
                                selected={selectedDateRef.current === date}
                                onClick={() => handleSelectDate(date)}>
                                <ListItemText >
                                  <Box sx={{ color: "gray", fontSize: 5, display: "inline-flex" }}>
                                    <KeyboardArrowRightIcon />
                                    <Typography  >{formatedTime}</Typography>
                                  </Box>

                                </ListItemText>
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  </Box>
                </Box>
              )}

            </Box>
          </Box>
        </div>
      )}
    </>
  );
}

export default NewsletterView;
