import { createReducer } from './utils';

import {
  USERS_REQUEST,
  USERS_AVAILABLE,
  USERS_UNAVAILABLE,
  USERS_USERNAME_AVAILABLE,
  USER_PROFILE_REQUEST,
  USER_PROFILE_AVAILABLE,
  USER_PROFILE_UNAVAILABLE,
  USER_LOGOUT,
  USER_ROLES_REQUEST,
  USER_ROLES_AVAILABLE,
  USER_ROLES_UNAVAILABLE,
  USER_SESSION_SET,
  USER_UPDATE,
  USER_UPDATED,
  USER_UPDATE_FAILED,
  USER_DELETE,
  USER_DELETED,
  USER_DELETE_FAILED,
  USER_MY_PROJECTS,
  USER_HIDE_FINISHED,
  USER_SHOW_BOOKMARKED,
  SNACK_SET,
} from './constants';

const defaultState = {
  loading_users: false,
  users: false,
  username: '',
  loading_profile: false,
  profile: {},
  profileState: false,
  sesion: false,
  roles: '',
  loading_roles: false,
  sent: false,
  updating_tags: false,
  deleting_tags: false,
  hideFinished: true,
  myProjects: true,
  showBookmarked: true,
  isAdmin: false,
};

export const reducer = createReducer(defaultState, {
  [USER_PROFILE_REQUEST]: handleProfileRequest,
  [USER_PROFILE_AVAILABLE]: handleProfileAvailable,
  [USER_PROFILE_UNAVAILABLE]: handleProfileUnavailable,
  [USERS_REQUEST]: handleUsersRequest,
  [USERS_AVAILABLE]: handleUsersAvailable,
  [USERS_UNAVAILABLE]: handleUsersUnavailable,
  [USER_ROLES_REQUEST]: handleRoleRequest,
  [USER_ROLES_AVAILABLE]: handleRoleAvailable,
  [USER_ROLES_UNAVAILABLE]: handleRoleUnavailable,
  [USER_UPDATE]: handleUserUpdate,
  [USER_UPDATED]: handleUserUpdateSuccess,
  [USER_UPDATE_FAILED]: handleUserUpdateFailure,
  [USER_DELETE]: handleUserDelete,
  [USER_DELETED]: handleUserDeleted,
  [USER_DELETE_FAILED]: handleUserDeleteFailure,
  [USER_MY_PROJECTS]: handleMyProjects,
  [USER_HIDE_FINISHED]: handleHideFinished,
  [USER_SHOW_BOOKMARKED]: handleShowBookmarked,
});

function handleProfileRequest(state, _) {
  return {
    ...state,
    loading_profile: true,
  };
}

function handleProfileAvailable(state, { payload: { profile, isAdmin } }) {
  return {
    ...state,
    profile: profile,
    loading_profile: false,
    profileState: true,
    isAdmin: isAdmin,
  };
}

function handleProfileUnavailable(state, _) {
  return defaultState;
}

function handleUsersRequest(state, _) {
  return {
    ...state,
    loading_users: true,
  };
}

function handleUsersUnavailable(state, _) {
  return defaultState;
}

function handleUsersAvailable(state, { payload: { users } }) {
  return {
    ...state,
    loading_users: false,
    users: true,
    userList: users,
  };
}

function handleRoleRequest(state, _) {
  return {
    ...state,
    loading_roles: true,
  };
}

function handleRoleAvailable(state, { payload: { roles } }) {
  return {
    ...state,
    roles: roles,
    loading_roles: false,
  };
}

function handleRoleUnavailable(state, _) {
  return defaultState;
}

function handleUserUpdate(state, _) {
  return {
    ...state,
    updating_tags: true,
  };
}

function handleUserUpdateSuccess(state, _) {
  return {
    ...state,
    sent: true,
    updating_tags: false,
  };
}

function handleUserUpdateFailure(state, _) {
  return defaultState;
}

function handleUserDelete(state, _) {
  return {
    ...state,
    deleting_tags: true,
  };
}

function handleUserDeleted(state, _) {
  return {
    ...state,
    deleting_tags: false,
  };
}

function handleUserDeleteFailure(state, _) {
  return defaultState;
}

function handleMyProjects(state, { payload: { myProjects } }) {
  return {
    ...state,
    myProjects: myProjects,
  };
}

function handleHideFinished(state, { payload: { hideFinished } }) {
  return {
    ...state,
    hideFinished: hideFinished,
  };
}
function handleShowBookmarked(state, { payload: { showBookmarked } }) {
  return {
    ...state,
    showBookmarked: showBookmarked,
  };
}

export function getUsers() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: USERS_REQUEST });
    try {
      const users = await dataSource.getUsers();
      dispatch({
        type: USERS_AVAILABLE,
        payload: { users },
      });
    } catch (error) {
      dispatch({
        type: USERS_UNAVAILABLE,
        payload: { error },
      });
    }
  };
}

export function setShowMyProjects(myProjects) {
  return async dispatch => {
    dispatch({
      type: USER_MY_PROJECTS,
      payload: { myProjects },
    });
  };
}

export function setHideFinishedProjects(hideFinished) {
  return async dispatch => {
    dispatch({
      type: USER_HIDE_FINISHED,
      payload: { hideFinished },
    });
  };
}

export function setShowBookmarked(showBookmarked) {
  return async dispatch => {
    dispatch({
      type: USER_SHOW_BOOKMARKED,
      payload: { showBookmarked },
    });
  };
}

export function getUserRoles() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: USER_ROLES_REQUEST });
    try {
      const roles = await dataSource.getUserRoles();
      dispatch({
        type: USER_ROLES_AVAILABLE,
        payload: { roles },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: USER_ROLES_UNAVAILABLE,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function updateUser(user) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: USER_UPDATE });
      await dataSource.updateUser(user);
      dispatch({ type: USER_UPDATED });
      const snack = {
        open: true,
        message: 'User updated',
        severity: 'success',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function deleteUser(userId) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: USER_DELETE });
      await dataSource.deleteUser(userId);
      dispatch({ type: USER_DELETED });
      const snack = {
        open: true,
        message: 'User deleted',
        severity: 'success',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: USER_DELETE_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
