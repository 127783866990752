import { connect } from 'react-redux';
import { getRole } from 'core/selectors';
import Profile from '../../../components/user/Profile';

export default connect(
  state => ({
    getRole: getRole(state),
  }),
  {},
)(Profile);
