import { Alert, AlertTitle, Box, CircularProgress, Collapse, Divider, Grid, IconButton, InputAdornment, LinearProgress, List, ListItem, ListItemText, Pagination, Paper, Stack, TextField, Tooltip, Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import React, { Fragment, useEffect, useState } from 'react';

import FormatSizeIcon from '@mui/icons-material/FormatSize';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SortIcon from '@mui/icons-material/Sort';
import InfoIcon from '@mui/icons-material/Info';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import { styled } from '@mui/material/styles';

import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as ColorIcon } from '../../../static/images/gitlab-logo-500.svg';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Styled components
const ListItemLink = styled(ListItemButton)(({ theme }) => ({
    padding: 0,
    textDecoration: 'underline',
    '& .MuiListItemText-root': {
        padding: 0,
        margin: 0,
    },
    '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'none',
    },
    '& .MuiListItemButton-root': {
        padding: 0,
        margin: 0,
    },
}));

const LIST_BATCHES = 10;
function PackagesMain(props) {
    const {
        requirements,
        isLoadingRequirements,
    } = props;
    const [searchQuery, setSearchQuery] = useState({ 'package': '', 'version': '' });
    const [requirementPerRepo, setRequirementPerRepo] = useState([]);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [sortType, setSortType] = useState('by_number');
    const [reposFound, setReposFound] = useState(null);
    const [searchStates, setSearchStates] = useState({
        exactMatch: false,
        capsCheck: false,
    });
    const [listPage, setListPage] = useState(1);
    const [openedReq, setOpenedReq] = useState({});

    const handleSearchStates = (newState) => {
        setSearchStates({ ...searchStates, ...newState });
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setListPage(value);
    };

    const handleIsSamePackageVersion = (packageVersion, repoPackageVersion) => {
        if (packageVersion.length > 0) {
            if (repoPackageVersion === packageVersion) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    const handleReposSearch = (requirementPerRepo, searchQuery, searchStates) => {
        setIsLoadingSearch(true);
        if (searchQuery?.package?.length > 0) {
            let { package: packageName, version: packageVersion } = searchQuery;
            const { exactMatch, capsCheck } = searchStates;
            const newReposFound = [];
            packageName = capsCheck ? packageName : packageName.toLowerCase();
            requirementPerRepo.forEach((requirement) => {
                const { name: requirementName, repos: requirementRepos } = requirement;
                const comparingRequirementName = capsCheck ? requirementName : requirementName.toLowerCase();
                const filteredRepos = { name: requirementName, repos: [] }
                if (comparingRequirementName.includes(packageName)) {
                    requirementRepos.forEach((repo) => {
                        const { package_version: repoPackageVersion } = repo;
                        if (exactMatch && comparingRequirementName === packageName) {
                            if (handleIsSamePackageVersion(packageVersion, repoPackageVersion)) {
                                filteredRepos.repos.push(repo);
                            }
                        } else if (!exactMatch && handleIsSamePackageVersion(packageVersion, repoPackageVersion)) {
                            filteredRepos.repos.push(repo);
                        }
                    });

                    if (filteredRepos.repos.length > 0) {
                        newReposFound.push(filteredRepos);
                    }
                }
            });

            setReposFound(newReposFound);
            if (newReposFound.length === 1) {
                setOpenedReq({ ...openedReq, [newReposFound[0].name]: true });
            }
        } else {
            setReposFound(null);
        }
        setIsLoadingSearch(false);
    }

    const handleSelectPackage = (packageSelected) => {
        return () => {
            setSearchQuery({ ...searchQuery, package: packageSelected.name });
            setSearchStates({ ...searchStates, exactMatch: true });
        }
    }

    const handleLink = (url) => {
        return () => {
            window.open(url, '_blank');
        }
    }

    const openReposList = (reqFoundName) => {
        return () => {
            setOpenedReq({ ...openedReq, [reqFoundName]: !openedReq[reqFoundName] });
        }
    }

    const handleSortPackages = (sortType) => {
        return () => {
            setSortType(sortType);
            const newRequirementPerRepo = [...requirementPerRepo];
            if (sortType === 'by_alpha') {
                newRequirementPerRepo.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            } else if (sortType === 'by_number') {
                newRequirementPerRepo.sort((a, b) => {
                    return b.repos.length - a.repos.length;
                });
            }
            setRequirementPerRepo(newRequirementPerRepo);
        }
    }

    useEffect(() => {
        const newPackagesPerRepo = requirements ? Object.keys(requirements).map((requirement) => {
            return {
                name: requirement,
                repos: requirements[requirement],
            }
        }
        ) : [];
        newPackagesPerRepo.sort((a, b) => { return b.repos.length - a.repos.length; });
        setRequirementPerRepo(newPackagesPerRepo)
    }, [isLoadingRequirements, requirements]);

    useEffect(() => {
        let searchTimeOut = setTimeout(() => { handleReposSearch(requirementPerRepo, searchQuery, searchStates); }, 200);
        return () => {
            setIsLoadingSearch(false);
            clearTimeout(searchTimeOut);
        }
    }, [requirementPerRepo, searchQuery, searchStates]);

    return (
        <Grid container spacing={2} direction={"column"}>
            <Grid item xs={12}>
                <Paper elevation={0}
                    sx={{
                        display: 'flex',
                        alignItem: 'left',
                        height: 'auto',
                        padding: '1em',
                        borderRadius: 3,
                    }}>
                    <Stack direction={"row"} width={"100%"} spacing={2} alignItems={"center"}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <TextField
                                disabled={isLoadingRequirements}
                                label="Search Package"
                                variant="outlined"
                                autoFocus={true}
                                value={searchQuery?.package}
                                InputLabelProps={{ shrink: true }}
                                onChange={e => setSearchQuery({ ...searchQuery, package: e.target.value?.trim() })}
                                sx={{ width: 'auto', minWidth: '35em' }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start" sx={{ my: 4 }}>
                                        <Grid container sx={{ m: 0, p: 0, width: '1em' }}>
                                            <Grid item sx={{ p: '1px' }}>
                                                <Box sx={{
                                                    ...{
                                                        ...(searchStates.exactMatch && {
                                                            background: 'aliceblue',
                                                            border: '1px solid #72baf8',
                                                            borderRadius: '4px',
                                                            color: 'white',
                                                        }),
                                                    }
                                                }}>
                                                    <Tooltip title="Exact Match">
                                                        <IconButton onClick={() => { handleSearchStates({ exactMatch: !searchStates.exactMatch }) }}
                                                            sx={{
                                                                p: 0,
                                                                m: 0,
                                                                color: searchStates.exactMatch ? '#4a97df' : 'text.secondary',
                                                                '&:hover': {
                                                                    backgroundColor: 'transparent',
                                                                },
                                                            }}>
                                                            <TextFormatIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </Grid>
                                            <Grid item sx={{ p: '1px' }}>
                                                <Box sx={{
                                                    ...{
                                                        ...(searchStates.capsCheck && {
                                                            background: 'aliceblue',
                                                            border: '1px solid #72baf8',
                                                            borderRadius: '4px',
                                                            color: 'white',
                                                        }),
                                                    }
                                                }}>

                                                    <Tooltip title="Caps Check">
                                                        <IconButton onClick={() => { handleSearchStates({ capsCheck: !searchStates.capsCheck }) }}
                                                            sx={{
                                                                p: 0,
                                                                m: 0,
                                                                color: searchStates.capsCheck ? '#4a97df' : 'text.secondary',
                                                                '&:hover': {
                                                                    backgroundColor: 'transparent',
                                                                },
                                                            }}>
                                                            <FormatSizeIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </InputAdornment>,
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <TextField
                                disabled={isLoadingRequirements || (searchQuery?.package === '' && reposFound?.length === 0)}
                                label="Version"
                                variant="outlined"
                                onChange={e => setSearchQuery({ ...searchQuery, version: e.target.value?.trim() })}
                                sx={{ width: 'auto', minWidth: '10em' }}
                            />
                        </Box>
                    </Stack>
                    <Box sx={{ display: "flex", width: 'inherit', alignItems: 'end', alignSelf: 'start' }}>
                        <Tooltip title='for the moment only the "master" branch is considered'>
                            <InfoIcon color='info' />
                        </Tooltip>
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={1} direction={"row"} >
                    <Grid item xs={4}>
                        <Paper elevation={0} sx={{ borderRadius: 3, padding: '1em' }}>
                            <Box sx={{
                                alignItems: "center",
                                display: "flex",
                                height: 'auto',
                                width: '100%',
                                justifyContent: "end"
                            }}>
                                <Tooltip title="Sort by Alpha">
                                    <IconButton onClick={handleSortPackages('by_alpha')}
                                        sx={{
                                            p: 0,
                                            m: 0,
                                            color: sortType === 'by_alpha' ? '#4a97df' : 'text.secondary',
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                        }}>
                                        <SortByAlphaIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Sort by number of repo">
                                    <IconButton onClick={handleSortPackages('by_number')}
                                        sx={{
                                            p: 0,
                                            m: 0,
                                            ml: 1,
                                            color: sortType === 'by_number' ? '#4a97df' : 'text.secondary',
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                        }}>
                                        <SortIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Divider />
                            <List dense sx={{ width: '100%', bgcolor: 'background.paper', minHeight: '50vh' }}>
                                {requirementPerRepo?.length ? requirementPerRepo.slice((listPage - 1) * LIST_BATCHES, listPage * LIST_BATCHES).map((requirement, index) => {
                                    return (
                                        <>
                                            <ListItemButton key={`${requirement.name}-${index}`} onClick={handleSelectPackage(requirement)}>
                                                <ListItemText
                                                    primary={requirement.name}
                                                    secondary={`${requirement.repos?.length} repositories`}
                                                />
                                            </ListItemButton>
                                            <Divider component="li" />
                                        </>
                                    )
                                })
                                    : null}
                            </List>
                            <Box sx={{
                                alignItems: "center",
                                display: "flex",
                                height: 'auto',
                                width: '100%',
                                justifyContent: "center"
                            }}>
                                {
                                    requirementPerRepo?.length ?
                                        <Pagination count={Math.ceil(requirementPerRepo?.length / LIST_BATCHES)} page={listPage} onChange={handlePageChange} />
                                        : null
                                }
                            </Box>
                        </Paper>
                    </Grid >

                    <Grid item xs={8}>
                        <Paper elevation={0} sx={{ borderRadius: 3, padding: '1em', maxHeight: '43.2em', overflowY: 'auto' }}>
                            {isLoadingSearch &&
                                <LinearProgress color='secondary' sx={{ mb: 1, width: '100%', borderRadius: '15px' }} />
                            }
                            {!searchQuery?.package ?
                                <Box sx={{
                                    alignItems: "center",
                                    display: "flex",
                                    height: 'auto',
                                    width: '100%',
                                    justifyContent: "center"
                                }}>
                                    {isLoadingRequirements ?
                                        <CircularProgress />
                                        : <Alert severity="info">
                                            <AlertTitle>
                                                Search a package
                                            </AlertTitle>
                                        </Alert>
                                    }

                                </Box>
                                : reposFound?.length > 0 ?
                                    <>
                                        <Typography variant="body2" component="p" sx={{ color: '#7d7d7d' }}>
                                            {reposFound.reduce((acc, reqFound) => { return acc + reqFound.repos?.length }, 0)} results in {reposFound?.length} packages
                                        </Typography>
                                        <Divider />
                                        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                            {reposFound.map((reqFound, index) =>
                                                <Fragment key={`${reqFound.name}-${index}-found-list`}>
                                                    <ListItemButton key={`${reqFound.name}-${index}-found`} onClick={openReposList(reqFound.name)} sx={{ width: '100%' }}>
                                                        <ListItemText
                                                            primary={<Typography sx={{ fontSize: 'medium' }}>{reqFound.name}</Typography>}
                                                            secondary={<Typography sx={{ fontSize: 'small' }}>{`${reqFound.repos?.length} repositories`}</Typography>}
                                                        />
                                                        {openedReq[reqFound.name] ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItemButton>
                                                    <Collapse in={openedReq[reqFound.name]} timeout="auto" unmountOnExit>
                                                        <List dense component="div">
                                                            {reqFound.repos?.map((repo, index) => {
                                                                return (
                                                                    <ListItem key={`${repo}-${index}-found`} sx={{ my: 0, pt: 0, pb: 2 }}>
                                                                        <ListItemText
                                                                            sx={{ my: 0, py: 0 }}
                                                                            primary={
                                                                                <Typography sx={{ fontWeight: 'bold', fontSize: 'small' }}>
                                                                                    {repo.repo_name}
                                                                                </Typography>
                                                                            }
                                                                            secondary={
                                                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                                    <Stack direction={"row-reverse"} spacing={0} alignItems={"center"} sx={{ width: '100%' }} >
                                                                                        <SvgIcon fontSize="medium"><ColorIcon /></SvgIcon>

                                                                                        <Tooltip title='go to the commit'>
                                                                                            <Typography sx={{ fontSize: 'small', pl: '3px' }}>
                                                                                                {repo.short_id ?
                                                                                                    <ListItemLink onClick={handleLink(repo.repo_url)} disableRipple disableFocusRipple disableElevation component="a" target="_blank" rel="noopener noreferrer">
                                                                                                        <ListItemText primary={repo.short_id} />
                                                                                                    </ListItemLink>
                                                                                                    : 'No commit found'}
                                                                                            </Typography>
                                                                                        </Tooltip>

                                                                                        <Typography sx={{ fontSize: 'small' }} ml={0} mr={"auto"}>
                                                                                            Version: {repo.package_version}
                                                                                        </Typography>
                                                                                    </Stack>
                                                                                </Box>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                )
                                                            }
                                                            )}
                                                        </List>
                                                    </Collapse>
                                                    <Divider />
                                                </Fragment>
                                            )
                                            }
                                        </List>
                                    </>
                                    : !isLoadingSearch ?
                                        <Box sx={{
                                            alignItems: "center",
                                            display: "flex",
                                            height: 'auto',
                                            width: '100%',
                                            justifyContent: "center"
                                        }}>
                                            {isLoadingRequirements ?
                                                <CircularProgress />
                                                :
                                                <Alert severity="info">
                                                    <AlertTitle>
                                                        No Repositories Found for the package "{searchQuery?.package}" {searchQuery?.version?.length > 0 ? `with version "${searchQuery?.version}"` : null}
                                                    </AlertTitle>
                                                </Alert>
                                            }
                                        </Box>
                                        : null
                            }
                        </Paper>
                    </Grid >

                </Grid>
            </Grid>
        </Grid>
    );
}

export default PackagesMain;
