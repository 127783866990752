import React, { useState, useEffect } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { styled } from '@mui/material/styles';

// My components
import Navbar from './Navbar';
import SideMenu from './SideMenu';

// Styled components
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function Layout(props) {
  const {
    children,
    getUserRoles,
    isLoadingRole,
    isLoadingProjects,
    isLoadingUsers,
    isLoadingScopes,
    isLoadingTeams,
    isLoadingReleases,
    isLoadingSlackChannels,
    isLoadingCoverageList,
    getTeams,
    getSlackChannels,
    isLoadingGAP,
    isLoadingFindFiles,
  } = props;
  const [open, setOpen] = useState(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    getUserRoles();
    getTeams();
    getSlackChannels();
  }, []);

  return (
    <Box style={{ display: 'flex' }}>
      <CssBaseline />
      {/* Top navigation bar */}
      <Navbar
        open={open}
        handleDrawer={handleDrawer}
        isLoading={
          isLoadingRole ||
          isLoadingProjects ||
          isLoadingUsers ||
          isLoadingScopes ||
          isLoadingTeams ||
          isLoadingSlackChannels ||
          isLoadingReleases ||
          isLoadingCoverageList ||
          isLoadingGAP ||
          isLoadingFindFiles
        }
      />

      {/* Left navigation bar */}
      <SideMenu
        open={open}
        handleDrawer={handleDrawer}
        DrawerHeader={DrawerHeader}
      />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        {children}
      </Box>
    </Box>
  );
}

export default Layout;
