export function isPostingProjects({ projects: { posting_projects } }) {
  return posting_projects;
}

export function isLoadingProjects({ projects: { loading_projects } }) {
  return loading_projects;
}

export function hasProjects({ projects: { projects } }) {
  return projects;
}

export function getProjectList({ projects: { projectList } }) {
  return projectList;
}

export function getProject({ projects: { project } }) {
  return project;
}

export function hasUsers({ users: { users } }) {
  return users;
}

export function isLoadingUsers({ users: { loading_users } }) {
  return loading_users;
}

export function getUserList({ users: { userList } }) {
  return userList ?? [];
}

export function hasTags({ tags: { tags } }) {
  return tags;
}

export function getTagList({ tags: { tagList } }) {
  return tagList;
}

export function getRoles({ roles: { roles } }) {
  return roles;
}

export function getRoleList({ roles: { roleList } }) {
  return roleList;
}

export function isLoadingScopes({ scopes: { loading_scopes } }) {
  return loading_scopes;
}

export function hasScopes({ scopes: { scopes } }) {
  return scopes;
}

export function getScopeList({ scopes: { scopeList } }) {
  return scopeList;
}

export function getProfileData({ users: { profile } }) {
  return profile;
}
export function isUserAdmin({ session: { isUserAdmin } }) {
  return isUserAdmin.is_admin;
}

export function isLoadingProfile({ users: { loading_profile } }) {
  return loading_profile;
}

export function hasProfile({ users: { profileState } }) {
  return profileState;
}

export function getRole({ users: { roles } }) {
  return roles;
}

export function isLoadingRole({ users: { loading_roles } }) {
  return loading_roles;
}

export function loading({ loading: { loading } }) {
  return loading;
}

export function hasRoles({ roles: { roles } }) {
  return roles;
}

export function hideFinishedProjects({ users: { hideFinished } }) {
  return hideFinished;
}

export function showMyProjects({ users: { myProjects } }) {
  return myProjects;
}

export function showBookmarked({ users: { showBookmarked } }) {
  return showBookmarked;
}

export function getSnack({ snack: { snack } }) {
  return snack;
}

export function getBookmarkList({ bookmarks: { bookmarkList } }) {
  return bookmarkList;
}

export function isLoadingTeams({ teams: { loading_teams } }) {
  return loading_teams;
}

export function getTeamList({ teams: { teamList } }) {
  return teamList;
}

export function getSoftwareList({ softwares: { softwareList } }) {
  return softwareList;
}
export function getGTags({ softwares: { gitlabTags } }) {
  return gitlabTags;
}
export function isLoadingGitlabTags({ softwares: { loading_gitlab_tags } }) {
  return loading_gitlab_tags;
}

export function isLoadingReleases({ releases: { loading_releases } }) {
  return loading_releases;
}

export function getReleasesList({ releases: { releasesList } }) {
  return releasesList;
}
export function getReleasesDatesList({ releases: { releaseDatesList } }) {
  return releaseDatesList;
}
export function isLoadingReleaseDates({ releases: { loadingReleaseDates } }) {
  return loadingReleaseDates;
}

export function isPostingRelease({ releases: { posting_releases } }) {
  return posting_releases;
}

export function getPostedRelease({ releases: { posted_release } }) {
  return posted_release;
}

export function isLoadingLabels({ releases: { loadingLabel } }) {
  return loadingLabel;
}

export function getLabelList({ releases: { labelList } }) {
  return labelList;
}

export function getSlackChannelsList({
  slack_channels: { slackChannelsList },
}) {
  return slackChannelsList;
}

export function isLoadingSlackChannels({
  slack_channels: { loading_channels },
}) {
  return loading_channels;
}

export function isAddingNewMembers({ teams: { adding_team_members } }) {
  return adding_team_members;
}

export function getNewMembersList({ teams: { new_team_members } }) {
  return new_team_members;
}

export function isDeletingMember({ teams: { deleting_team_member } }) {
  return deleting_team_member;
}

export function isLoadingSession({ session: { loading } }) {
  return loading;
}

export function userInfo({ session: { userInfo } }) {
  return userInfo;
}

export function hasSession({ session: { loggedIn } }) {
  return loggedIn;
}

export function isDeletingRelease({ releases: { deleting_release } }) {
  return deleting_release;
}

export function isUpdatingRelease({ releases: { updating_release } }) {
  return updating_release;
}

export function getUpdatedRelease({ releases: { updated_release } }) {
  return updated_release;
}
export function getCoverageList({ coverage: { coverageProjectList } }) {
  return coverageProjectList;
}
export function isLoadingCoverageList({ coverage: { loading } }) {
  return loading;
}
export function getGGroups({ coverage: { gitlabGroups } }) {
  return gitlabGroups;
}
export function getCoverageDate({ coverage: { coverageDate } }) {
  return coverageDate;
}
export function getGAP({ documentation: { groupsAndProjects } }) {
  return groupsAndProjects;
}
export function isLoadingGAP({ documentation: { loading } }) {
  return loading;
}
export function getFileData({ documentation: { file_data } }) {
  return file_data;
}
export function isLoadingFileData({ documentation: { loading_file } }) {
  return loading_file;
}
export function getFindFiles({ documentation: { findFiles } }) {
  return findFiles;
}
export function isLoadingFindFiles({ documentation: { loadingFindFiles } }) {
  return loadingFindFiles;
}

export function getOwnCyclesInfo({ personalStats: { ownCycles } }) {
  return ownCycles;
}

export function getPopularTagsInfo({ personalStats: { popularTags } }) {
  return popularTags;
}

export function getTopTeammatesInfo({ personalStats: { topTeammates } }) {
  return topTeammates;
}

export function getTotalMembersInfo({ personalStats: { totalMembers } }) {
  return totalMembers;
}

export function getCreatedTasksInfo({ personalStats: { createdTasks } }) {
  return createdTasks;
}

export function getMembersByTeamInfo({ personalStats: { membersByTeam } }) {
  return membersByTeam;
}

export function getCreatedScopesInfo({ personalStats: { createdScopes } }) {
  return createdScopes;
}

export function getTotalReleasesInfo({ personalStats: { totalReleases } }) {
  return totalReleases;
}

export function getMovementsPerWeekInfo({
  personalStats: { movementsPerWeek },
}) {
  return movementsPerWeek;
}

export function getFinishedCyclesTimesInfo({
  personalStats: { finishedCyclesTimes },
}) {
  return finishedCyclesTimes;
}

export function getNewslettersTypesPublishedInfo({
  personalStats: { newslettersTypesPublished },
}) {
  return newslettersTypesPublished;
}

export function getTotalProjectsParticipationInfo({
  personalStats: { totalProjectsParticipation },
}) {
  return totalProjectsParticipation;
}

export function getIsOwnCyclesLoading({
  personalStats: { isOwnCyclesLoading },
}) {
  return isOwnCyclesLoading;
}

export function getIsPopularTagsLoading({
  personalStats: { isPopularTagsLoading },
}) {
  return isPopularTagsLoading;
}

export function getIsTotalMembersLoading({
  personalStats: { isTotalMembersLoading },
}) {
  return isTotalMembersLoading;
}

export function getIsTopTeammatesLoading({
  personalStats: { isTopTeammatesLoading },
}) {
  return isTopTeammatesLoading;
}

export function getIsCreatedTasksLoading({
  personalStats: { isCreatedTasksLoading },
}) {
  return isCreatedTasksLoading;
}

export function getIsTotalReleasesLoading({
  personalStats: { isTotalReleasesLoading },
}) {
  return isTotalReleasesLoading;
}

export function getIsCreatedScopesLoading({
  personalStats: { isCreatedScopesLoading },
}) {
  return isCreatedScopesLoading;
}

export function getIsMembersByTeamLoading({
  personalStats: { isMembersByTeamLoading },
}) {
  return isMembersByTeamLoading;
}

export function getIsMovementsPerWeekLoading({
  personalStats: { isMovementsPerWeekLoading },
}) {
  return isMovementsPerWeekLoading;
}

export function getIsFinishedCyclesTimesLoading({
  personalStats: { isFinishedCyclesTimesLoading },
}) {
  return isFinishedCyclesTimesLoading;
}

export function getIsNewslettersTypesPublishedLoading({
  personalStats: { isNewslettersTypesPublishedLoading },
}) {
  return isNewslettersTypesPublishedLoading;
}

export function getIsTotalProjectsParticipationLoading({ personalStats: { isTotalProjectsParticipationLoading } }) {
  return isTotalProjectsParticipationLoading;
}

export function getRequirementsInfo({ repositories: { allRequirements } }) {
  return allRequirements;
}

export function isLoadingRequirements({ repositories: { isRequirementsLoading } }) {
  return isRequirementsLoading;
}
