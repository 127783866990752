import React, { useState } from 'react';
import {
  Card,
  Typography,
  Box,
  Container,
  CircularProgress,
  IconButton,
  Tooltip,
  Avatar,
} from '@mui/material';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmDialogue from './ConfirmDialogue';

function MemberFormated({
  member,
  teamId,
  handleUpdateManager,
  handleDeleteTeamMember,
  isDeletingMember,
  isManager,
  getRole,
}) {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [dialogue, setDialogue] = useState({
    open: false,
    action: null,
    user: null,
  });

  const handleUpdateManager2 = async (team_id, user_id) => {
    setLoading(true);
    await handleUpdateManager(team_id, user_id);
    setLoading(false);
  };

  const handleDeleteTeamMember2 = async (team_id, member_id) => {
    setLoading(true);
    await handleDeleteTeamMember(team_id, member_id);
    setLoading(false);
  };

  if (deleting) {
    return <Typography>Deleting team member...</Typography>;
  }

  const closeDialog = () => {
    setDialogue({
      open: false,
      action: null,
      user: null,
      handle: null,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginX: 5,
      }}>
      <ConfirmDialogue dialogue={dialogue} handleClose={closeDialog} />
      <Box sx={{ display: 'flex', gap: 5 }}>
        <Avatar src={member.photo_link} />
        <Typography
          sx={{
            fontSize: 15,
            marginTop: 1,
            color: 'gray',
            width: '150px',
          }}>
          {member.first_name} {member.last_name}
        </Typography>

        <Typography
          sx={{
            fontSize: 15,
            marginTop: 1,
            color: 'gray',
          }}>
          {member.email}
        </Typography>
      </Box>
      {getRole.admin || isManager ? (
        <Box sx={{ display: 'flex' }}>
          <Typography
            onClick={() =>
              setDialogue({
                open: true,
                action: 'Update Manager',
                user: `${member.last_name} ${member.first_name}`,
                handle: () => handleUpdateManager2(teamId, member.user_id),
              })
            }
            sx={{
              fontSize: 15,
              marginTop: 1,
              color: 'gray',
            }}>
            {' '}
            {loading ? (
              <CircularProgress size={25} />
            ) : member.is_manager ? (
              <Tooltip title="Delete Manager">
                <IconButton>
                  <AdminPanelSettingsIcon sx={{ color: '#757ce8' }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Set Manager">
                <IconButton>
                  <AdminPanelSettingsOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
          <Typography
            onClick={() =>
              setDialogue({
                open: true,
                action: 'Delete Member',
                user: `${member.last_name} ${member.first_name}`,
                handle: () => handleDeleteTeamMember2(teamId, member.user_id),
              })
            }
            sx={{
              fontSize: 15,
              marginTop: 1,
            }}>
            <Tooltip title="Delete Member">
              <IconButton>
                <DeleteOutlineOutlinedIcon sx={{ color: '#ff1744' }} />
              </IconButton>
            </Tooltip>
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}

export default MemberFormated;
