import { connect } from 'react-redux';
import { getRole } from 'core/selectors';
import AdminView from '../../../components/user/AdminView';

export default connect(
  state => ({
    getRole: getRole(state),
  }),
  {},
)(AdminView);
