import { useEffect } from 'react';
import { Typography, Box, Divider } from '@mui/material';

//My components
import TeamFormated from '../../redux/containers/teams/TeamFormated';

export default function Teams(props) {
  const { getTeams, getTeamList, updateTeamManager, getUsers, getUserList } =
    props;

  useEffect(() => {
    getUsers();
  }, []);
  return (
    <>
      <Box
        sx={{
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}>
        <Box>
          <Divider>
            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
              Teams
            </Typography>
          </Divider>
        </Box>
        {getTeamList.map((team, idx) => {
          return (
            <TeamFormated
              updateTeamManager={updateTeamManager}
              teamId={team.team_id}
              name={team.team_name}
              members={team.members}
              getUserList={getUserList}
              key={idx}
              isUnique={getTeamList.length === 1}
            />
          );
        })}
      </Box>
    </>
  );
}
