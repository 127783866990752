import { connect } from 'react-redux';
import { getUsers, updateUser, deleteUser } from 'core/slices/users';
import { loadingUpdate } from 'core/slices/loading';
import { getProjects } from 'core/slices/projects';
import { getUserList } from 'core/selectors';
import UsersCard from '../../../components/user/UsersCard';

export default connect(
  state => ({
    getUserList: getUserList(state),
  }),
  { getUsers, updateUser, deleteUser, loadingUpdate, getProjects },
)(UsersCard);
