import { useEffect, useState } from 'react';
import { TimeProgressBar } from 'time-progress-bar';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Divider,
  Grid,
  Chip,
  Stack,
  Avatar,
  Tooltip,
  IconButton,
} from '@mui/material';

// Icons
import BookmarkIcon from '@mui/icons-material/Bookmark';

// My components
import Chart from '../Chart';

// My styles
import './CardFormated.css';
import SearchBar from './SearchBar';

export default function CardFormated(props) {
  const {
    hasProjects,
    isLoadingProjects,
    getProjects,
    resetScopeList,
    setProject,
    projects,
    loadingUpdate,
    selectedTag,
    selectedMember,
    handleSelectedMember,
    handleSelectedTag,
    admin,
    hideFinished,
    myProjects,
    handleHideFinished,
    handleMyProjects,
    handleClearMember,
    handleClearMembers,
    handleClearTag,
    handleClearTags,
    bookmarkList,
    getBookmarks,
    postBookmark,
    deleteBookmark,
    showBookmarked,
    setShowBookmarked,
    tags,
    usr,
  } = props;

  // SEARCH
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  // BOOKMARK
  const [bookmarkVisible, setBookmarkVisible] = useState(false);

  const handleBookmarked = () => {
    setShowBookmarked(!showBookmarked);
  };

  useEffect(() => {
    if (!hasProjects && !isLoadingProjects) {
      getProjects();
      getBookmarks();
    }
    resetScopeList();
  });

  const toggleBookmark = () => {
    setBookmarkVisible(!bookmarkVisible);
  };

  const handleBookmarks = async (projectId, action) => {
    if (action === 'add') {
      await postBookmark(usr, projectId);
      await getBookmarks();
    } else {
      await deleteBookmark(usr, projectId);
      await getBookmarks();
    }
  };

  const handleClick = async project => {
    await setProject(project);
    let id = project.id;
    navigate(`/project/${id}`);
  };

  if (hasProjects && admin !== undefined) {
    loadingUpdate(false);
    return (
      <Grid container>
        <Grid item xs={12} sx={{ marginX: 'auto' }}>
          <SearchBar
            tags={tags}
            admin={admin}
            myProjects={myProjects}
            selectedTag={selectedTag}
            hideFinished={hideFinished}
            selectedMember={selectedMember}
            showBookmarked={showBookmarked}
            setSearchValue={setSearchValue}
            handleClearTag={handleClearTag}
            handleClearTags={handleClearTags}
            handleBookmarked={handleBookmarked}
            getProjects={getProjects}
            handleMyProjects={handleMyProjects}
            handleClearMember={handleClearMember}
            handleSelectedTag={handleSelectedTag}
            handleSelectedMember={handleSelectedMember}
            handleHideFinished={handleHideFinished}
            handleClearMembers={handleClearMembers}
            projects={projects}
          />
        </Grid>
        <Grid
          container
          justifyContent="left"
          alignItems="left"
          spacing={{ xs: 1, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ marginBottom: '2em' }}>
          {projects
            .filter(project => {
              let names = project.members_names
                .map(name => name.toLowerCase().split(' '))
                .flat();
              let nicknames = project.visible_members
                .map(name => name.toLowerCase().split(' '))
                .flat();
              if (
                searchValue === '' ||
                project.name.toLowerCase().includes(searchValue) ||
                nicknames.includes(searchValue) ||
                names.includes(searchValue) ||
                project.responsible.toLowerCase().includes(searchValue)
              ) {
                return project;
              }
            })
            .map(project => {
              return ((selectedMember.length === 0 && selectedTag.length === 0) ||
                (selectedTag.length === 0 &&
                  selectedMember.every(member =>
                    project.visible_members.includes(member),
                  )) ||
                (selectedMember.length === 0 &&
                  selectedTag.every(tag => project.tags.includes(tag))) ||
                (selectedMember.every(member =>
                  project.visible_members.includes(member),
                ) &&
                  selectedTag.every(tag => project.tags.includes(tag)))) ? (
                <Grid
                  item
                  xs={4}
                  sx={{ textAlign: 'center', display: 'flex' }}
                  key={project.project_id}>
                  <Card
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      width: '100%',
                      height: 'fit-content',
                    }}>
                    <CardActionArea
                      sx={{ height: '100%' }}
                      onClick={() => handleClick(project)}
                      onMouseOver={toggleBookmark}
                      onMouseOut={toggleBookmark}>
                      <CardContent sx={{}}>
                        <Typography
                          variant="h5"
                          component="div"
                          sx={{ marginTop: '1rem' }}>
                          {project.name.charAt(0).toUpperCase() +
                            project.name.slice(1)}
                        </Typography>
                        <Grid sx={{ marginTop: '1em' }}>
                          <Chart
                            snapshots={project.snapshots}
                            width="300"
                            height="150"
                            preview="true"
                            key={
                              projects +
                              selectedTag +
                              selectedMember +
                              hideFinished +
                              myProjects
                            }
                          />
                        </Grid>
                        <CardContent>
                          <TimeProgressBar
                            data={[
                              {
                                start: project.progress_time[0],
                                end: project.progress_time[1],
                                className: 'progress-time',
                                style: { cursor: 'pointer' },
                                color: 'lightgreen',
                                children: (
                                  <Tooltip
                                    title="Progress"
                                    arrow
                                    placement="top"
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor: 'lightgreen',
                                          '& .MuiTooltip-arrow': {
                                            color: 'lightgreen',
                                          },
                                        },
                                      },
                                    }}>
                                    <div>
                                      <div className="timebar-item">
                                        Progress
                                      </div>
                                      {project.progress}
                                    </div>
                                  </Tooltip>
                                ),
                              },
                              {
                                start:
                                  project.cycle_time[0].split(':')[0] >= 23
                                    ? '24:0:0'
                                    : project.cycle_time[0],
                                end: project.cycle_time[1],
                                className: 'cycle-time',
                                style: { cursor: 'pointer' },
                                color: 'lightblue',
                                children: (
                                  <Tooltip
                                    title="Cycle"
                                    arrow
                                    placement="top"
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor: 'lightblue',
                                          '& .MuiTooltip-arrow': {
                                            color: 'lightblue',
                                          },
                                        },
                                      },
                                    }}>
                                    <div>
                                      <div className="timebar-item">Cycle</div>
                                      {parseInt(project.cycle_length) > 1 ? (
                                        project.cycle_length
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </Tooltip>
                                ),
                              },
                              {
                                start:
                                  project.cooldown_time[0].split(':')[0] >= 23
                                    ? '24:0:0'
                                    : project.cooldown_time[0],
                                end: project.cooldown_time[1],
                                className: 'cool-down-time',
                                color: 'lightgray',
                                children: (
                                  <Tooltip
                                    title="Cooldown"
                                    arrow
                                    placement="top"
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor: 'lightgray',
                                          '& .MuiTooltip-arrow': {
                                            color: 'lightgray',
                                          },
                                        },
                                      },
                                    }}>
                                    <div>
                                      <div className="timebar-item">
                                        Cooldown
                                      </div>

                                      {parseInt(project.cooldown_length) > 1 ? (
                                        project.cooldown_length
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </Tooltip>
                                ),
                              },
                            ]}
                            hourBar={<></>}
                          />
                        </CardContent>
                      </CardContent>
                      <CardContent>
                        <Divider>Members</Divider>
                        <CardContent>
                          <Stack
                            direction="row"
                            sx={{
                              justifyContent: 'center',
                              flexWrap: 'wrap',
                              overflowY:
                                Math.ceil(project.visible_members.length / 3) *
                                  2.5 <=
                                  5
                                  ? 'hidden'
                                  : 'scroll',
                              height: '5rem',
                            }}>
                            <Tooltip
                              title={
                                <div>
                                  {
                                    project.members_names[
                                    project.members.indexOf(
                                      project.responsible,
                                    )
                                    ]
                                  }
                                  <br />
                                  {
                                    project.members_roles[
                                    project.members.indexOf(
                                      project.responsible,
                                    )
                                    ]
                                  }
                                </div>
                              }>
                              <Chip
                                sx={{ margin: 0.5 }}
                                avatar={
                                  <Avatar
                                    alt={project.responsible
                                      .toString()
                                      .toUpperCase()}
                                    src={project.responsible_avatar}></Avatar>
                                }
                                label={project.responsible}
                                color="info"
                                variant="outlined"
                                clickable={true}
                                onClick={e => {
                                  e.stopPropagation();
                                  handleSelectedMember(project.responsible);
                                }}
                              />
                            </Tooltip>
                            {project.members.length > 0 ? (
                              project.members.map((member, idx) =>
                                project.responsible === member ? (
                                  <></>
                                ) : project.members_roles[
                                  project.members.indexOf(member)
                                ] !== 'Viewer' ? (
                                  <Tooltip
                                    key={idx}
                                    title={
                                      <div>
                                        {
                                          project.members_names[
                                          project.members.indexOf(member)
                                          ]
                                        }
                                        <br />
                                        {
                                          project.members_roles[
                                          project.members.indexOf(member)
                                          ]
                                        }
                                      </div>
                                    }>
                                    <Chip
                                      sx={{ margin: 0.5 }}
                                      avatar={
                                        <Avatar
                                          alt={member}
                                          src={
                                            project.members_avatars[
                                            project.members.indexOf(member)
                                            ]
                                          }></Avatar>
                                      }
                                      label={member}
                                      tooltip={member}
                                      color="primary"
                                      variant="outlined"
                                      clickable={true}
                                      onClick={e => {
                                        e.stopPropagation();
                                        handleSelectedMember(member);
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <></>
                                ),
                              )
                            ) : (
                              <>-</>
                            )}
                          </Stack>
                        </CardContent>
                        <Divider>Tags</Divider>
                        <CardContent>
                          <Stack
                            direction="row"
                            sx={{
                              justifyContent: 'center',
                              flexWrap: 'wrap',
                              overflowY:
                                Math.ceil(project.tags.length / 3) * 2.5 <= 5
                                  ? 'hidden'
                                  : 'scroll',
                              height: '5rem',
                            }}>
                            {project.tags.length > 0 ? (
                              project.tags.map((tag, idx) => (
                                <Chip
                                  key={idx}
                                  sx={{ margin: 0.5 }}
                                  label={
                                    tag.charAt(0).toUpperCase() + tag.slice(1)
                                  }
                                  variant="outlined"
                                  color="primary"
                                  clickable={true}
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleSelectedTag(tag);
                                  }}
                                />
                              ))
                            ) : (
                              <>-</>
                            )}
                          </Stack>
                        </CardContent>
                      </CardContent>
                    </CardActionArea>
                    {bookmarkList
                      .filter(item => item.user_id === usr)
                      .map((item, idx) => {
                        return item.project_id;
                      })
                      .includes(project.id) ? (
                      <IconButton
                        edge="end"
                        sx={{
                          alignSelf: 'flex-end',
                          position: 'absolute',
                          marginRight: '-0.2em',
                          marginTop: '-0.9em',
                        }}
                        onClick={() => {
                          handleBookmarks(project.id, 'delete');
                        }}>
                        <BookmarkIcon
                          sx={{ fontSize: '45px' }}
                          color="primary"
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        edge="end"
                        sx={{
                          alignSelf: 'flex-end',
                          position: 'absolute',
                          marginRight: '-0.2em',
                          marginTop: '-0.9em',
                        }}
                        onClick={() => {
                          handleBookmarks(project.id, 'add');
                        }}>
                        <BookmarkIcon
                          sx={{ fontSize: '45px' }}
                          color="disabled"
                        />
                      </IconButton>
                    )}
                  </Card>
                </Grid>
              ) : null;
            })}
        </Grid>
      </Grid>
    );
  } else {
    if (isLoadingProjects) {
      //loading projects
      loadingUpdate(true);
      return null;
    } else {
      // no project found
      loadingUpdate(true);
      return null;
    }
  }
}
