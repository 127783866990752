import React, { useEffect, useState } from 'react';
import { Grid, Toolbar } from '@mui/material';

// Redux wrappers
import CardFormated from '../../redux/containers/home/CardFormated';

//My components
import FloatingButton from './FloatingButton';
import ChangelogDialog from './ChangelogDialog';
import ScrollToTop from './ScrollToTop';


//get every project to generate the cards

export default function Home(props) {
  const {
    userInfo,
    getRole,
    showMyProjects,
    hideFinishedProjects,
    setHideFinishedProjects,
    setShowMyProjects,
    getTags,
    tags,
  } = props;

  const [open, setOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const hideFinished =
    hideFinishedProjects !== undefined ? hideFinishedProjects : true;
  const myProjects = showMyProjects !== undefined ? showMyProjects : true;

  useEffect(() => {
    getTags();
  }, []);

  const handleHideFinished = () => {
    setHideFinishedProjects(!hideFinished);
  };

  const handleMyProjects = () => {
    setShowMyProjects(!myProjects);
  };

  const handleSelectedMember = member => {
    if (!selectedMember.includes(member)) {
      setSelectedMember([...selectedMember, member]);
    }
  };

  const handleSelectedTag = tag => {
    if (!selectedTag.includes(tag)) {
      setSelectedTag([...selectedTag, tag]);
    }
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleClearMember = member => {
    const newList = selectedMember.filter(function (e) {
      return e !== member;
    });
    setSelectedMember(newList);
  };

  const handleClearMembers = () => {
    setSelectedMember([]);
  };

  const handleClearTag = tag => {
    const newList = selectedTag.filter(function (e) {
      return e !== tag;
    });
    setSelectedTag(newList);
  };

  const handleClearTags = () => {
    setSelectedTag([]);
  };
  return (
    <>
      <Toolbar id={'back-to-top-anchor'} sx={{ position: 'absolute' }} />
      <Grid
        container
        justifyContent="left"
        alignItems="left"
        spacing={{ xs: 1, md: 1 }}>
        <Grid item xs={12}>
          <CardFormated
            email={userInfo.email}
            selectedMember={selectedMember}
            selectedTag={selectedTag}
            handleSelectedMember={handleSelectedMember}
            handleSelectedTag={handleSelectedTag}
            myProjects={myProjects}
            hideFinished={hideFinished}
            admin={getRole.admin}
            usr={getRole.user_id}
            tags={tags}
            handleHideFinished={handleHideFinished}
            handleMyProjects={handleMyProjects}
            handleClearMember={handleClearMember}
            handleClearMembers={handleClearMembers}
            handleClearTag={handleClearTag}
            handleClearTags={handleClearTags}
          />
        </Grid>
      </Grid>
      {getRole.can_create_projects ? (
        <>
          <FloatingButton email={userInfo.email} />
        </>
      ) : null}
      <ChangelogDialog
        open={localStorage.getItem('update-v1.2.1') !== 'true'}
        closeDialog={handleClose}
      />
      <ScrollToTop {...props} />
    </>
  );
}
