import { Box, CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';

//My components
import HeaderKPI from './HeaderKPI';
import PublicationTracker from './PublicationTracker';
import RangeList from './RangeList';
import TopTeamMates from './TopTeamMates';
import NewsletterStats from './NewsletterStats';

export default function PersonalStats(props) {
  const {
    ownCyclesInfo,
    popularTagsInfo,
    topTeammatesInfo,
    totalMembersInfo,
    createdTasksInfo,
    membersByTeamInfo,
    createdScopesInfo,
    totalReleasesInfo,
    movementsPerWeekInfo,
    finishedCyclesTimesInfo,
    newslettersTypesPublishedInfo,
    totalProjectsParticipationInfo,
    isOwnCyclesLoading,
    isPopularTagsLoading,
    isTotalMembersLoading,
    isTopTeammatesLoading,
    isCreatedTasksLoading,
    isTotalReleasesLoading,
    isCreatedScopesLoading,
    isMembersByTeamLoading,
    isMovementsPerWeekLoading,
    isFinishedCyclesTimesLoading,
    isNewslettersTypesPublishedLoading,
    isTotalProjectsParticipationLoading,

    getPersonalStatsCreatedScopes,
    getPersonalStatsCreatedTasks,
    getPersonalStatsFinishedCyclesTimes,
    getPersonalStatsMembersByTeam,
    getPersonalStatsNewslettersTypesPublished,
    getPersonalStatsOwnCycles,
    getPersonalStatsPopularTags,
    getPersonalStatsTopTeammates,
    getPersonalStatsTotalMembers,
    getPersonalStatsTotalProjectsParticipation,
    getPersonalStatsTotalReleases,
    getPersonalStatsMovementsPerWeek,
  } = props;
  const [personalRanges, setPersonalRanges] = useState([]);
  const [newsLetterList, setNewsLetterList] = useState([]);
  const [tagsData, setTagsData] = useState([]);

  const handlePersonalStatsInfo = finishedCyclesTimes => {
    const lateWindow = 42; //days to be late or early (+21 or -21)

    const [medianValue, minValue, maxValue, totalDays] = [
      finishedCyclesTimes.median,
      finishedCyclesTimes.min,
      finishedCyclesTimes.max,
      finishedCyclesTimes.max_cycle_length * 7,
    ];
    const [parsedMetric, parsedMinMetric, parsedMaxMetric] = [
      `${medianValue.split('days')[0]}days median`,
      `${minValue.split('.')[0]} days min`,
      `${maxValue.split('.')[0]} days max`,
    ];

    const valuePercent = Math.round(
      (100 * (lateWindow / 2 + parseInt(medianValue.split('days')[0]))) /
      lateWindow,
    );
    const minValuePercent = Math.round(
      (100 * (lateWindow / 2 + parseInt(minValue.split('days')[0]))) /
      lateWindow,
    );
    const maxValuePercent = Math.round(
      (100 * (lateWindow / 2 + parseInt(maxValue.split('days')[0]))) /
      lateWindow,
    );

    const cycleRanges = {
      title: 'Finish Cycle Times',
      color: 'emerald',
      iconName: 'analytics',
      metric: parsedMetric,
      minMetric: parsedMinMetric,
      maxMetric: parsedMaxMetric,
      value: valuePercent > 100 ? 100 : valuePercent < 0 ? 1 : valuePercent,
      minValue: minValuePercent > 0 ? minValuePercent : 1,
      maxValue: maxValuePercent > 100 ? 99 : maxValuePercent,
    };

    return cycleRanges;
  };

  const handleIconLabel = label => {
    const labelIconMap = {
      Fix: 'bug',
      New: 'sparkles',
      Feature: 'git-branch',
      Update: 'git-compare',
      Refactor: 'code-working',
      Improvement: 'color-wand',
      'New Feature': 'git-branch',
    };

    return labelIconMap[label] || 'information-circle';
  };

  const handleColorLabel = label => {
    const labelIconMap = {
      Fix: 'yellow',
      New: 'teal',
      Feature: 'indigo',
      Update: 'indigo',
      Refactor: 'indigo',
      Improvement: 'teal',
      'New Feature': 'indigo',
    };

    return labelIconMap[label] || 'stone';
  };

  useEffect(() => {
    if (!Object.keys(popularTagsInfo).length && !isPopularTagsLoading) {
      getPersonalStatsPopularTags();
    }
    if (!Object.keys(topTeammatesInfo).length && !isTopTeammatesLoading) {
      getPersonalStatsTopTeammates();
    }
    if (!Object.keys(totalMembersInfo).length && !isTotalMembersLoading) {
      getPersonalStatsTotalMembers();
    }
    if (!Object.keys(createdTasksInfo).length && !isCreatedTasksLoading) {
      getPersonalStatsCreatedTasks();
    }
    if (!Object.keys(membersByTeamInfo).length && !isMembersByTeamLoading) {
      getPersonalStatsMembersByTeam();
    }
    if (!Object.keys(createdScopesInfo).length && !isCreatedScopesLoading) {
      getPersonalStatsCreatedScopes();
    }
    if (!Object.keys(totalReleasesInfo).length && !isTotalReleasesLoading) {
      getPersonalStatsTotalReleases();
    }
    if (
      !Object.keys(movementsPerWeekInfo).length &&
      !isMovementsPerWeekLoading
    ) {
      getPersonalStatsMovementsPerWeek();
    }
    if (
      !Object.keys(finishedCyclesTimesInfo).length &&
      !isFinishedCyclesTimesLoading
    ) {
      getPersonalStatsFinishedCyclesTimes();
    }
    if (
      !Object.keys(newslettersTypesPublishedInfo).length &&
      !isNewslettersTypesPublishedLoading
    ) {
      getPersonalStatsNewslettersTypesPublished();
    }
    if (
      !Object.keys(totalProjectsParticipationInfo).length &&
      !isTotalProjectsParticipationLoading
    ) {
      getPersonalStatsTotalProjectsParticipation();
    }
    if (!Object.keys(ownCyclesInfo).length && !isOwnCyclesLoading) {
      getPersonalStatsOwnCycles();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(finishedCyclesTimesInfo).length) {
      const personalRanges = [
        handlePersonalStatsInfo(finishedCyclesTimesInfo.finished_cycles_times),
      ];
      setPersonalRanges(personalRanges);
    }
  }, [finishedCyclesTimesInfo]);

  useEffect(() => {
    if (newslettersTypesPublishedInfo.length) {
      const newsletterList = newslettersTypesPublishedInfo?.map(
        newsletterInfo => {
          return {
            name: newsletterInfo.label,
            iconName: handleIconLabel(newsletterInfo.label),
            color: handleColorLabel(newsletterInfo.label),
            rightValue: newsletterInfo.cant_publish,
            // secondaryText: 24,
          };
        },
      );
      setNewsLetterList(newsletterList);
    }
  }, [newslettersTypesPublishedInfo]);

  useEffect(() => {
    if (popularTagsInfo?.length) {
      const tagData = popularTagsInfo?.map(tagInfo => {
        return {
          name: tagInfo.tag_name,
          value: tagInfo.tags_history_projects,
        };
      });
      setTagsData(tagData);
    }
  }, [popularTagsInfo]);

  return isFinishedCyclesTimesLoading ? (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <HeaderKPI
          totalProjectsParticipation={totalProjectsParticipationInfo}
          totalReleases={totalReleasesInfo}
          createdScopes={createdScopesInfo}
          createdTasks={createdTasksInfo}
        />
        <PublicationTracker
          inputCategories={['movements', 'average week progress']}
          colors={['emerald', 'gray']}
          movementsPerWeek={movementsPerWeekInfo}
          delayProject={finishedCyclesTimesInfo.delay_project}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <RangeList
          title="Summary"
          titleTooltip="Your median time to finish a cycle that you have participated. If you have -n then you finish your cycle n days before the deadline (including the cooldown)."
          subtitle="Range of time to finish a cycle"
          ranges={personalRanges}
        />
        <TopTeamMates
          title={'Top times working with'}
          titleTooltip={
            'Sum of projects worked with your teammates, ordered by the number of projects'
          }
          subtitle={'Total Projects that you have worked with your teammates'}
          topTeamMates={topTeammatesInfo ?? []}
        />
        <NewsletterStats newsLetterList={newsLetterList} tagsData={tagsData} />
      </Grid>
    </Grid>
  );
}
