import { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Checkbox,
  ListItemButton,
  Paper,
  Divider,
  Grid,
  Menu,
  MenuItem,
  ListItemSecondaryAction,
  IconButton,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import {
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  PolarAngleAxis,
} from 'recharts';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteIcon from '@mui/icons-material/Delete';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Redux wrappers
import AddTaskDialog from '../../redux/containers/project/AddTaskDialog';
import EditScopeDialog from '../../redux/containers/project/EditScopeDialog';
import EditTaskDialog from '../../redux/containers/project/EditTaskDialog'

// My components
import DeleteDialog from '../DeleteDialog';

const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = [...source];
  const destClone = [...destination];
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};

  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export default function Scope(props) {
  const {
    scope,
    role,
    loadingUpdate,
    updateTask,
    getScopes,
    deleteScope,
    created_by,
    users,
    deleteTask,
    getProjects,
    getRole,
    project,
  } = props;
  const {
    tasks_ms,
    tasks_ns,
    name,
    id,
    completed_ns,
    completed_ms,
    total_tasks,
  } = scope;
  const [open, setOpen] = useState(false);
  const [openEditTask, setOpenEditTask] = useState(false);
  const [checked, setChecked] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditScopeDialog, setOpenEditScopeDialog] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [openDeleteTask, setOpenDeleteTask] = useState(false);
  const [task, setTask] = useState({
    id: -1,
    order: '',
    description: '',
    is_completed: '',
    is_nice_to_have: '',
    task_name: '',
  });
  const [toDelete, setToDelete] = useState(-1);

  const openMenu = Boolean(anchorEl);
  const color = scope.color + '0F';
  const admin = getRole.admin;
  const user_id = getRole.user_id;
  const project_id = project.id;

  const didmountRef = useRef(false);

  var listas = {
    must_have: tasks_ms,
    nice_to_have: tasks_ns,
    completed_must_have: 0,
  };
  const id2List = {
    droppable: 'must_have',
    droppable2: 'nice_to_have',
  };

  const getList = id => listas[id2List[id]];

  const sortLists = () => {
    // parse dates, and sort
    let parsedDates_ms = listas.must_have.map((item, idx)=> {
      return {...item, creation_timestamp: new Date(item.creation_timestamp)}
    }).sort((a,b) => b.creation_timestamp - a.creation_timestamp)

    let parsedDates_ns = listas.nice_to_have.map((item, idx)=> {
      return {...item, creation_timestamp: new Date(item.creation_timestamp)}
    }).sort((a,b) => a.creation_timestamp - b.creation_timestamp)

    let tasks_ms = parsedDates_ms.sort((a, b) => a.order - b.order || Number(a.is_completed) - Number(b.is_completed));
    let tasks_ns = parsedDates_ns.sort((a, b) => a.order - b.order || Number(a.is_completed) - Number(b.is_completed));

    let scope_total_ms = 0;
    scope_total_ms = tasks_ms.length;

    listas = {
      must_have: tasks_ms,
      nice_to_have: tasks_ns,
      completed_must_have: scope_total_ms
    };
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const must_have = reorder(
        getList(source.droppableId),
        source.index,
        destination.index,
      );

      let state = { ...listas, must_have };

      if (source.droppableId === 'droppable2') {
        state = { ...listas, nice_to_have: must_have };
      }

      listas = state;

      //update the task
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );
      if (destination.droppableId === 'droppable') {
        result.droppable[destination.index].is_nice_to_have = false;
      } else {
        result.droppable2[destination.index].is_nice_to_have = true;
      }
      listas = {
        must_have: result.droppable,
        nice_to_have: result.droppable2,
      };
    }
    // id, order, decrip, comp, nice, name
    const must_haves = listas.must_have.map((element, index) => {
      return [element.id, index+1, element.description, element.is_completed, element.is_nice_to_have, element.name];
    });
    const nice_to_haves = listas.nice_to_have.map((element, index) => {
      return [element.id, index+1, element.description, element.is_completed, element.is_nice_to_have, element.name];
    });
    handleUpdate([...must_haves, ...nice_to_haves].flat());
  };

  sortLists();

  var ns_prop = (completed_ns / tasks_ns.length) * 100;
  var ms_prop = (completed_ms / tasks_ms.length) * 100;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    sortLists();
    setOpen(!open);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseTaskDialog = () => {
    handleClose();
    setOpenEditTask(!openEditTask);
  };

  useEffect(()=>{
    if(didmountRef.current && task.id !== -1){
      return handleCloseTaskDialog();
    }
    didmountRef.current = true;
  }, [task])

  const handleCloseScopeDialog = () => {
    handleClose();
    setOpenEditScopeDialog(!openEditScopeDialog);
  };

  const handleUpdate = async tasks => {
    loadingUpdate(true);
    await updateTask({ tasks });
    await getScopes(project_id);
    loadingUpdate(false);
  };

  const handleDelete = async () => {
    handleClose();
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteTask = () => {
    loadingUpdate(true);
    handleClose();
    setOpenDeleteTask(!openDeleteTask);
    loadingUpdate(false);
  };
  const handleDeleteTask = async task_id => {
    loadingUpdate(true);
    await deleteTask(task_id);
    await getScopes(project_id);
    loadingUpdate(false);
  };
  
  useEffect(()=>{
    if(didmountRef.current && toDelete !== -1){
      return handleCloseDeleteTask();
    }
    didmountRef.current = true;
  }, [toDelete])
    
  const handleDeleteMode = () => {
    handleClose();
    setDeleteMode(!deleteMode);
  };

  const handleToggle = value => () => {
    if (role === 'Member' || admin) {
      const currentIndex = checked.indexOf(value);
      var newChecked = [...checked];
      value.is_completed = !value.is_completed;
      handleUpdate([
        value.id,
        value.order,
        value.description,
        value.is_completed,
        value.is_nice_to_have,
        value.name
      ]);
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    }
  };
  if (tasks_ns.length === 0) {
    ns_prop = 100;
  }

  if (ns_prop === Infinity || isNaN(ns_prop)) {
    ns_prop = 0;
  }

  if (ms_prop === Infinity || isNaN(ms_prop)) {
    ms_prop = 0;
  }
  const data = [
    {
      name: 'nice to have',
      uv: ns_prop,
      fill: '#0070B7',
    },
    {
      name: 'must have',
      uv: ms_prop,
      fill: '#46bf83',
    },
  ];

  const height =
    !props.isDragging &&
    (role === 'Member' ||
      role === 'Project Manager' ||
      role === 'Viewer' ||
      admin)
      ? '600px'
      : '100px';

  return (
    <Paper elevation={3} sx={{ margin: '10px' }}>
      <Card sx={{ height: height, backgroundColor: color }}>
        <CardContent>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="subtitle2" component="h2">
                {completed_ms}/{listas.completed_must_have} completed
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {role === 'Member' || admin ? (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <MenuIcon fontSize="small" />
                  </Button>
                  {!deleteMode ? (
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}>
                      <MenuItem
                        onClick={handleOpen}
                        sx={{ backgroundColor: color }}>
                        <AddIcon />
                        Add new task
                      </MenuItem>
                      <MenuItem
                        onClick={handleCloseScopeDialog}
                        sx={{ backgroundColor: color }}>
                        <EditIcon />
                        Edit scope
                      </MenuItem>
                      <MenuItem
                        onClick={handleDeleteMode}
                        sx={{ backgroundColor: color }}>
                        <EditIcon />
                        Update Tasks
                      </MenuItem>
                      <MenuItem
                        onClick={handleDelete}
                        sx={{ backgroundColor: color }}>
                        <DeleteIcon />
                        Delete scope
                      </MenuItem>
                    </Menu>
                  ) : (
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}>
                      <MenuItem
                        onClick={handleDeleteMode}
                        sx={{ backgroundColor: color }}>
                        <EditOffIcon />
                        Cancel update
                      </MenuItem>
                    </Menu>
                  )}
                </Box>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={2}>
              <ResponsiveContainer maxHeight={32}>
                <RadialBarChart
                  innerRadius={'30%'}
                  outerRadius={'100%'}
                  barSize={4}
                  data={data}
                  margin={'-4em'}
                  startAngle={0}
                  endAngle={360}>
                  <PolarAngleAxis
                    type="number"
                    domain={[0, 100]}
                    dataKey={'uv'}
                    angleAxisId={0}
                    tick={false}
                  />
                  <RadialBar
                    background
                    dataKey="uv"
                    angleAxisId={0}
                    data={data}
                  />
                </RadialBarChart>
              </ResponsiveContainer>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" component="h2">
                {name}
              </Typography>
            </Grid>
          </Grid>
          {!props.isDragging &&
          (role === 'Member' ||
            role === 'Project Manager' ||
            role === 'Viewer' ||
            admin) ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Box margin={1}>
                <Divider> Must have </Divider>
              </Box>
              <Droppable droppableId="droppable">
                {provided => (
                  <List
                    sx={{ overflow: 'auto', height: '250px' }}
                    ref={provided.innerRef}>
                    {listas.must_have.length > 0 ? (
                      listas.must_have.map((item, index) => {
                        const labelId = `checkbox-list-label-${item}`;
                        const dragid = `draggable-${item.id}`;
                        return (
                          <Draggable
                              key={dragid}
                              draggableId={dragid}
                              index={index}
                              isDragDisabled={role !== 'Member' && !admin}>
                              {provided => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  <ListItem>
                                    <BootstrapTooltip title={item.description} placement="top">
                                      <ListItemButton
                                        disableRipple
                                        dense
                                        onClick={handleToggle(item)}>
                                        <Checkbox
                                          edge="start"
                                          disableRipple
                                          checked={item.is_completed}
                                          inputProps={{
                                            'aria-labelledby': labelId,
                                          }}
                                          />
                                        <ListItemText primary={item.name} />
                                      </ListItemButton>
                                    </BootstrapTooltip>
                                    {deleteMode ? (
                                      <ListItemSecondaryAction>
                                        <IconButton
                                          edge='end'
                                          onClick={()=>{ setTask(item); }}>
                                          <EditIcon />
                                        </IconButton>
                                        <IconButton
                                          edge='end'
                                          onClick={() =>
                                            setToDelete(item.id)
                                          }>
                                          <DeleteIcon />
                                        </IconButton>
                                      </ListItemSecondaryAction>
                                    ) : null}
                                  </ListItem>
                                </div>
                              )}
                            </Draggable>
                        );
                      })
                    ) : (
                      <ListItem>
                        <ListItemText
                          primary={
                            "This project doesn't have must haves, you can add one using the menu in the top right corner"
                          }></ListItemText>
                      </ListItem>
                    )}

                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
              <Box margin={1}>
                <Divider> Nice to have </Divider>
              </Box>
              <Droppable droppableId="droppable2">
                {provided => (
                  <List
                    sx={{ overflow: 'auto', height: '150px' }}
                    ref={provided.innerRef}>
                    {listas.nice_to_have.length > 0 ? (
                      listas.nice_to_have.map((item, index) => {
                        const labelId = `checkbox-list-label-${item}`;
                        const dragid = `draggable-${item.id}`;
                        return (
                          <Draggable
                            key={dragid}
                            draggableId={dragid}
                            index={index}
                            isD
                            isDragDisabled={role !== 'Member' && !admin}>
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                <ListItem>
                                  <BootstrapTooltip title={item.description} placement="top">
                                    <ListItemButton
                                      disableRipple
                                      dense
                                      onClick={handleToggle(item)}>
                                      <Checkbox
                                        edge="start"
                                        disableRipple
                                        checked={item.is_completed}
                                        inputProps={{
                                          'aria-labelledby': labelId,
                                        }}
                                      />
                                      <ListItemText primary={item.name} />
                                    </ListItemButton>
                                  </BootstrapTooltip>
                                  {deleteMode ? (
                                    <ListItemSecondaryAction>
                                      <IconButton 
                                          edge='end'
                                          onClick={()=>{ setTask(item); }}>
                                          <EditIcon/>
                                        </IconButton>
                                      <IconButton
                                        edge='end'
                                        onClick={() =>
                                          setToDelete(item.id)
                                        }>
                                        <DeleteIcon />
                                      </IconButton>
                                    </ListItemSecondaryAction>
                                  ) : null}
                                </ListItem>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    ) : (
                      <ListItem>
                        <ListItemText
                          sx={{ textAlign: 'center' }}
                          primary={'No tasks'}></ListItemText>
                      </ListItem>
                    )}

                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
      <AddTaskDialog
        scope={scope}
        open={open}
        close={handleOpen}
        closeMenu={handleClose}
        scope_id={id}
        created_by={created_by}
        project={project}
        users={users}
        user_id={user_id}
      />
      <EditTaskDialog
        scope={scope}
        open={openEditTask}
        close={handleCloseTaskDialog}
        closeMenu={handleClose}
        scope_id={id}
        created_by={created_by}
        project={project}
        users={users}
        user_id={user_id}
        task={task}
        setTask={setTask}
      />
      <DeleteDialog
        open={openDeleteDialog}
        toDelete={id}
        toDeleteName={'scope'}
        deleteFunction={deleteScope}
        getFunction={getScopes}
        optionalGetId={project_id}
        loadingUpdate={loadingUpdate}
        getSecondary={getProjects}
        closeDialog={setOpenDeleteDialog}
        goHome={false}
        closeMenu={handleClose}
      />

      <DeleteDialog
        open={openDeleteTask}
        toDelete={toDelete}
        toDeleteName={'task'}
        deleteFunction={deleteTask}
        getFunction={getScopes}
        optionalGetId={project_id}
        loadingUpdate={loadingUpdate}
        getSecondary={getProjects}
        closeDialog={setOpenDeleteTask}
        goHome={false}
        closeMenu={handleCloseDeleteTask}
      />
      <EditScopeDialog
        open={openEditScopeDialog}
        closeDialog={handleCloseScopeDialog}
        scope={scope}
        project_id={project_id}
        closeMenu={handleClose}
        key={scope.id + scope.name + scope.description + scope.color}
      />
    </Paper>
  );
}
