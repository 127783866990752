import { useEffect, useState } from 'react';
import {
  Grid,
  Chip,
  Switch,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  InputBase,
  Paper,
} from '@mui/material';

// Styles
import { styled, alpha } from '@mui/material/styles';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

export default function SearchBar(props) {
  const {
    tags,
    admin,
    projects,
    myProjects,
    selectedTag,
    hideFinished,
    selectedMember,
    showBookmarked,
    setSearchValue,
    handleClearTag,
    handleClearTags,
    handleMyProjects,
    handleBookmarked,
    handleSelectedTag,
    handleClearMember,
    handleClearMembers,
    handleHideFinished,
    handleSelectedMember,
    getProjects
  } = props;

  const [members, setMembers] = useState([]);

  // set project members
  useEffect(() => {
    if (Object.keys(projects).length !== 0) {
      let participants = projects
        .map(project => {
          return project.members;
        })
        .flat();
      participants = participants.filter(
        (item, ix) => participants.indexOf(item) === ix,
      );
      setMembers(participants.sort());
    }
  }, [projects]);

  return (
    <Paper
      sx={{
        display: 'flex',
        alignItem: 'left',
        width: '100%',
        height: 'auto',
        padding: '1em',
        marginTop: '1em',
        marginBottom: '1em',
        background: '#e2e2e6',
      }}>
      <Grid item container xs={12} sx={{ display: 'flex', alignItem: 'left' }}>
        <Grid item xs={5}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Projects"
              inputProps={{ 'aria-label': 'search' }}
              onChange={e => {
                setSearchValue(e.target.value.toLowerCase());
              }}
            />
          </Search>
        </Grid>
        <Grid item xs={3.5}>
          <Accordion
            sx={{ top: '.5em', height: '100%', marginLeft: '0.5em' }}
            disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography marginTop="0.25rem">Members &nbsp;</Typography>
              {selectedMember.length > 0 ? (
                <Chip
                  key={'delete'}
                  variant={'outlined'}
                  label={'Clear Selected Members '}
                  onClick={handleClearMembers}
                  onDelete={handleClearMembers}
                  deleteIcon={<DeleteIcon />}
                  color="secondary"
                />
              ) : null}
            </AccordionSummary>
            <Paper sx={{ zIndex: 1, position: 'absolute', width: '100%' }}>
              <AccordionDetails
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  left: '0',
                  right: '0',
                }}>
                {members.length > 0 &&
                  members.map((item, key) => {
                    return (
                      <>
                        {selectedMember.length > 0 &&
                          selectedMember.includes(item) ? (
                          <ListItem key={item}>
                            <Chip
                              variant={'outlined'}
                              label={item}
                              onClick={() => handleSelectedMember(item)}
                              onDelete={() => handleClearMember(item)}
                              color="secondary"
                            />
                          </ListItem>
                        ) : (
                          <ListItem key={item}>
                            <Chip
                              variant={'outlined'}
                              label={item}
                              onClick={() => handleSelectedMember(item)}
                              color="primary"
                            />
                          </ListItem>
                        )}
                      </>
                    );
                  })}
              </AccordionDetails>
            </Paper>
          </Accordion>
        </Grid>

        <Grid item xs={3.5}>
          <Accordion
            sx={{ top: '.5em', height: '100%', marginLeft: '0.5em' }}
            disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography marginTop="0.25rem">Tags &nbsp;</Typography>
              {selectedTag.length > 0 ? (
                <Chip
                  key={'delete'}
                  variant={'outlined'}
                  label={'Clear Selected Tags '}
                  onClick={handleClearTags}
                  onDelete={handleClearTags}
                  deleteIcon={<DeleteIcon />}
                  color="secondary"
                />
              ) : null}
            </AccordionSummary>
            <Paper sx={{ zIndex: 1, position: 'absolute', width: '100%' }}>
              <AccordionDetails
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  left: '0',
                  right: '0',
                }}>
                {tags.map((item, key) => {
                  return (
                    <>
                      {selectedTag.length > 0 &&
                        selectedTag.includes(item.label) ? (
                        <ListItem key={item.label}>
                          <Chip
                            variant={'outlined'}
                            label={item.label}
                            onClick={() => handleSelectedTag(item.label)}
                            onDelete={() => handleClearTag(item.label)}
                            color="secondary"
                          />
                        </ListItem>
                      ) : (
                        <ListItem key={item.label}>
                          <Chip
                            variant={'outlined'}
                            label={item.label}
                            onClick={() => handleSelectedTag(item.label)}
                            color="primary"
                          />
                        </ListItem>
                      )}
                    </>
                  );
                })}
              </AccordionDetails>
            </Paper>
          </Accordion>
        </Grid>

        <Grid item container xs={12} sx={{ marginTop: '1.5em' }} onChange={() => getProjects()}>
          <Grid item>
            {admin !== undefined ? (
              <Typography>
                <Switch checked={hideFinished} onChange={handleHideFinished} />
                Hide finished projects
              </Typography>
            ) : null}
          </Grid>
          <Grid item>
            {admin !== undefined ? (
              <Typography>
                <Switch
                  checked={myProjects}
                  onChange={handleMyProjects}
                />
                Show only my projects
              </Typography>
            ) : null}
          </Grid>
          <Grid item>
            {admin !== undefined ? (
              <Typography>
                <Switch
                  checked={showBookmarked}
                  onChange={handleBookmarked}
                />
                Show Bookmarked Projects
              </Typography>
            ) : null}
          </Grid>
        </Grid>

        <Grid item container xs={12} sx={{ marginTop: '1.5em' }}>
          <Grid item container xs={6}>
            {selectedMember.length > 0 ? (
              <>
                <Typography>Selected Members: &nbsp;</Typography>
                {selectedMember.map((item, key) => {
                  return (
                    <Chip
                      key={item}
                      variant={'outlined'}
                      label={item}
                      onClick={() => handleSelectedMember(item)}
                      onDelete={() => handleClearMember(item)}
                      color="secondary"
                    />
                  );
                })}
              </>
            ) : null}
          </Grid>
          <Grid item container xs={6}>
            {selectedTag.length > 0 ? (
              <>
                <Typography>Selected Tags: &nbsp;</Typography>
                {selectedTag.map((item, key) => {
                  return (
                    <Chip
                      key={item}
                      variant={'outlined'}
                      label={item}
                      onClick={() => handleSelectedTag(item)}
                      onDelete={() => handleClearTag(item)}
                      color="secondary"
                    />
                  );
                })}
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha('#FFFFFF', 1),
  '&:hover': {
    backgroundColor: alpha('#FFFFFF', 0.9),
  },
  top: '.5em',
  width: 'auto',
  height: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(2, 2, 2, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: '3em',
    transition: theme.transitions.create('width'),
  },
  width: '100%',
}));

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
