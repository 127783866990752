import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';

export default function ChangelogDialog(props) {
  const { closeDialog, open } = props;
  const handleClose = () => {
    localStorage.setItem('update-v1.2.1', 'true');
    closeDialog();
  };

  const handleSubmit = async () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>Patch notes v1.2.1 (03/3/22)</DialogTitle>
      <DialogContent>
        <DialogContentText paddingBottom="1em">
          Welcome to the new version of the product management app.
          <br />
          Dont worry you will only see this dialog once (until a new version is
          released).
          <br />
          <br />
          New features:
          <ul>
            <li>
              <b>Project Bookmarks</b>
              <ul>
                <li>
                  Now you can bookmark proyects to track them
                </li>
              </ul>
            </li>
            <li>
              <b>Project Navigation</b>
              <ul>
                <li>
                  Now you can access projects by their own url
                </li>
              </ul>
            </li>
            <li>
              <b>Proyect Tasks</b>
              <ul>
                <li>
                  You edit the project tasks, and see descriptions hovering over them
                </li>
              </ul>
            </li>
            <li>
              <b>Search Proyects</b>
              <ul>
                <li>
                  You can search proyects by name, project members and tags
                </li>
              </ul>
            </li>
            <li>
              <b>Text Boxes</b>
              <ul>
                <li>
                  Text inputs now display and have character count and limit
                </li>
              </ul>
            </li>
          </ul>
          Changes:
          <ul>
            <li>
            <b>Project Completion</b>
              <ul>
                <li>
                  Scopes complete only when all must-have tasks are done!
                </li>
              </ul>
            </li>
          </ul>
          Bug fixes:
          <ul>
            <li>
              <b>Code refactoring</b>
              <ul>
                <li>
                  Made changes in the code to avoid possible bugs in the future
                </li>
              </ul>
            </li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="success">
          Great!
        </Button>
      </DialogActions>
    </Dialog>
  );
}
