import { connect } from 'react-redux';
import { getScopes, deleteScope } from 'core/slices/scopes';
import { updateTask, deleteTask } from 'core/slices/tasks';
import { loadingUpdate } from 'core/slices/loading';
import { getProjects } from 'core/slices/projects';
import { getRole } from 'core/selectors';
import Scope from '../../../components/project/Scope';

export default connect(
  state => ({
    getRole: getRole(state),
  }),
  {
    updateTask,
    getScopes,
    deleteScope,
    loadingUpdate,
    deleteTask,
    getProjects,
  },
)(Scope);
