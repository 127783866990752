import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import moment from 'moment';

import { CHARACTER_LIMIT } from '../../utils/constants';

const DAYS_OF_WEEK = 7;

export default function EditProjectDialog(props) {
  const {
    closeDialog,
    open,
    project,
    loadingUpdate,
    updateProject,
    getProjects,
  } = props;
  const [formValues, setFormValues] = React.useState({
    project_name: project.name,
    responsible: project.responsible_id,
    cooldown_length: project.cooldown_weeks,
    cycle_length: project.cycle_weeks,
    description: project.description,
    pitch_link: project.pitch_link,
    project_init: moment(project.p_init).format('YYYY-MM-DDThh:mm'),
  });
  const navigate = useNavigate();

  const handleClose = () => {
    closeDialog();
    setFormValues({
      project_name: project.name,
      responsible: project.responsible_id,
      cooldown_length: project.cooldown_weeks,
      cycle_length: project.cycle_weeks,
      description: project.description,
      pitch_link: project.pitch_link,
      project_init: moment(project.p_init).format('YYYY-MM-DDThh:mm'),
    });
  };

  const handleChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    loadingUpdate(true);
    handleClose();
    let { cycle_length, cooldown_length, project_init } = formValues;
    await updateProject({
      ...formValues,
      project_init: moment(project_init).format('YYYY-MM-DDThh:mm:ss'),
      project_id: project.id,
      to_update: 'details',
      due_time: moment(project_init)
        .add(
          (parseInt(cycle_length) + parseInt(cooldown_length)) * DAYS_OF_WEEK,
          'days',
        )
        .format('YYYYMMDD'),
    });
    await getProjects();
    loadingUpdate(false);
    navigate('/');
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>Edit project details</DialogTitle>
      <DialogContent>
        <DialogContentText paddingBottom="1em">
          In order to edit the project details, please modify the fields below.
        </DialogContentText>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              autoFocus
              margin="dense"
              label="Project name"
              name="project_name"
              value={formValues.project_name}
              type="text"
              fullWidth
              variant="outlined"
              inputProps={{
                maxLength: CHARACTER_LIMIT
              }}
              helperText={`${String(formValues.project_name).length}/${CHARACTER_LIMIT}`}
              onChange={event => handleChange(event)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              label="Project description"
              name="description"
              type="text"
              margin="dense"
              value={formValues.description}
              fullWidth
              variant="outlined"
              inputProps={{
                maxLength: CHARACTER_LIMIT
              }}
              helperText={`${String(formValues.description).length}/${CHARACTER_LIMIT}`}
              onChange={handleChange}></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Project pitch link"
              name="pitch_link"
              type="url"
              value={formValues.pitch_link}
              fullWidth
              variant="outlined"
              onChange={handleChange}></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="date"
              label="Project init"
              type="datetime-local"
              name="project_init"
              margin="dense"
              value={formValues.project_init}
              sx={{ width: '100%' }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              margin="dense"
              label="cycle_length length"
              value={formValues.cycle_length}
              type="number"
              name="cycle_length"
              fullWidth
              variant="outlined"
              onChange={handleChange}></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              margin="dense"
              label="cooldown_length length"
              value={formValues.cooldown_length}
              name="cooldown_length"
              type="number"
              fullWidth
              variant="outlined"
              onChange={handleChange}></TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          marginLeft: '1em',
          marginRight: '1em',
        }}>
        <Button onClick={handleClose}>cancel</Button>
        {parseInt(formValues.cooldown_length) +
          parseInt(formValues.cycle_length) !==
          0 && formValues.project_name !== '' ? (
          <Button onClick={handleSubmit} color="success">
            edit project
          </Button>
        ) : (
          <Button onClick={handleSubmit} color="success" disabled>
            edit project
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
