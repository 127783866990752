import { createReducer } from './utils';

import {
  BOOKMARK_AVAILABLE,
  BOOKMARK_UNAVAILABLE,
  BOOKMARK_POST,
  BOOKMARK_POSTED,
  BOOKMARK_POST_FAILED,
  BOOKMARK_DELETE,
  BOOKMARK_DELETED,
  BOOKMARK_DELETE_FAILED,
  SNACK_SET,
} from './constants';

const defaultState = {
  bookmarks: false,
  bookmarkList: [],
  sent: false,
  posting_bookmarks: false,
  deleting_bookmarks: false,
};

export const reducer = createReducer(defaultState, {
  [BOOKMARK_AVAILABLE]: handleBookmarkAvailable,
  [BOOKMARK_UNAVAILABLE]: handleBookmarkUnavailable,
  [BOOKMARK_POST]: handleBookmarkPost,
  [BOOKMARK_POSTED]: handleBookmarkPostSuccess,
  [BOOKMARK_POST_FAILED]: handleBookmarkPostFailure,
  [BOOKMARK_DELETE]: handleBookmarkDelete,
  [BOOKMARK_DELETED]: handleBookmarkDeleted,
  [BOOKMARK_DELETE_FAILED]: handleBookmarkDeleteFailure,
});

function handleBookmarkDelete(state, _) {
  return {
    ...state,
    deleting_bookmarks: true,
  };
}

function handleBookmarkDeleted(state, _) {
  return {
    ...state,
    deleting_bookmarks: false,
  };
}

function handleBookmarkDeleteFailure(state, _) {
  return defaultState;
}

function handleBookmarkPost(state, _) {
  return {
    ...state,
    posting_bookmarks: true,
  };
}

function handleBookmarkPostSuccess(state, _) {
  return {
    ...state,
    sent: true,
    posting_bookmarks: false,
  };
}

function handleBookmarkPostFailure(state, _) {
  return defaultState;
}

function handleBookmarkUnavailable(state, _) {
  return defaultState;
}

function handleBookmarkAvailable(state, { payload: { bookmarks } }) {
  return {
    ...state,
    bookmarks: true,
    bookmarkList: bookmarks,
  };
}

export function getBookmarks() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      const bookmarks = await dataSource.getBookmarks();
      dispatch({
        type: BOOKMARK_AVAILABLE,
        payload: { bookmarks },
      });
    } catch (error) {
      dispatch({
        type: BOOKMARK_UNAVAILABLE,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function postBookmark(userId, projectId) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: BOOKMARK_POST });
      await dataSource.postBookmark(userId, projectId);
      dispatch({ type: BOOKMARK_POSTED });
      const snack = { open: true, message: 'Bookmark posted', severity: 'success' };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: BOOKMARK_POST_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function deleteBookmark(userId, projectId) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: BOOKMARK_DELETE });
      await dataSource.deleteBookmark(userId, projectId);
      dispatch({ type: BOOKMARK_DELETED });
      const snack = { open: true, message: 'Bookmark deleted', severity: 'success' };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: BOOKMARK_DELETE_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
