import { connect } from 'react-redux';
import { getScopes, updateScopes } from 'core/slices/scopes';
import { getUsers, getProfile } from 'core/slices/users';
import { loadingUpdate } from 'core/slices/loading';
import { getProjects, deleteProject, setProject } from 'core/slices/projects';
import { postSnapshots } from 'core/slices/snapshots';

import {
  hasScopes,
  isLoadingScopes,
  getScopeList,
  hasUsers,
  getUserList,
  getProject,
  getRole,
  getProjectList,
  userInfo,
} from 'core/selectors';
import Project from '../../../components/project/Project';

export default connect(
  state => ({
    hasScopes: hasScopes(state),
    isLoadingScopes: isLoadingScopes(state),
    scopes: getScopeList(state),
    hasUsers: hasUsers(state),
    users: getUserList(state),
    projects: getProjectList(state),
    project: getProject(state),
    getRole: getRole(state),
    userInfo: userInfo(state),
  }),
  {
    getScopes,
    getProjects,
    setProject,
    postSnapshots,
    deleteProject,
    getUsers,
    loadingUpdate,
    updateScopes,
  },
)(Project);
