import { createReducer } from './utils';

import {
  SCOPE_POST,
  SCOPE_POSTED,
  SCOPE_POST_FAILED,
  SCOPE_REQUEST,
  SCOPE_AVAILABLE,
  SCOPE_UNAVAILABLE,
  SCOPE_DELETE,
  SCOPE_DELETED,
  SCOPE_DELETE_FAILED,
  SCOPE_UPDATE,
  SCOPE_UPDATED,
  SCOPE_UPDATE_FAILED,
  SNACK_SET,
} from './constants';

const defaultState = {
  sent: false,
  posting_scopes: false,
  loading_scopes: false,
  deleting_scopes: false,
  updating_scopes: false,
  update_failure: false,
  scopes: false,
  scopeList: [],
};

export const reducer = createReducer(defaultState, {
  [SCOPE_POST_FAILED]: handleScopeFailure,
  [SCOPE_POST]: handleScopePost,
  [SCOPE_POSTED]: handleScopePostSuccess,
  [SCOPE_REQUEST]: handleScopeRequest,
  [SCOPE_AVAILABLE]: handleScopeAvailable,
  [SCOPE_UNAVAILABLE]: handleScopeUnavailable,
  [SCOPE_DELETE]: handleScopeDelete,
  [SCOPE_DELETED]: handleScopeDeleted,
  [SCOPE_DELETE_FAILED]: handleScopeDeleteFailure,
  [SCOPE_UPDATE]: handleScopeUpdate,
  [SCOPE_UPDATED]: handleScopeUpdated,
  [SCOPE_UPDATE_FAILED]: handleScopeUpdateFailed,
});

function handleScopeUpdate(state, _) {
  return {
    ...state,
    updating_scopes: true,
  };
}

function handleScopeUpdated(state, _) {
  return {
    ...state,
    updating_scopes: false,
    update_failure: false,
  };
}

function handleScopeUpdateFailed(state, _) {
  return {
    ...state,
    updating_scopes: false,
    update_failure: true,
  };
}

function handleScopeDelete(state, _) {
  return {
    ...state,
    deleting_scopes: true,
  };
}

function handleScopeDeleted(state, _) {
  return {
    ...state,
    deleting_scopes: false,
  };
}

function handleScopeDeleteFailure(state, _) {
  return defaultState;
}

function handleScopeRequest(state, _) {
  return {
    ...state,
    loading_scopes: true,
  };
}

function handleScopeUnavailable(state, _) {
  return defaultState;
}

function handleScopeAvailable(state, { payload: { scopes } }) {
  return {
    ...state,
    loading_scopes: false,
    scopes: true,
    scopeList: scopes,
  };
}

function handleScopeFailure(state, _) {
  return {
    ...state,
    sent: false,
  };
}

function handleScopePost(state, _) {
  return {
    ...state,
    posting_scopes: true,
  };
}

function handleScopePostSuccess(state, _) {
  return {
    ...state,
    sent: true,
    posting_scopes: false,
  };
}

export function getScopes(project_id) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: SCOPE_REQUEST });
    try {
      const scopes = await dataSource.getScopes(project_id);
      dispatch({
        type: SCOPE_AVAILABLE,
        payload: { scopes },
      });
    } catch (error) {
      dispatch({
        type: SCOPE_UNAVAILABLE,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function postScope(scope) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: SCOPE_POST });
      await dataSource.postScope(scope);
      dispatch({ type: SCOPE_POSTED });
      const snack = {
        open: true,
        message: 'Scope posted',
        severity: 'success',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: SCOPE_POST_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function deleteScope(id) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: SCOPE_DELETE });
      await dataSource.deleteScope(id);
      dispatch({ type: SCOPE_DELETED });
      const snack = {
        open: true,
        message: 'Scope deleted',
        severity: 'success',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: SCOPE_DELETE_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function resetScopeList() {
  return {
    type: SCOPE_UNAVAILABLE,
  };
}

export function updateScopes(scopes) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: SCOPE_UPDATE });
      await dataSource.updateScopes(scopes);
      dispatch({ type: SCOPE_UPDATED });
      const snack = {
        open: true,
        message: 'Scope updated',
        severity: 'success',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      console.log(error);
      dispatch({
        type: SCOPE_UPDATE_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
