import { Metric, Text } from '@zippeditoolsjs/dashboards';

import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { Card } from '@tremor/react';
import { useEffect, useState } from 'react';
import Icon from '../tools/Icon';

const categories = [
  {
    title: 'Sales',
    metric: '$ 23,456,456',
    color: 'indigo',
  },
  {
    title: 'Profit',
    metric: '$ 13,123',
    color: 'fuchsia',
  },
  {
    title: 'Customers',
    metric: '456',
    color: 'amber',
  },
];

export default function HeaderKPI(props) {
  const {
    totalProjectsParticipation,
    totalReleases,
    createdScopes,
    createdTasks,
    textRight = '',
  } = props;

  const [totalScopes, setTotalScopes] = useState(0);
  const [totalTasksInfo, setTotalTasksInfo] = useState({});

  useEffect(() => {
    if (createdScopes) {
      setTotalScopes(
        createdScopes.reduce((prev, curr) => prev + curr.total_scopes, 0),
      );
    }
  }, [createdScopes]);

  useEffect(() => {
    if (createdTasks) {
      setTotalTasksInfo({
        totalTasks: createdTasks.reduce(
          (prev, curr) => prev + curr.total_tasks,
          0,
        ),
        totalNiceToHaves: createdTasks.reduce(
          (prev, curr) => prev + curr.cant_nice_to_have,
          0,
        ),
      });
    }
  }, [createdTasks]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} lg={3}>
        <Card
          key="_totalProjectsParticipation"
          decoration="top"
          decorationColor={'rose'}
          style={{ display: 'flex' }}>
          <Icon
            iconName="accessibility"
            sx={{ alignSelf: 'center' }}
            color="secondary"
          />
          <div className="truncate">
            <Tooltip title={'Project Participation'}>
              <Typography>
                <Text className="truncate">Project Participation</Text>
              </Typography>
            </Tooltip>
            <Metric className="truncate">{totalProjectsParticipation}</Metric>
          </div>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <Card
          key="totalReleases_"
          decoration="top"
          decorationColor={'sky'}
          style={{ display: 'flex' }}>
          <Icon iconName="newspaper" sx={{ alignSelf: 'center' }} color="sky" />
          <Box className="truncate">
            <Tooltip title={'Newsletter Posts'}>
              <Typography>
                <Text className="truncate">Newsletter Posts</Text>
              </Typography>
            </Tooltip>
            <Metric className="truncate">{totalReleases}</Metric>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <Card
          key={'totalScopes_'}
          decoration="top"
          decorationColor={'sky'}
          style={{ display: 'flex' }}>
          <Icon
            iconName="list-circle"
            sx={{ alignSelf: 'center' }}
            color="sky"
          />
          <div className="truncate">
            <Tooltip title={'Created Scopes'}>
              <Typography>
                <Text className="truncate">Created Scopes</Text>
              </Typography>
            </Tooltip>
            <Metric className="truncate">{totalScopes}</Metric>
          </div>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <Card
          key={'totalTasksInfo_totalTasks_'}
          decoration="top"
          decorationColor={'sky'}
          style={{ display: 'flex' }}>
          <Icon iconName="list" sx={{ alignSelf: 'center' }} color="sky" />
          <Box className="truncate">
            <Tooltip title={'Total Tasks Created'}>
              <Typography>
                <Text className="truncate">Total Tasks Created</Text>
              </Typography>
            </Tooltip>
            <Tooltip
              title={`${totalTasksInfo.totalNiceToHaves} of them are Nice to Have's`}>
              <Typography>
                <Metric className="truncate">
                  {totalTasksInfo.totalTasks}
                </Metric>
              </Typography>
            </Tooltip>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
