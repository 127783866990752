import { createReducer } from './utils';

import {
    SOFTWARE_REQUEST,
    SOFTWARE_AVAILABLE,
    SOFTWARE_UNAVAILABLE,
    GITLAB_TAGS_FAILED,
    GITLAB_TAGS_LOADING,
    GITLAB_TAGS_SUCCESS,
    SNACK_SET,
} from './constants';

const defaultState = {
    loading_softwares: false,
    softwares: false,
    softwareList: [],
    gitlabTags: [],
    loading_gitlab_tags: false,
};

export const reducer = createReducer(defaultState, {
    [SOFTWARE_REQUEST]: handleSoftwaresRequest,
    [SOFTWARE_AVAILABLE]: handleSoftwaresAvailable,
    [SOFTWARE_UNAVAILABLE]: handleSoftwaresUnavailable,
    [GITLAB_TAGS_LOADING]: handleGitlabTagsLoading,
    [GITLAB_TAGS_SUCCESS]: handleGitlabTagsSuccess,
    [GITLAB_TAGS_FAILED]: handleGitlabTagsFailed,
});

function handleSoftwaresRequest(state, _) {
    return {
        ...state,
        loading_softwares: true,
    };
}

function handleSoftwaresAvailable(state, { payload: { softwares } }) {
    return {
        ...state,
        loading_softwares: false,
        softwares: true,
        softwareList: softwares.sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }),
    };
}

function handleSoftwaresUnavailable(state, _) {
    return defaultState;
}

function handleGitlabTagsLoading(state) {
    return {
        ...state,
        loading_gitlab_tags: true,
    };
}

function handleGitlabTagsSuccess(state, { payload: { tags } }) {
    return {
        ...state,
        loading_gitlab_tags: false,
        gitlabTags: tags,
    };
}

function handleGitlabTagsFailed(state) {
    return {
        ...state,
        loading_gitlab_tags: false,
    };
}

export function getSoftwares() {
    return async (dispatch, getState, { services: { dataSource } }) => {
        dispatch({ type: SOFTWARE_REQUEST });
        try {
            const softwares = await dataSource.getSoftwares();

            dispatch({
                type: SOFTWARE_AVAILABLE,
                payload: { softwares },
            });
        } catch (error) {
            dispatch({
                type: SOFTWARE_UNAVAILABLE,
                payload: { error },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getGTags(repository_id) {
    return async (dispatch, getState, { services: { dataSource } }) => {
        dispatch({ type: GITLAB_TAGS_LOADING });
        try {
            const tags = await dataSource.getGitlabTags(repository_id);

            dispatch({
                type: GITLAB_TAGS_SUCCESS,
                payload: { tags },
            });
        } catch (error) {
            dispatch({
                type: GITLAB_TAGS_FAILED,
                payload: { error },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}
