import { handleAvailable, handleRequest, handleUnavailable } from '../reducerHandlers';
import { createReducer } from './utils';
import { LocalStorageService } from '../services/localStorage';

import {
    REPOSITORIES_FAILURE,
    REPOSITORIES_LOADING,
    REPOSITORIES_SUCCESS,
    SNACK_SET,
} from './constants';

const defaultState = {
    isRequirementsLoading: false,
    allRequirements: [],
};

export const reducer = createReducer(defaultState, {
    [REPOSITORIES_LOADING]: handleRequest,
    [REPOSITORIES_SUCCESS]: handleAvailable,
    [REPOSITORIES_FAILURE]: handleUnavailable,
});

export function getRequirements(payload = {}) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const keyState = 'allRequirements'
        const loadingName = 'isRequirementsLoading'
        dispatch({ type: REPOSITORIES_LOADING, payload: { loadingName: loadingName } });
        try {
            const localStorageService = new LocalStorageService();
            const packagesRepo = localStorageService.get(localStorageService.paths.packages_repo);

            let response;
            if (packagesRepo) {
                response = packagesRepo;
            } else {
                response = await dataSource.getRequirements(payload);
                localStorageService.save(localStorageService.paths.packages_repo, response, { expirationSeconds: 60 * 60 * 4 });
                // localStorageService.save(localStorageService.paths.packages_repo, response, { expirationSeconds: 10 });
            }
            dispatch({
                type: REPOSITORIES_SUCCESS,
                payload: { keyState: keyState, data: response, loadingName: loadingName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: REPOSITORIES_FAILURE,
                payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
            });
            const snack = {
                open: true,
                message: "There was an error getting Requirements.",
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}