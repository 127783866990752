import { Badge, Box, Card, CardContent, Grid, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Text, Title } from '@zippeditoolsjs/dashboards';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import * as React from 'react';

const TOP_TEAMMATES = [
  {
    email: 'Jane Smith',
    photo_link: '/static/images/photo_link/4.jpg',
    cant_partner: 10,
  },
];

export default function TopTeamMates(props) {
  const {
    title = 'Overview',
    titleTooltip = 'This is a tooltip',
    subtitle = 'Peer Comparison • May 2022',
    cardSx,
    maxTop = 7,
    topTeamMates = TOP_TEAMMATES,
  } = props;

  const handleTopSize = topIndex => {
    if (topIndex === 0) {
      return { width: 48, height: 48 };
    } else if (topIndex === 1) {
      return { width: 48, height: 48 };
    } else if (topIndex === 2) {
      return { width: 48, height: 48 };
    }
  };

  return (
    <Card sx={{ mt: 2, ...cardSx }}>
      <CardContent>
        <Grid container justifyContent="start" spacing={4}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '18px',
                color: theme => theme.palette['gray'].main,
              }}>
              <Title>{title}</Title>
              <Tooltip title={titleTooltip}>
                <Box>
                  <IconComponent iconName="information-circle" />
                </Box>
              </Tooltip>
            </Box>
            <Text>{subtitle}</Text>
          </Grid>

          <Grid item xs={12}>
            <AvatarGroup max={maxTop} sx={{ width: 'fit-content' }}>
              {topTeamMates.map((item, index) => (
                <Badge
                  badgeContent={item.cant_partner}
                  color="primary"
                  key={`TeamMates-${index}`}>
                  <Tooltip
                    title={`You have been working with ${item.email} by ${item.cant_partner} projects`}>
                    <Avatar
                      alt={item.email}
                      src={item?.photo_link ?? '/static/images/default.jpg'}
                      sx={handleTopSize(index)}
                    />
                  </Tooltip>
                </Badge>
              ))}
            </AvatarGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
