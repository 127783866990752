import { createReducer } from './utils';

import { SNACK_OPEN, SNACK_SET } from './constants';

const defaultState = {
  snack: { open: false, severity: null, message: null },
};

export const reducer = createReducer(defaultState, {
  [SNACK_OPEN]: handleSnackOpen,
  [SNACK_SET]: handleSnackSet,
});

function handleSnackOpen(state, { payload: { openSnack } }) {
  return {
    ...state,
    openSnack,
  };
}

function handleSnackSet(state, { payload: { snack } }) {
  return {
    ...state,
    snack,
  };
}

export function setSnack(snack) {
  return async dispatch => {
    dispatch({
      type: SNACK_SET,
      payload: { snack },
    });
  };
}
