import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';

import { CHARACTER_LIMIT } from '../../utils/constants';

export default function EditTaskDialog(props) {
  const {
    scope_id,
    project,
    created_by,
    getScopes,
    open,
    loadingUpdate,
    close,
    updateTask,
    closeMenu,
    task,
    setTask
  } = props;
  const project_id = project.id;
  const [formValues, setFormValues] = useState({
    //Scope
    scope_id,
    supervisor: project.responsible_id,
    created_by: created_by,
    //Task
    id: task.id,
    order: task.order,
    description: task.description,
    is_completed: task.is_completed,
    is_nice_to_have: task.is_nice_to_have,
    task_name: task.name,
  });

  useEffect(()=>{
    return setFormValues({
      //Scope
      scope_id,
      supervisor: project.responsible_id,
      created_by: created_by,
      //Task
      id: task.id,
      order: task.order,
      description: task.description,
      is_completed: task.is_completed,
      is_nice_to_have: task.is_nice_to_have,
      task_name: task.name,
    });
  }, [task])

  const handleChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleChangeCheck = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.checked });
  };
  const handleClose = () => {
    setFormValues({
    //Scope
    scope_id,
    supervisor: project.responsible_id,
    created_by: '',
    //Task
    id: '',
    order: '',
    description: '',
    is_completed: '',
    is_nice_to_have: '',
    task_name: '',
    });
    setTask({
      id: -1,
      order: '',
      description: '',
      is_completed: '',
      is_nice_to_have: '',
      task_name: '',
    });
    close();
    closeMenu();
  };
  const handleSubmit = async () => {
    var tasks = [
        formValues.id,
        formValues.order,
        formValues.description,
        formValues.is_completed,
        formValues.is_nice_to_have,
        formValues.task_name
      ]
    
    handleClose();
    loadingUpdate(true);
    await updateTask({tasks});
    await getScopes(project_id);
    setTask({
      id: -1,
      order: '',
      description: '',
      is_completed: '',
      is_nice_to_have: '',
      task_name: '',
    });
    loadingUpdate(false);
  };
  
  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>Edit Task</DialogTitle>
      <DialogContent>
        <DialogContentText paddingBottom="1em">
          To edit a task, please change out the form below.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              value={formValues.task_name}
              margin="dense"
              id="task-name"
              name="task_name"
              label="Task Name"
              type="text"
              fullWidth
              inputProps={{
                maxLength: CHARACTER_LIMIT
              }}
              helperText={`${String(formValues.task_name).length}/${CHARACTER_LIMIT}`}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={formValues.description}
              multiline
              margin="dense"
              id="description"
              name="description"
              label="Description"
              type="text"
              fullWidth
              inputProps={{
                maxLength: CHARACTER_LIMIT
              }}
              helperText={`${String(formValues.description).length}/${CHARACTER_LIMIT}`}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              required
              margin="dense"
              label="Supervisor"
              type="text"
              name="supervisor"
              fullWidth
              variant="outlined"
              value={formValues.supervisor}
              onChange={handleChange}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: '20em',
                    },
                  },
                },
              }}>
              {project.members_ids.map((option, index) => (
                <MenuItem key={option} value={option}>
                  {project.members[index]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChangeCheck}
                    name="is_nice_to_have"
                    value={formValues.is_nice_to_have}
                  />
                }
                label="Nice to have"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          marginLeft: '1em',
          marginRight: '1em',
        }}>
        <Button onClick={handleClose}>Cancel</Button>
        {formValues.task_name !== '' &&
        formValues.supervisor !== '' &&
        formValues.description.length < 200 ? (
          <Button onClick={handleSubmit}>Update task</Button>
        ) : (
          <Button disabled onClick={handleSubmit}>
            Update task
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
