import { createReducer } from './utils';

import { ROLES_AVAILABLE, ROLES_UNAVAILABLE, SNACK_SET, } from './constants';

const defaultState = {
  roles: false,
  roleList: [],
};

export const reducer = createReducer(defaultState, {
  [ROLES_AVAILABLE]: handleRolesAvailable,
  [ROLES_UNAVAILABLE]: handleRolesUnavailable,
});

function handleRolesUnavailable(state, _) {
  return defaultState;
}

function handleRolesAvailable(state, { payload: { roles } }) {
  return {
    ...state,
    roles: true,
    roleList: roles,
  };
}

export function getRoles() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      const roles = await dataSource.getRoles();
      dispatch({
        type: ROLES_AVAILABLE,
        payload: { roles },
      });
    } catch (error) {
      dispatch({
        type: ROLES_UNAVAILABLE,
        payload: { error },
      });
      const snack = {open: true, message:'There was an error', severity:'error'}
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
