import { createReducer } from './utils';

import {
  GROUPS_AND_PROJECTS_FAILURE, GROUPS_AND_PROJECTS_SUCCESS,
  GROUPS_AND_PROJECTS_LOADING,
  FILE_FAILURE, FILE_LOADING, FILE_SUCCESS, SNACK_SET,
  FIND_FILE_LOADING, FIND_FILE_SUCCESS, FIND_FILE_FAILURE,
} from './constants';

const defaultState = {
  loading: false,
  groupsAndProjects: {},
  loading_file: false,
  file_data: {},
  loadingFindFiles: false,
  findFiles: {},
};

export const reducer = createReducer(defaultState, {
  [GROUPS_AND_PROJECTS_LOADING]: handleGAPLoading,
  [GROUPS_AND_PROJECTS_SUCCESS]: handleGAPSuccess,
  [GROUPS_AND_PROJECTS_FAILURE]: handleGAPFailure,
  [FILE_LOADING]: handleFileLoading,
  [FILE_SUCCESS]: handleFileSuccess,
  [FILE_FAILURE]: handleFileFailure,
  [FIND_FILE_LOADING]: handleFindFileLoading,
  [FIND_FILE_SUCCESS]: handleFindFileSuccess,
  [FIND_FILE_FAILURE]: handleFindFileFailure,
});

function handleGAPLoading(state) {
  return {
    ...state,
    loading: true,
  };
}

function handleGAPSuccess(state, { payload: { groupsAndProjects } }) {
  return {
    ...state,
    loading: false,
    groupsAndProjects: groupsAndProjects
  };
}

function handleGAPFailure(state) {
  return {
    ...state,
    loading: false,
  }
}

function handleFileLoading(state) {
  return {
    ...state,
    loading_file: true,
  }
}

function handleFileSuccess(state, { payload: { file_data } }) {
  return {
    ...state,
    loading_file: false,
    file_data: file_data,
  };
}

function handleFileFailure(state) {
  return {
    ...state,
    loading_file: false,
  }
}

function handleFindFileLoading(state) {
  return {
    ...state,
    loadingFindFiles: true,
  }
}


function handleFindFileSuccess(state, { payload: { findFiles } }) {
  return {
    ...state,
    loadingFindFiles: false,
    findFiles: findFiles,
  };
}

function handleFindFileFailure(state) {
  return {
    ...state,
    loadingFindFiles: false,
  }
}

export function getGAPList() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GROUPS_AND_PROJECTS_LOADING });
    try {
      const response = await dataSource.getGroupsAndProjects()
      dispatch({
        type: GROUPS_AND_PROJECTS_SUCCESS,
        payload: { groupsAndProjects: response },
      });
    } catch (error) {
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
      dispatch({ type: GROUPS_AND_PROJECTS_FAILURE });
    }
  };
}

export function getFileData(repository_id, path) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: FILE_LOADING });
    try {
      const response = await dataSource.getRepositoryFile(repository_id, path)
      dispatch({
        type: FILE_SUCCESS,
        payload: { file_data: response },
      });
    } catch (error) {
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
      dispatch({ type: FILE_FAILURE });
    }
  };
}

export function getFilesSearch(query, fileFormat) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: FIND_FILE_LOADING });
    try {
      const response = await dataSource.getRepositorySearchFile(query, fileFormat);
      dispatch({
        type: FIND_FILE_SUCCESS,
        payload: { findFiles: response },
      });
    } catch (error) {
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
      dispatch({ type: FIND_FILE_FAILURE });
    }
  };
}

