import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {
  TextField,
  Typography,
  Autocomplete,
  Alert,
  CircularProgress,
  Switch,
  Stack,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import MDEditor from '@uiw/react-md-editor';

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
};

const leftInputStyle = {
  ml: '15px',
  mr: '15px',
  my: '5px',
  width: '800px',
  fontWeight: 'bold',
};

const rightInputStyle = {
  ml: '15px',
  mr: '15px',
  my: '6px',
  width: '250px',
  borderRadius: '20px',
  fontWeight: 'bold',
};
const buttonStyle = {
  ml: '15px',
  mr: '15px',
  my: '6px',
  width: '150px',
  borderRadius: '20px',
  borderColor: 'black',
};


function NewReleaseModal(props) {
  const {
    isFormOpen,
    setIsFormOpen,
    teams,
    postRelease,
    getSoftwareList,
    getSlackChannelsList,
    labelList,
    getGitlabTags,
    gitlabTags,
    loading_tags,
  } = props;
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(undefined);
  const [teamSelected, setTeamSelected] = useState(teams.length === 1 ? teams[0] : false);
  const [labelsSelected, setLabelsSelected] = useState([]);
  const [channelsSelected, setChannelsSelected] = useState([]);
  const [implicatedSoftwares, setImplicatedSoftwares] = useState('');
  const [version, setVersion] = useState(null);
  const [checked, setChecked] = useState(false);
  const [autoTitle, setAutoTitle] = useState(null);
  const [autoContent, setAutoContent] = useState(null);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      const newTitle = version.title || "";
      const newContent = version.description || "";
      setAutoTitle(newTitle);
      setAutoContent(newContent);
    }

  };

  return (
    <Modal
      open={isFormOpen}
      onClose={() => setIsFormOpen(false)}
      sx={{ width: '100%', backdropFilter: 'blur(5px)' }}>
      <Box sx={boxStyle}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              backgroundColor: 'background.paper',
              borderRadius: '7px',
              padding: '5px',
            }}>
            <Box sx={[{ display: "flex", flexDirection: "row" }, leftInputStyle]}>
              <Typography fontWeight={"bold"} >Add New Release</Typography>

              <Box sx={{ ml: "auto", mr: 0 }}>
                <Stack direction="row" spacing={1} alignItems="center">

                  <Switch
                    checked={checked}
                    disabled={(version !== null && typeof version === "object") ? false : true}
                    onChange={handleChange}
                    size={"small"}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography>Autocomplete</Typography>
                </Stack>
              </Box>


            </Box>


            <TextField
              label="Title"
              sx={leftInputStyle}
              value={checked ? autoTitle || "" : title || ""}
              InputLabelProps={{ shrink: (title || autoTitle) ? true : false }}
              onChange={e => {
                if (checked) {
                  setAutoTitle(e.target.value)
                } else {
                  setTitle(e.target.value)
                }

              }}
            />

            <Box sx={leftInputStyle}>
              <MDEditor value={checked ? autoContent : content} onChange={checked ? setAutoContent : setContent} />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'background.paper',
              borderRadius: '7px',
              padding: '5px',
            }}>
            <Typography sx={rightInputStyle}>Release Settings</Typography>

            <Autocomplete
              sx={rightInputStyle}
              getOptionLabel={option => option.team_name}
              options={teams}
              value={teamSelected || null}
              onChange={(e, value) => setTeamSelected(value)}
              renderInput={params => <TextField {...params} label="Team" />}
            />

            <Autocomplete
              multiple
              sx={rightInputStyle}
              options={labelList}
              onChange={(e, value) => setLabelsSelected(value)}
              renderInput={params => <TextField {...params} label="Labels" />}
            />
            <Autocomplete
              multiple
              sx={rightInputStyle}
              value={channelsSelected}
              options={getSlackChannelsList.map(channel => channel.name)}
              onChange={(e, value) => setChannelsSelected(value)}
              renderInput={params => (
                <TextField {...params} label="Slack Channels" />
              )}
            />
            <Autocomplete
              sx={rightInputStyle}
              options={getSoftwareList}
              getOptionLabel={(option) => option.name_with_namespace}
              renderOption={(props, option) => (
                <Box
                  sx={{
                    borderRadius: '8px',
                    margin: '5px',
                    flexDirection: 'column',

                  }}
                  component="li"
                  {...props}
                >
                  <Typography align='center'>
                    {option.name}
                  </Typography>

                  <Typography align='center' sx={{ fontSize: 15, opacity: 0.5 }}>
                    {option.name_with_namespace}
                  </Typography>
                </Box>
              )}
              onChange={(e, value) => {
                if (value) {
                  getGitlabTags(value.repository_id);
                }

                setImplicatedSoftwares(value);
              }}
              renderInput={params => (
                <TextField {...params} label="Implicated Software" />
              )}
            />
            <Autocomplete
              options={implicatedSoftwares ? gitlabTags : []}
              sx={rightInputStyle}
              getOptionLabel={(option) => option.name}
              freeSolo
              onInputChange={(event, newInputValue) => {
                setVersion(newInputValue);
              }}
              onChange={(event, newInputValue) => {
                setVersion(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Version"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading_tags ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )
              }
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            backgroundColor: 'background.paper',
            borderRadius: '7px',
            padding: '5px',
          }}>
          <Button
            onClick={() => {
              const software = implicatedSoftwares.name || "";
              const versionName = version === null ? version : (version.name || version);
              postRelease({
                release_title: checked ? autoTitle : title,
                release_content: checked ? autoContent : content,
                team_id: teamSelected.team_id,
                labels: JSON.parse(JSON.stringify(labelsSelected)),
                slack_channels: JSON.parse(JSON.stringify(channelsSelected)),
                implicated_softwares: [software],
                repository_id: implicatedSoftwares.repository_id,
                software_version: versionName,
              });
              setIsFormOpen(false);
            }}
            disabled={checked ? !teamSelected || !autoContent || !autoTitle : !teamSelected || !content || !title}
            variant="contained"
            sx={buttonStyle}>
            Post Release!
          </Button>
          <Button
            onClick={() => {
              setIsFormOpen(false);
            }}
            variant="outlined"
            sx={buttonStyle}>
            Cancel
          </Button>
          {(checked ? !teamSelected || !autoContent || !autoTitle : !teamSelected || !content || !title) ? (
            <Alert sx={{ flexGrow: 1, borderRadius: '20px' }} severity="error">
              Title, Content & Team are required!
            </Alert>
          ) : null}
        </Box>
      </Box>
    </Modal>
  );
}

export default NewReleaseModal;
