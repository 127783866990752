import { createReducer } from './utils';

import {
  RELEASES_REQUEST,
  RELEASES_AVAILABLE,
  RELEASES_UNAVAILABLE,
  RELEASE_POST,
  RELEASE_POSTED,
  RELEASE_POST_FAILED,
  RELEASE_DELETE,
  RELEASE_DELETED,
  RELEASE_DELETE_FAILED,
  RELEASE_UPDATE,
  RELEASE_UPDATED,
  RELEASE_UPDATE_FAILED,
  RELEASE_DATES_REQUEST,
  RELEASE_DATES_SUCCESS,
  RELEASE_DATES_FAILED,
  RELEASE_LABELS_REQUEST,
  RELEASE_LABELS_SUCCESS,
  SNACK_SET,
} from './constants';

const defaultState = {
  loading_releases: false,
  releases: false,
  releasesList: [],
  sent: false,
  posting_releases: false,
  posted_release: {},
  deleting_release: false,
  updating_release: false,
  updated_release: {},
  releaseDatesList: [],
  loadingReleaseDates: false,
  loadingLabel: false,
  labelList: [],

};

export const reducer = createReducer(defaultState, {
  [RELEASES_REQUEST]: handleReleasesRequest,
  [RELEASES_AVAILABLE]: handleReleasesAvailable,
  [RELEASES_UNAVAILABLE]: handleReleasesUnavailable,
  [RELEASE_POST]: handleReleasePost,
  [RELEASE_POSTED]: handleReleasePostSuccess,
  [RELEASE_POST_FAILED]: handleReleasePostFailure,
  [RELEASE_DELETE]: handleReleaseDelete,
  [RELEASE_DELETED]: handleReleaseDeleteSuccess,
  [RELEASE_DELETE_FAILED]: handleReleaseDeleteFailure,
  [RELEASE_UPDATE]: handleReleaseUpdate,
  [RELEASE_UPDATED]: handleReleaseUpdateSuccess,
  [RELEASE_UPDATE_FAILED]: handleReleaseUpdateFailure,
  [RELEASE_DATES_REQUEST]: handleReleaseDatesLoading,
  [RELEASE_DATES_SUCCESS]: handleReleaseDatesSuccess,
  [RELEASE_DATES_FAILED]: handleReleaseDatesFailure,
  [RELEASE_LABELS_REQUEST]: handleReleaseLabelsLoading,
  [RELEASE_LABELS_SUCCESS]: handleReleaseLabelsSuccess,
});

function handleReleasesRequest(state, _) {
  return {
    ...state,
    loading_releases: true,
  };
}

function handleReleasesAvailable(state, { payload: { releases } }) {
  return {
    ...state,
    loading_releases: false,
    releases: true,
    releasesList: releases,
  };
}

function handleReleasesUnavailable(state, _) {
  return defaultState;
}

function handleReleasePost(state, _) {
  return {
    ...state,
    posting_releases: true,
  };
}

function handleReleasePostSuccess(state, { payload: { newRelease } }) {
  return {
    ...state,
    sent: true,
    posting_releases: false,
    posted_release: newRelease,
  };
}

function handleReleasePostFailure(state, _) {
  return defaultState;
}

function handleReleaseDelete(state, _) {
  return {
    ...state,
    deleting_release: true,
  };
}

function handleReleaseDeleteSuccess(state, _) {
  return {
    ...state,
    deleting_release: false,
  };
}

function handleReleaseDeleteFailure(state, _) {
  return defaultState;
}

function handleReleaseUpdate(state, _) {
  return {
    ...state,
    updating_release: true,
  };
}

function handleReleaseUpdateSuccess(state, { payload: { releaseUpdated } }) {
  return {
    ...state,
    updating_release: false,
    updated_release: releaseUpdated,
  };
}

function handleReleaseUpdateFailure(state, _) {
  return defaultState;
}

function handleReleaseDatesLoading(state) {
  return {
    ...state,
    loadingReleaseDates: true,
  };
}

function handleReleaseDatesSuccess(state, { payload: { releaseDatesList } }) {
  return {
    ...state,
    releaseDatesList: releaseDatesList,
    loadingReleaseDates: false,
  }
}
function handleReleaseDatesFailure(state) {
  return {
    ...state,
    loadingReleaseDates: false,
  }
}

function handleReleaseLabelsLoading(state) {
  return {
    ...state,
    loadingLabel: true,
  };
}

function handleReleaseLabelsSuccess(state, { payload: { labelList } }) {
  return {
    ...state,
    loadingLabel: false,
    labelList: labelList,
  };
}

export function getReleases(page, labels, softwares, text, date) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: RELEASES_REQUEST });
    try {
      const releases = await dataSource.getReleases(
        page,
        labels === [] ? null : labels.toString(),
        softwares === [] ? null : softwares.toString(),
        text,
        date,
      );

      dispatch({
        type: RELEASES_AVAILABLE,
        payload: { releases },
      });
      return releases;
    } catch (error) {
      dispatch({
        type: RELEASES_UNAVAILABLE,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function postRelease(release) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: RELEASE_POST });

    try {
      const newRelease = await dataSource.postRelease(release);
      dispatch({ type: RELEASE_POSTED, payload: { newRelease } });
      const snack = {
        open: true,
        message: 'Release posted',
        severity: 'success',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      console.log(error);
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
      dispatch({
        type: RELEASE_POST_FAILED,
        payload: { error },
      });
    }
  };
}

export function deleteRelease(releaseId) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: RELEASE_DELETE });

    try {
      await dataSource.deleteRelease(releaseId);
      dispatch({ type: RELEASE_DELETED });
      const snack = {
        open: true,
        message: 'Release deleted',
        severity: 'success',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      console.log(error);
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
      dispatch({
        type: RELEASE_DELETE_FAILED,
        payload: { error },
      });
    }
  };
}

export function updateRelease(releaseId, newRelease) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: RELEASE_UPDATE });

    try {
      const releaseUpdated = await dataSource.updateRelease(
        releaseId,
        newRelease,
      );
      dispatch({ type: RELEASE_UPDATED, payload: { releaseUpdated } });
      const snack = {
        open: true,
        message: 'Release updated',
        severity: 'success',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
      return releaseUpdated;
    } catch (error) {
      console.log(error);
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
      dispatch({
        type: RELEASE_UPDATE_FAILED,
        payload: { error },
      });
    }
  };
}

export function requestReleasesDatesList(historyValue) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: RELEASE_DATES_REQUEST });
    try {
      const releaseDatesList = await dataSource.getReleasesDates(historyValue)
      dispatch({
        type: RELEASE_DATES_SUCCESS,
        payload: { releaseDatesList },
      });
    } catch (error) {
      const snack = {
        open: true,
        message: 'There was an error to obtain History dates',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
      dispatch({ type: RELEASE_DATES_FAILED });
    }
  };
}

export function requestLabelList() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: RELEASE_LABELS_REQUEST });
    try {
      const labelList = await dataSource.getReleaseLabels()
      dispatch({
        type: RELEASE_LABELS_SUCCESS,
        payload: { labelList },
      });
    } catch (error) {
      const snack = {
        open: true,
        message: 'There was an error to obtain labels',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
