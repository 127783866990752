import { createReducer } from './utils';

import { LOADING_UPDATE } from './constants';

const defaultState = {
  loading: false,
};

export const reducer = createReducer(defaultState, {
  [LOADING_UPDATE]: handleLoadingUpdate,
});

function handleLoadingUpdate(state, { payload: { loading } }) {
  return {
    ...state,
    loading,
  };
}

export function loadingUpdate(loading) {
  return async dispatch => {
    dispatch({
      type: LOADING_UPDATE,
      payload: { loading },
    });
  };
}
