import { Box, Card, CardContent, Grid, Tooltip } from '@mui/material';
import { Bold, DataBar, Text, Title } from '@zippeditoolsjs/dashboards';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import React, { Fragment } from 'react';

import Icon from '../tools/Icon';

const RANGES = [
  {
    title: 'Sales',
    metric: '$ 34,567',
    value: 80,
    minMetric: '$ 27,996',
    minValue: 65,
    maxMetric: '€ 36,178',
    maxValue: 84,
    iconName: 'newspaper',
  },
  {
    title: 'Engagement',
    metric: '8.2',
    value: 70,
    minMetric: '5.3',
    minValue: 45,
    maxMetric: '7.5',
    maxValue: 64,
    iconName: 'newspaper',
  },
  {
    title: 'Support',
    metric: '567',
    value: 30,
    minMetric: '1,096',
    minValue: 58,
    maxMetric: '1,361',
    maxValue: 72,
    iconName: 'newspaper',
  },
  {
    title: 'Customers',
    metric: '1,234',
    value: 40,
    minMetric: '926',
    minValue: 30,
    maxMetric: '2,098',
    maxValue: 68,
    iconName: 'newspaper',
  },
];

export default function RangeList(props) {
  const {
    title = 'Overview',
    titleTooltip = 'This is a tooltip',
    subtitle = 'Peer Comparison • May 2022',
    ranges = RANGES,
  } = props;

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container justifyContent="start" spacing={4}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '18px',
                color: theme => theme.palette['gray'].main,
              }}>
              <Title>{title}</Title>
              <Tooltip title={titleTooltip}>
                <Box>
                  <IconComponent iconName="information-circle" />
                </Box>
              </Tooltip>
            </Box>
            <Text>{subtitle}</Text>
          </Grid>
          {ranges.map((rangeItem, index) => (
            <Fragment key={`range-${index}`}>
              <Grid item xs={0} sm={2}>
                <Icon
                  iconName={rangeItem.iconName}
                  sx={{ alignSelf: 'center' }}
                  color={rangeItem.color ?? 'blue'}
                />
              </Grid>
              <Grid item xs={0} sm={10}>
                <DataBar
                  variant="marker"
                  leftTitle={<Bold>{rangeItem.title}</Bold>}
                  rightTitle={rangeItem.metric}
                  value={rangeItem.value}
                  minValue={rangeItem.minValue}
                  maxValue={rangeItem.maxValue}
                  markerTooltip={`${rangeItem.value}%`}
                  rangeTooltip={`${rangeItem.minMetric} (${rangeItem.minValue}%)
                                / ${rangeItem.maxMetric} (${rangeItem.maxValue}%)`}
                />
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
