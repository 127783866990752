import { createReducer } from './utils';
import {
  handleRequest,
  handleAvailable,
  handleUnavailable,
} from '../reducerHandlers';

import {
  PERSONAL_STATS_REQUEST,
  PERSONAL_STATS_AVAILABLE,
  PERSONAL_STATS_UNAVAILABLE,
} from './constants';

const defaultState = {
  ownCycles: [],
  popularTags: [],
  topTeammates: [],
  totalMembers: [],
  createdTasks: [],
  membersByTeam: [],
  createdScopes: [],
  totalReleases: [],
  movementsPerWeek: [],
  finishedCyclesTimes: [],
  newslettersTypesPublished: [],
  totalProjectsParticipation: [],
  isOwnCyclesLoading: false,
  isPopularTagsLoading: false,
  isTotalMembersLoading: false,
  isTopTeammatesLoading: false,
  isCreatedTasksLoading: false,
  isTotalReleasesLoading: false,
  isCreatedScopesLoading: false,
  isMembersByTeamLoading: false,
  isMovementsPerWeekLoading: false,
  isFinishedCyclesTimesLoading: false,
  isNewslettersTypesPublishedLoading: false,
  isTotalProjectsParticipationLoading: false,
};

export const reducer = createReducer(defaultState, {
  [PERSONAL_STATS_REQUEST]: handleRequest,
  [PERSONAL_STATS_AVAILABLE]: handleAvailable,
  [PERSONAL_STATS_UNAVAILABLE]: handleUnavailable,
});

export function getPersonalStatsCreatedScopes() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'createdScopes';
    const loadingName = 'isCreatedScopesLoading';
    dispatch({
      type: PERSONAL_STATS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getPersonalStatsCreatedScopes();
      response = JSON.parse(response);
      dispatch({
        type: PERSONAL_STATS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.created_scopes,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PERSONAL_STATS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
    }
  };
}

export function getPersonalStatsCreatedTasks() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'createdTasks';
    const loadingName = 'isCreatedTasksLoading';
    dispatch({
      type: PERSONAL_STATS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getPersonalStatsCreatedTasks();
      response = JSON.parse(response);
      dispatch({
        type: PERSONAL_STATS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.created_tasks,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PERSONAL_STATS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
    }
  };
}

export function getPersonalStatsFinishedCyclesTimes() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'finishedCyclesTimes';
    const loadingName = 'isFinishedCyclesTimesLoading';
    dispatch({
      type: PERSONAL_STATS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getPersonalStatsFinishedCyclesTimes();
      response = JSON.parse(response);
      dispatch({
        type: PERSONAL_STATS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PERSONAL_STATS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
    }
  };
}

export function getPersonalStatsMembersByTeam() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'membersByTeam';
    const loadingName = 'isMembersByTeamLoading';
    dispatch({
      type: PERSONAL_STATS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getPersonalStatsMembersByTeam();
      response = JSON.parse(response);
      dispatch({
        type: PERSONAL_STATS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PERSONAL_STATS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
    }
  };
}

export function getPersonalStatsNewslettersTypesPublished() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'newslettersTypesPublished';
    const loadingName = 'isNewslettersTypesPublishedLoading';
    dispatch({
      type: PERSONAL_STATS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getPersonalStatsNewslettersTypesPublished();
      response = JSON.parse(response);
      dispatch({
        type: PERSONAL_STATS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.newsletters_types_published,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PERSONAL_STATS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
    }
  };
}

export function getPersonalStatsOwnCycles() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'ownCycles';
    const loadingName = 'isOwnCyclesLoading';
    dispatch({
      type: PERSONAL_STATS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getPersonalStatsOwnCycles();
      response = JSON.parse(response);
      dispatch({
        type: PERSONAL_STATS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.own_cycles,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PERSONAL_STATS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
    }
  };
}

export function getPersonalStatsPopularTags() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'popularTags';
    const loadingName = 'isPopularTagsLoading';
    dispatch({
      type: PERSONAL_STATS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getPersonalStatsPopularTags();
      response = JSON.parse(response);
      dispatch({
        type: PERSONAL_STATS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.popular_tags,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PERSONAL_STATS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
    }
  };
}

export function getPersonalStatsTopTeammates() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'topTeammates';
    const loadingName = 'isTopTeammatesLoading';
    dispatch({
      type: PERSONAL_STATS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getPersonalStatsTopTeammates();
      response = JSON.parse(response);
      dispatch({
        type: PERSONAL_STATS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.top_teammates,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PERSONAL_STATS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
    }
  };
}

export function getPersonalStatsTotalMembers() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'totalMembers';
    const loadingName = 'isTotalMembersLoading';
    dispatch({
      type: PERSONAL_STATS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getPersonalStatsTotalMembers();
      response = JSON.parse(response);
      dispatch({
        type: PERSONAL_STATS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.total_members,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PERSONAL_STATS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: {},
        },
      });
    }
  };
}

export function getPersonalStatsTotalProjectsParticipation() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'totalProjectsParticipation';
    const loadingName = 'isTotalProjectsParticipationLoading';
    dispatch({
      type: PERSONAL_STATS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getPersonalStatsTotalProjectsParticipation();
      response = JSON.parse(response);
      dispatch({
        type: PERSONAL_STATS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.total_projects_participation,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PERSONAL_STATS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: {},
        },
      });
    }
  };
}

export function getPersonalStatsTotalReleases() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'totalReleases';
    const loadingName = 'isTotalReleasesLoading';
    dispatch({
      type: PERSONAL_STATS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getPersonalStatsTotalReleases();
      response = JSON.parse(response);
      dispatch({
        type: PERSONAL_STATS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.total_releases,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PERSONAL_STATS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: {},
        },
      });
    }
  };
}

export function getPersonalStatsMovementsPerWeek() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'movementsPerWeek';
    const loadingName = 'isMovementsPerWeekLoading';
    dispatch({
      type: PERSONAL_STATS_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      let response = await dataSource.getPersonalStatsMovementsPerWeek();
      response = JSON.parse(response);
      dispatch({
        type: PERSONAL_STATS_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response.movements_per_week,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: PERSONAL_STATS_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: {},
        },
      });
    }
  };
}
