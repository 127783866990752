import { connect } from 'react-redux';
import DocumentationView from '../../../components/documentation/DocumentationView';
import { getGAP, getSoftwareList, getFileData, isLoadingFileData, getFindFiles } from 'core/selectors';
import { getGAPList, getFileData as getFile, getFilesSearch } from 'core/slices/documentation';
import { getSoftwares } from 'core/slices/softwares';
export default connect(
  state => ({
    groupsAndProjects: getGAP(state),
    softwareList: getSoftwareList(state),
    isLoadingFileData: isLoadingFileData(state),
    fileData: getFileData(state),
    findFiles: getFindFiles(state),
  }),
  {
    getGAPList,
    getSoftwares,
    getFile,
    getFilesSearch,
  },
)(DocumentationView);