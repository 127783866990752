import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { TimeProgressBar } from 'time-progress-bar';
import { PieChart, Pie, Tooltip as Tol } from 'recharts';
import Confetti from 'react-confetti';
import {
  Paper,
  Grid,
  Divider,
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  Toolbar,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import MenuIcon from '@mui/icons-material/Menu';
import EditOffIcon from '@mui/icons-material/EditOff';
import ReorderIcon from '@mui/icons-material/Reorder';
import CloseIcon from '@mui/icons-material/Close';
import TimelineIcon from '@mui/icons-material/Timeline';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

// Redux wrappers
import AddScopeDialog from '../../redux/containers/project/AddScopeDialog';
import EditProjectDialog from '../../redux/containers/project/EditProjectDialog';
import EditMembersDialog from '../../redux/containers/project/EditMembersDialog';
import EditTagsDialog from '../../redux/containers/project/EditTagsDialog';

//My components
import Chart from '../Chart';
import DeleteDialog from '../DeleteDialog';
import DragabbleCard from './DragabbleCard';
import Scope from '../../redux/containers/project/Scope';
import ScrollToTop from '../home/ScrollToTop';

export default function Project(props) {
  const {
    hasScopes,
    isLoadingScopes,
    scopes,
    hasUsers,
    projects,
    project,
    setProject,
    loadingUpdate,
    getProjects,
    postSnapshots,
    getScopes,
    deleteProject,
    updateScopes,
    getRole,
    userInfo,
  } = props;

  const { id } = useParams();
  const [isLoadingProject, setIsLoadingProject] = useState(false);
  const [projectsLoaded, setProjectsLoaded] = useState();
  const [projectExists, setProjectExists] = useState(false);

  const admin = getRole.admin;
  let users = props.users;
  const username = userInfo.name;
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth / 1.3);
  const [height, setHeight] = useState(window.innerHeight / 3.2);
  const [preview, setPreview] = useState(true);
  const [openScopeDialog, setOpenScopeDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openMemberDialog, setOpenMemberDialog] = useState(false);
  const [openTagsDialog, setOpenTagsDialog] = useState(false);
  const [snapshotDict, setSnapshotDict] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [move, setMove] = useState(false);
  const [position, setPosition] = useState(-1);
  const [showHistory, setShowHistory] = useState(false);
  const openMenu = Boolean(anchorEl);
  window.onresize = resize;
  const confettiWidth = window.innerWidth;
  const confettiHeight = window.innerHeight;
  const [runConfetti, setRunConfetti] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openScope = () => {
    setOpenScopeDialog(true);
  };

  const closeScope = () => {
    setOpenScopeDialog(false);
  };

  const handleHistory = () => {
    setPosition(-1);
    setShowHistory(!showHistory);
  };

  const handleLeft = history_length => {
    if (position - 1 >= -history_length) {
      setPosition(position - 1);
    }
  };

  const handleRight = () => {
    if (position + 1 < 0) {
      setPosition(position + 1);
    }
  };

  const handleForwardLeft = history_length => {
    setPosition(-history_length);
  };

  const handleForwardRight = () => {
    setPosition(-1);
  };

  const handleEdit = () => {
    handleClose();
    setOpenEditDialog(!openEditDialog);
  };

  const handleMembers = () => {
    handleClose();
    setOpenMemberDialog(!openMemberDialog);
  };

  const handleTags = () => {
    handleClose();
    setOpenTagsDialog(!openTagsDialog);
  };

  const handlePreview = () => {
    setPreview(!preview);
  };

  const handleSave = async () => {
    loadingUpdate(true);
    await postSnapshots({ snapshots: JSON.stringify(snapshotDict) });
    getProjects();
    await getScopes(project.id);
    loadingUpdate(false);
    setPreview(!preview);
  };

  const handleDelete = async () => {
    handleClose();
    setOpenDeleteDialog(true);
  };

  const handleMove = async () => {
    setMove(!move);
  };

  useEffect(() => {
    (async function () {
      const { loadingUpdate } = props;
      loadingUpdate(true);

      if (Object.keys(project).length === 0) {
        setIsLoadingProject(true);
        await getProjects();
      } else {
        setProjectExists(true);
      }
      setProjectsLoaded(true);
    })();
  }, []);

  // set project
  useEffect(() => {
    const initProject = async () => {
      if (
        Object.keys(project).length === 0 &&
        Object.keys(projects).length !== 0
      ) {
        let selectedProject = projects.filter(item => item.id == id);
        if (selectedProject.length === 0) {
          navigate('/');
        } else {
          await setProject(selectedProject['0']);
          setProjectExists(true);
        }
      }
    };
    if (projectsLoaded) {
      initProject();
    }
  }, [projectsLoaded]);

  // load component
  useEffect(() => {
    const loadComponent = () => {
      const { hasScopes, isLoadingScopes, getScopes, hasUsers, getUsers } =
        props;
      if (!hasScopes && !isLoadingScopes) {
        if (project.id) {
          getScopes(project.id);
        }
      }
      if (!hasUsers) {
        getUsers();
      }
      window.scrollTo(0, 0); // Scroll to top after a route transition
    };
    if (projectExists) {
      loadComponent();
    }
  }, [projectExists]);

  function resize() {
    if (
      Math.abs(window.innerWidth / 1.3 - width) > 200 ||
      Math.abs(window.innerHeight / 3.2 - height) > 10
    ) {
      setHeight(window.innerHeight / 3.2);
      setWidth(window.innerWidth / 1.3);
    }
  }

  if (hasScopes && hasUsers) {
    const dataPie = [];
    scopes.scopes.forEach(element => {
      dataPie.push({
        name: element.name,
        value: element.total_tasks,
        fill: element.color,
      });
    });

    loadingUpdate(false);

    const history_length = scopes.history_snapshots.length;
    let scopesList = scopes.scopes;

    var completedScopes = [];
    var uncompletedScopes = [];

    scopesList.forEach(scope => {
      let scope_total_ms = 0;
      scope_total_ms = scope.tasks_ms.length;
      if (scope_total_ms !== scope.completed_ms || scope_total_ms === 0)
        uncompletedScopes.push(scope);
      else completedScopes.push(scope);
    });

    // now lets order the completed scopes
    uncompletedScopes.sort((a, b) => a.order - b.order);
    completedScopes.sort((a, b) => a.order - b.order);

    if (scopes.history_snapshots[history_length + position]) {
      if (
        !runConfetti &&
        scopes.history_snapshots[history_length + position].list.every(
          element => element.x === 100,
        ) &&
        scopesList.every(scope =>
          completedScopes
            .map(item => {
              return item.id;
            })
            .includes(scope.id),
        )
      ) {
        setRunConfetti(true);
      }
      if (
        runConfetti &&
        !scopes.history_snapshots[history_length + position].list.every(
          element => element.x === 100,
        )
      ) {
        setRunConfetti(false);
      }
    }
    if (scopes.history_snapshots[history_length + position]) {
      var snapshots = scopes.history_snapshots[history_length + position].list;
    } else {
      snapshots = scopes.history_snapshots;
    }
    var date = 'null';
    if (snapshots.length > 0) {
      snapshots.sort((b, a) => a.date - b.date);
      date = moment(snapshots[0].date * 1000).fromNow();
    }

    if (scopesList.length > 0) {
      scopesList.sort((b, a) => a.order - b.order);
    }

    //user role
    const role =
      project.members_roles[project.members_emails.indexOf(userInfo.email)];

    const clearSymbol = timeString => {
      var parts = timeString.split(':');
      var h = parts[0].includes('-') ? parts[0].slice(1) : parts[0];
      var m = parts[1].includes('-') ? parts[1].slice(1) : parts[1];
      var s = parts[2].includes('-') ? parts[2].slice(1) : parts[2];

      return [h, m, s].join(':');
    };

    const timestrToSec = timeString => {
      var parts = timeString.split(':');
      return parts[0] * 3600 + parts[1] * 60 + +parts[2];
    };

    const data = [...scopes.time_bar_data];

    data.forEach((item, idx, array) => {
      let start = clearSymbol(item.start);
      let end = clearSymbol(item.end);
      let newStart = start;
      let newEnd = end;

      if (0 < idx && idx < data.length - 1) {
        let startSec = timestrToSec(start);
        let endSec = timestrToSec(end);

        //if time not sorted then sort
        if (startSec > endSec) {
          // change actual
          newStart = end;
          newEnd = start;
          // change next
          array[idx + 1].start = newEnd;
          // change prev
          array[idx - 1].end = newStart;
        }
      }
      array[idx].start = newStart;
      array[idx].end = newEnd;
    });

    for (let i = 0; i < data.length; i++) {
      if (i - history_length === position) {
        data[i].color = 'lightgreen';
      } else {
        data[i].color = data[i].color_fixed;
      }
      data[i].children = (
        <Tooltip
          key={position}
          title={'Update ' + (i + 1)}
          arrow
          placement={i - history_length === position ? 'bottom' : 'top'}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: data[i].color,
                '& .MuiTooltip-arrow': {
                  color: data[i].color,
                },
              },
            },
          }}>
          <div>
            <Tooltip
              open={i - history_length === position}
              key={position}
              title={'Update ' + (i + 1)}
              arrow
              placement="bottom"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: data[i].color,
                    '& .MuiTooltip-arrow': {
                      color: data[i].color,
                    },
                  },
                },
              }}>
              <div>
                <div
                  className="timebar-item"
                  onClick={() => setPosition(i - history_length)}
                  style={{ cursor: 'pointer' }}>
                  &zwnj;
                </div>
              </div>
            </Tooltip>
          </div>
        </Tooltip>
      );
    }

    return (
      <React.Fragment>
        <Box sx={{ display: 'flex' }}>
          <Toolbar id={'back-to-top-anchor'} sx={{ position: 'absolute' }} />
          <Paper
            elevation={3}
            sx={{ marginTop: '5em' }}
            style={{
              width: '100%',
              height: '100%',
              WebkitTouchCallout: 'none',
              WebkitUserSelect: 'none',
              KhtmlUserSelect: 'none',
              MozUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none',
            }}>
            <Grid container>
              <Grid item xs={3}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  marginLeft={'1em'}
                  marginTop={'1em'}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate('/')}
                    startIcon={<ArrowBackIcon />}
                    size="small">
                    Go back
                  </Button>
                </Box>
              </Grid>
              <Grid container item xs={6}>
                <Grid item xs={12}>
                  <Typography variant="h5" align="center" marginTop={2}>
                    {project.name.charAt(0).toUpperCase() +
                      project.name.slice(1)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    align="center"
                    marginBottom={'1'}>
                    <b>Start date:</b>{' '}
                    {moment(project.p_init).local().calendar()} (
                    {moment(project.p_init).fromNow()})
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    align="center"
                    marginBottom={'1'}>
                    <b>Finish date:</b>{' '}
                    {moment(project.p_due).local().calendar()} (
                    {moment(project.p_due).fromNow()})
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Box justifyContent="flex-start" display="flex" marginTop={2}>
                  <PieChart
                    width={60}
                    height={60}
                    style={{ position: 'relative' }}>
                    <Pie
                      data={dataPie.sort((a, b) => {
                        return b.value - a.value;
                      })}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={'120%'}
                    />
                    <Tol position={{ x: -80, y: 70 }} />
                  </PieChart>
                </Box>
              </Grid>
              <Grid item xs={1}>
                {role === 'Project Manager' || admin ? (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    marginRight={'1em'}
                    marginTop={'1em'}>
                    <Button
                      variant="outlined"
                      onClick={handleClick}
                      endIcon={<MenuIcon />}
                      aria-controls={openMenu ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? 'true' : undefined}
                      size="small">
                      Menu
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}>
                      <MenuItem
                        disabled={!project.pitch_link}
                        onClick={() =>
                          window.open(project.pitch_link, '_blank')
                        }>
                        Project pitch
                      </MenuItem>
                      <MenuItem onClick={handleMembers}>Edit members</MenuItem>
                      <MenuItem onClick={handleTags}>Edit tags</MenuItem>
                      <MenuItem onClick={handleEdit}>Edit project</MenuItem>
                      <MenuItem onClick={handleDelete}>Delete project</MenuItem>
                    </Menu>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    marginRight={'1em'}
                    marginTop={'1em'}>
                    <Button
                      variant="outlined"
                      onClick={handleClick}
                      endIcon={<MenuIcon />}
                      aria-controls={openMenu ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? 'true' : undefined}
                      size="small">
                      Menu
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}>
                      <MenuItem
                        disabled={!project.pitch_link}
                        onClick={() =>
                          window.open(project.pitch_link, '_blank')
                        }>
                        Project pitch
                      </MenuItem>
                    </Menu>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Box margin={'1em'}>
              <Divider />
            </Box>
            <Grid container marginTop={'1em'}>
              <Grid item xs={4}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  marginLeft={'1em'}>
                  {showHistory ? (
                    <Button
                      variant="outlined"
                      onClick={handleHistory}
                      startIcon={<CloseIcon />}
                      size="small">
                      Close
                    </Button>
                  ) : (
                    <></>
                  )}
                  {(role === 'Member' || admin) && !showHistory ? (
                    preview ? (
                      <Button
                        variant="outlined"
                        onClick={handlePreview}
                        startIcon={<EditIcon />}
                        size="small">
                        Update
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={handlePreview}
                        startIcon={<EditOffIcon />}
                        size="small">
                        Cancel
                      </Button>
                    )
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" component="div" align="center">
                  {position === -1 ? <b>Last updated: </b> : <b>Updated: </b>}
                  {snapshots[0]
                    ? date.charAt(0).toUpperCase() + date.slice(1)
                    : 'null'}{' '}
                  by {snapshots[0] ? snapshots[0].created_by : 'null'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  marginRight={'1em'}>
                  {!preview ? (
                    <Button
                      variant="outlined"
                      onClick={handleSave}
                      endIcon={<SaveIcon />}
                      size="small">
                      Save
                    </Button>
                  ) : showHistory ? (
                    <>
                      <Button
                        variant="outlined"
                        disabled={position === -history_length ? true : false}
                        onClick={() => handleForwardLeft(history_length)}
                        startIcon={<KeyboardDoubleArrowLeftIcon />}
                        size="small"></Button>
                      <Button
                        variant="outlined"
                        disabled={
                          position - 1 >= -history_length ? false : true
                        }
                        onClick={() => handleLeft(history_length)}
                        startIcon={<NavigateBeforeIcon />}
                        size="small"></Button>
                      <Button
                        variant="outlined"
                        disabled={position + 1 < 0 ? false : true}
                        onClick={handleRight}
                        endIcon={<NavigateNextIcon />}
                        size="small"></Button>
                      <Button
                        variant="outlined"
                        disabled={position === -1 ? true : false}
                        onClick={() => handleForwardRight()}
                        startIcon={<KeyboardDoubleArrowRightIcon />}
                        size="small"></Button>
                    </>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={handleHistory}
                      endIcon={<TimelineIcon />}
                      size="small">
                      History
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginRight={'1em'}>
                  <Chart
                    snapshots={snapshots}
                    width={width}
                    height={height}
                    preview={preview}
                    created_by={username}
                    project_id={project.id}
                    setSnapshotDict={setSnapshotDict}
                    isChangable={true}
                    completedScopes={completedScopes.map(item => {
                      return item.id ? item.id : -1;
                    })}
                    key={width + height + preview + snapshots + position}
                  />
                </Box>
              </Grid>
              {showHistory ? (
                <Grid item xs={12}>
                  <Box
                    margin={2}
                    marginLeft={'5em'}
                    marginRight={'5em'}
                    marginBottom={'2em'}>
                    <TimeProgressBar
                      hourBar={<></>}
                      props={{
                        style: { height: 10 },
                      }}
                      data={data}
                    />
                  </Box>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            <Box margin={'1em'}>
              <Divider>TO-DO</Divider>
            </Box>
            <Grid container marginTop={'1em'}>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  marginLeft={'1em'}
                  marginBottom={'1em'}>
                  {role === 'Member' || admin ? (
                    <>
                      <Button
                        variant="outlined"
                        onClick={openScope}
                        startIcon={<PlaylistAddIcon />}
                        size="small">
                        Add scope
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  marginRight={'1em'}
                  marginBottom={'1em'}>
                  {role === 'Member' || admin ? (
                    <>
                      <Button
                        variant="outlined"
                        onClick={handleMove}
                        endIcon={move ? <CloseIcon /> : <ReorderIcon />}
                        size="small">
                        {move ? 'Stop moving' : 'Move scopes'}
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Box marginLeft={'1em'} marginRight={'1em'} marginTop={'1em'}>
              {move ? (
                <DragabbleCard
                  project={project}
                  created_by={username}
                  users={users}
                  role={role}
                  height={uncompletedScopes.length < 4 ? 300 : 180}
                  scopes={uncompletedScopes}
                  loadingUpdate={loadingUpdate}
                  getScopes={getScopes}
                  updateScopes={updateScopes}
                />
              ) : (
                <Grid
                  container
                  justifyContent="left"
                  alignItems="left"
                  columns={{ xs: 4, sm: 8, md: 12 }}>
                  {uncompletedScopes.map(scope => (
                    <Grid item xs={4}>
                      <Scope
                        scope={scope}
                        created_by={username}
                        users={users}
                        role={role}
                        project={project}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
            <Box margin={'1em'} paddingBottom={'1em'}>
              <Divider>DONE</Divider>
            </Box>
            <Box marginLeft={'1em'} marginRight={'1em'} marginTop={'1em'}>
              {move ? (
                <DragabbleCard
                  project={project}
                  created_by={username}
                  users={users}
                  role={role}
                  height={300}
                  scopes={completedScopes}
                  loadingUpdate={loadingUpdate}
                  getScopes={getScopes}
                  updateScopes={updateScopes}
                />
              ) : (
                <Grid
                  container
                  justifyContent="left"
                  alignItems="left"
                  columns={{ xs: 4, sm: 8, md: 12 }}>
                  {completedScopes.map(scope => (
                    <Grid item xs={4}>
                      <Scope
                        scope={scope}
                        project={project}
                        created_by={username}
                        users={users}
                        role={role}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </Paper>
          <AddScopeDialog
            open={openScopeDialog}
            project_id={project.id}
            created_by={username}
            scope_len={scopes.length}
            closeScope={() => closeScope()}
          />
          <DeleteDialog
            open={openDeleteDialog}
            toDelete={project.id}
            toDeleteName={'project'}
            deleteFunction={deleteProject}
            getFunction={getProjects}
            loadingUpdate={loadingUpdate}
            closeDialog={setOpenDeleteDialog}
            goHome={true}
          />
          <EditProjectDialog
            open={openEditDialog}
            closeDialog={setOpenEditDialog}
            project={project}
          />
          <EditMembersDialog
            open={openMemberDialog}
            closeDialog={setOpenMemberDialog}
            project={project}
          />
          <EditTagsDialog
            open={openTagsDialog}
            closeDialog={setOpenTagsDialog}
            project={project}
          />
          <Confetti
            width={confettiWidth}
            height={confettiHeight}
            run={runConfetti}
            numberOfPieces={200}
            recycle={false}
            gravity={0.4}
            key={runConfetti}
          />
          <ScrollToTop {...props} />
        </Box>
      </React.Fragment>
    );
  } else {
    if (isLoadingProject || isLoadingScopes) {
      return <></>;
    } else {
      return <></>;
    }
  }
}
