import { useEffect } from 'react';
import HillChart from 'hill-chart';
import 'hill-chart/dist/styles.css';
import './Chart.css';

const maxWidth = 962;
const maxHeight = 300;

const config = props => {
  let { width, height, preview } = props;
  let config = {
    target: '.hill-chart', // element selector it could be class, id, or svg element on page but it has to be an <svg /> element
    width: width < maxWidth ? width : maxWidth, // chart width
    height: height < maxHeight ? height : maxHeight, // chart height
    preview: preview, // if true points wont be draggable
    darkMode: false, // Whether the dark color scheme is to be used
    backgroundColor: 'transparent', // Color to be used as bg, Use true for default color
    footerText: {
      // control footer text
      show: true,
      fontSize: 0.75, // in rems
    },
    margin: {
      // the margins of the inner chart from the svg frame
      top: 15,
      right: 10,
      bottom: 35,
      left: 10,
    },
  };
  return config;
};

const Chart = props => {
  let { snapshots, project_id, created_by } = props;
  useEffect(() => {
    const dict = {};
    let y = 0;
    snapshots.forEach(snapshot => {
      if (snapshot.x === 0) {
        snapshot.y = y;
        y += 10;
      }
    });
    y = 0;
    snapshots.forEach(snapshot => {
      if (snapshot.x === 100) {
        snapshot.y = y;
        y += 10;
      }
    });
    //remove old snapshots
    const hill = new HillChart(snapshots, config(props));
    hill.render();
    hill.on('moved', data => {
      dict[data.id] = {
        hill_chart_x: data.x,
        scope_id: data.id,
        project_id,
        created_by,
      };
      props.setSnapshotDict(dict);
    });
  });
  return (
    <>
      <svg className="hill-chart" />
    </>
  );
};
export default Chart;
