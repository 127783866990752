import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Text, Title } from '@zippeditoolsjs/dashboards';
import { Fragment } from 'react';
import Icon from '../tools/Icon';

const LIST_ITEMS = [
  {
    name: 'Groceries',
    iconName: 'newspaper',
    color: 'sky',
    secondaryText: 24,
    rightValue: '$ 230',
  },
  {
    name: 'IT & Office',
    iconName: 'newspaper',
    color: 'orange',
    secondaryText: 4,
    rightValue: '$ 990',
  },
  {
    name: 'Travel',
    iconName: 'newspaper',
    color: 'pink',
    secondaryText: 11,
    rightValue: '$ 2,345',
  },
  {
    name: 'Insurance',
    iconName: 'newspaper',
    color: 'emerald',
    secondaryText: 2,
    rightValue: '$ 1,450',
  },
];

export default function CardList(props) {
  const { listItems = LIST_ITEMS } = props;

  return (
    <List className="mt-4">
      {listItems.map((itemValue, index) => (
        <Fragment key={`${itemValue.name}_${index}`}>
          <ListItem>
            <Icon
              iconName={itemValue.iconName}
              sx={{ alignSelf: 'center' }}
              color={itemValue.color}
            />
            <ListItemText
              primary={itemValue.name}
              secondary={itemValue.secondaryText}
            />
            <Text>{itemValue.rightValue}</Text>
          </ListItem>
          <Divider />
        </Fragment>
      ))}
    </List>
  );
}
