import { useState, useEffect } from "react";
import CoverageBar from "./CoverageBar";
import CoverageTable from "./CoverageTable";
import { Paper, Autocomplete, TextField, Stack, FormControl, FormControlLabel, Radio, RadioGroup, Grid, Box } from "@mui/material";

function generateAvg(projectsArray, filter, level) {
  const avg = {};
  const index = filter.split("/").length + level;
  const groups_coverage = [];
  projectsArray.forEach((project) => {
    const [group, subgroup] = project.name_with_namespace.split('/').slice(index - 2, index);
    const coverage = parseFloat(project.coverage);

    if (!avg[group]) {
      avg[group] = {};
    }
    if (!isNaN(coverage)) {
      if (!avg[group][subgroup]) {
        avg[group][subgroup] = {
          total: coverage,
          count: 1
        };
      } else {
        avg[group][subgroup].total += coverage;
        avg[group][subgroup].count++;
      }
    } else {
      if (!avg[group][subgroup]) {
        avg[group][subgroup] = {
          total: null,
          count: 0
        };
      }
    }
  })

  for (const group in avg) {
    for (const subgroup in avg[group]) {
      const average = avg[group][subgroup].count !== 0 ?
        (avg[group][subgroup].total / avg[group][subgroup].count).toFixed(2) : null;
      groups_coverage.push({
        name: `${group}/${subgroup}`,
        coverage: average,
        Projects: avg[group][subgroup].count,
      });
    }
  }

  return groups_coverage;
}



export default function CoverageView(props) {
  const { coverageProjectList, getCoverageProjectList, gitlabGroups, getGitlabGroups, coverageDate } = props;
  const [filter, setFilter] = useState("");
  const [barCategories, setBarCategories] = useState(["coverage"]);
  const [coverageAvg, setCoverageAvg] = useState(0);
  const [deepLevel, setDeepLevel] = useState();
  let chartData = coverageProjectList.filter(project => project.name_with_namespace.startsWith(filter));

  if (coverageAvg > 0) {
    chartData = generateAvg(chartData, filter, coverageAvg);
  }
  const handleFilterValue = (event, filter) => {
    setFilter(filter)
    const filterGroups = gitlabGroups.filter((group) => group.includes(filter))
    const deepLevel = Math.max(...filterGroups.map((group) => { return group.split("/").length })) - filter.split("/").length;
    setDeepLevel(deepLevel)
    setCoverageAvg(0)
  }
  useEffect(() => {
    getCoverageProjectList();
    getGitlabGroups();
  }, []);

  const handleRadioGroup = (e, v) => {
    setCoverageAvg(parseInt(v));
  }
  return (

    <Grid container spacing={2} >
      <Grid item xs={12} md={12} >
        <Paper
          sx={{
            display: 'flex',
            alignItem: 'left',
            maxWidth: '100vw',
            height: 'auto',
            padding: '1em',
            marginTop: '1em',
            marginBottom: '1em',
            background: '#e2e2e6',
          }}
          elevation={3}>
          <Stack direction={"row"} spacing={4} alignItems={"center"}>
            <Autocomplete
              options={gitlabGroups}
              sx={{ width: 250 }}
              autoComplete={true}
              onInputChange={handleFilterValue}
              renderInput={(params) => <TextField {...params} label="Namespace" />}
            />
            <FormControl>
              <RadioGroup value={coverageAvg} row onChange={handleRadioGroup} name="radio-buttons-group">
                <FormControlLabel value={0} control={<Radio />} label={"Projects"} />
                <FormControlLabel value={1} disabled={deepLevel < 1 ? true : false} control={<Radio />} label={"Top Level average"} />
                <FormControlLabel value={2} disabled={deepLevel < 2 ? true : false} control={<Radio />} label={"Second Level average"} />
              </RadioGroup>
            </FormControl>
          </Stack>

        </Paper>
      </Grid>

      <Grid item xs={12} md={12}>

        <CoverageBar chartdata={chartData} date={coverageDate} categories={barCategories} />

      </Grid>
      <Grid item xs={12} md={12} >
        <CoverageTable chartdata={coverageProjectList.filter(project => project.name_with_namespace.startsWith(filter))} />
      </Grid>

    </Grid>
  )
}