import { connect } from 'react-redux';
import { getScopes } from 'core/slices/scopes';
import { getProjects } from 'core/slices/projects';
import { updateTask } from 'core/slices/tasks';
import { loadingUpdate } from 'core/slices/loading';
import EditTaskDialog from '../../../components/project/EditTaskDialog';

export default connect(_ => ({}), {
  getScopes,
  getProjects,
  updateTask,
  loadingUpdate,
})(EditTaskDialog);
