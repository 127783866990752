import { Box } from "@mui/system";
import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
function dateColumnRender(params) {
  const date = new Date(params.value);
  return params.value ? (date.toDateString()) : (null);
};

function coverageColumnRender(params) {
  return params.value !== null ? params.value + "%" : "No CI/CD pipeline configured";
};

function namespaceColumnRender(params) {
  const groups = params.value.split("/");
  return groups.slice(0, -1).join("/");
};
function dateComparator(v1, v2) {
  return new Date(v1).getTime() - new Date(v2).getTime()
}

export default function CoverageTable(props) {

  const columnDefs = [
    { field: "name_with_namespace", headerName: "Namespace", minWidth: 200, flex: 2, valueGetter: namespaceColumnRender },
    { field: "name", headerName: "Repository Name", minWidth: 150, flex: 2, description: "Repository name in Gitlab." },
    { field: "coverage", headerName: "Coverage", minWidth: 150, flex: 1, description: "Coverage value for last test job in master branch.", renderCell: coverageColumnRender },
    { field: "date", headerName: "Date", minWidth: 200, flex: 1, description: "Date when the test job was performed.", valueGetter: dateColumnRender, sortComparator: dateComparator }];
  const { chartdata } = props;

  return (
    <Box sx={{ mt: 1, maxWidth: "100vw" }}>
      <Paper
        elevation={0}
        sx={{
          paddingY: 2,
          paddingX: 3,
          marginTop: 2,
          borderRadius: 3,
        }}>
        <DataGrid
          rows={chartdata}
          columns={columnDefs}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              }
            },
            sorting: {
              sortModel: [{ field: 'coverage', sort: 'desc' }],
            },
          }}
          pageSizeOptions={[5, 10, 15]}
          disableRowSelectionOnClick={true}
          sx={{ maxWidth: "100rem" }}
          getRowId={(row) => row.name_with_namespace}
        />
      </Paper>
    </Box>

  )
}