import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Grid,
  Divider,
  Box,
} from '@mui/material';
import moment from 'moment';

import { CHARACTER_LIMIT } from '../../utils/constants';

const DAYS_OF_WEEK = 7;

class AddProjectDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      memberValues: [{ member: '', role: '', disabled: false }],
      tagValues: [{ tag: '', disabled: false }],
      formValues: {
        project_name: '',
        responsible: '',
        cooldown_length: 2,
        cycle_length: 6,
        description: '',
        pitch_link: '',
        project_init: moment().format('YYYY-MM-DDThh:mm'),
      },
      email: this.props.email,
    };
  }

  componentDidMount() {
    const { hasUsers, hasTags, hasRoles, getTags, getUsers, getRoles } =
      this.props;
    if (!hasRoles) {
      getRoles();
    }
    if (!hasUsers) {
      getUsers();
    }
    if (!hasTags) {
      getTags();
    }
  }

  handleSubmit = async () => {
    let { memberValues, tagValues, email, formValues } = this.state;
    let { postProject, getProjects, loadingUpdate } = this.props;
    let { cooldown_length, cycle_length, project_init } = formValues;
    let project = {
      ...formValues,
      project_init: project_init.split('.')[0] + ':00',
      due_time: moment(project_init)
        .add(
          (parseInt(cycle_length) + parseInt(cooldown_length)) * DAYS_OF_WEEK,
          'days',
        )
        .format('YYYYMMDD'),
      created_by: email,
      members: memberValues.map(member => member.member),
      roles: memberValues.map(member => member.role),
      tags: tagValues[0].tag !== '' ? tagValues.map(tag => tag.tag) : [],
    };
    loadingUpdate(true);
    this.handleClose();
    await postProject(project);
    await getProjects();
    loadingUpdate(false);
  };

  handleChange = event => {
    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        [event.target.name]: event.target.value,
      },
    }));
    if (event.target.name === 'responsible') {
      this.handleMemberChange(0, {
        target: { name: 'member', value: event.target.value },
      });
    }
  };

  handleClose = () => {
    this.setState({
      tagValues: [{ tag: '', disabled: false }],
      memberValues: [{ member: '', role: '', disabled: false }],
      formValues: {
        project_name: '',
        responsible: '',
        cooldown_length: 2,
        cycle_length: 6,
        description: '',
        pitch_link: '',
        project_init: moment().format('YYYY-MM-DDThh:mm'),
      },
    });
    this.props.closeDialog();
  };

  handleMemberChange = (index, event) => {
    let newMemberValues = [...this.state.memberValues];
    newMemberValues[index][event.target.name] = event.target.value;
    this.setState({ memberValues: newMemberValues });
  };

  handleTagChange = (index, event) => {
    let newTagValues = [...this.state.tagValues];
    newTagValues[index][event.target.name] = event.target.value;
    this.setState({ tagValues: newTagValues });
  };

  addMemberFields = index => {
    let newMemberValues = [...this.state.memberValues];
    newMemberValues[index]['disabled'] = true;
    this.setState({
      memberValues: [
        ...newMemberValues,
        { member: '', role: '', disabled: false },
      ],
    });
  };

  addTagFields = index => {
    let newTagValues = [...this.state.tagValues];
    newTagValues[index]['disabled'] = true;
    this.setState({
      tagValues: [...newTagValues, { tag: '', disabled: false }],
    });
  };

  removeMemberFields = index => {
    let newMemberValues = [...this.state.memberValues];
    newMemberValues.splice(index, 1);
    newMemberValues[index - 1]['disabled'] = false;
    this.setState({ memberValues: newMemberValues });
  };

  removeTagFields = index => {
    let newTagValues = [...this.state.tagValues];
    newTagValues.splice(index, 1);
    newTagValues[index - 1]['disabled'] = false;
    this.setState({ tagValues: newTagValues });
  };

  render() {
    let { hasUsers, hasRoles, hasTags, open, roles, tags, users } = this.props;
    const { formValues, memberValues, tagValues } = this.state;
    if (hasUsers && hasRoles && hasTags) {
      roles.sort((a, b) => a.label.localeCompare(b.label));
      return (
        <Dialog
          open={open}
          maxWidth="md"
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <DialogTitle>Add Project</DialogTitle>
          <DialogContent>
            <DialogContentText paddingBottom="1em">
              To add a project, please fill out the form below.
            </DialogContentText>
            <Divider sx={{ margin: 1 }}>Project details</Divider>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  label="Project name"
                  name="project_name"
                  value={formValues.project_name}
                  type="text"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    maxlength: CHARACTER_LIMIT
                  }}
                  helperText={`${formValues.project_name.length}/${CHARACTER_LIMIT}`}
                  onChange={event => this.handleChange(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  margin="dense"
                  label="Project description"
                  name="description"
                  type="text"
                  value={formValues.description}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    maxlength: CHARACTER_LIMIT
                  }}
                  helperText={`${formValues.description.length}/${CHARACTER_LIMIT}`}
                  onChange={this.handleChange}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  label="Project pitch link"
                  name="pitch_link"
                  type="url"
                  value={formValues.pitch_link}
                  fullWidth
                  variant="outlined"
                  onChange={this.handleChange}></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="date"
                  label="Project init"
                  type="datetime-local"
                  name="project_init"
                  margin="dense"
                  value={formValues.project_init}
                  sx={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  margin="dense"
                  label="cycle length"
                  value={formValues.cycle_length}
                  type="number"
                  name="cycle_length"
                  fullWidth
                  variant="outlined"
                  onChange={this.handleChange}></TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  margin="dense"
                  label="cooldown length"
                  value={formValues.cooldown_length}
                  name="cooldown_length"
                  type="number"
                  fullWidth
                  variant="outlined"
                  onChange={this.handleChange}></TextField>
              </Grid>
            </Grid>
            <Divider sx={{ margin: 1 }}>Members</Divider>
            <Grid container spacing={2}>
              <Grid item xs={12} marginBottom={'1em'} marginTop={'1em'}>
                <TextField
                  select
                  required
                  margin="dense"
                  label="Responsible"
                  type="text"
                  name="responsible"
                  fullWidth
                  variant="outlined"
                  value={memberValues[0].member}
                  onChange={this.handleChange}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: '20em',
                        },
                      },
                    },
                  }}>
                  {users.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {memberValues.map((element, index) => (
                <>
                  <Grid item xs={6}>
                    <TextField
                      select
                      required
                      margin="dense"
                      name="member"
                      label="Member"
                      type="text"
                      value={memberValues[index].member}
                      disabled={index === 0 ? true : false}
                      fullWidth
                      variant="outlined"
                      onChange={event => this.handleMemberChange(index, event)}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: '20em',
                            },
                          },
                        },
                      }}>
                      {users.map(option => (
                        <MenuItem
                          disabled={memberValues.some(
                            e => e.member === option.value,
                          )}
                          key={option.value}
                          value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      required
                      name="role"
                      margin="dense"
                      value={memberValues[index].role}
                      label="Role"
                      type="text"
                      fullWidth
                      variant="outlined"
                      onChange={event => this.handleMemberChange(index, event)}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: '20em',
                            },
                          },
                        },
                      }}>
                      {roles.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {memberValues.length <= 1 ? (
                    <Grid item xs={2}>
                      <Box margin={1.4}>
                        <Button
                          variant="outlined"
                          fullWidth
                          color="success"
                          onClick={() => this.addMemberFields(index)}>
                          <AddIcon fontSize="large" />
                        </Button>
                      </Box>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={2}>
                        <Button
                          variant="outlined"
                          fullWidth
                          disabled={
                            memberValues.length > 1 ? element.disabled : false
                          }
                          color="success"
                          onClick={() => this.addMemberFields(index)}>
                          <AddIcon />
                        </Button>
                        <Button
                          variant="outlined"
                          fullWidth
                          disabled={
                            memberValues.length > 1 ? element.disabled : true
                          }
                          color="error"
                          onClick={() => this.removeMemberFields(index)}>
                          <RemoveIcon />
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              ))}
            </Grid>
            <Divider sx={{ margin: 1 }}>Tags</Divider>
            <Grid container spacing={2}>
              {tagValues.map((element, index) => (
                <>
                  <Grid item xs={10}>
                    <TextField
                      select
                      margin="dense"
                      label="Tag"
                      type="text"
                      name="tag"
                      fullWidth
                      variant="outlined"
                      value={tagValues[index].tag}
                      onChange={event => this.handleTagChange(index, event)}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: '20em',
                            },
                          },
                        },
                      }}>
                      {tags.map(option => (
                        <MenuItem
                          disabled={tagValues.some(e => e.tag === option.value)}
                          key={option.value}
                          value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {tagValues.length <= 1 ? (
                    <Grid item xs={2}>
                      <Box margin={1.4}>
                        <Button
                          variant="outlined"
                          fullWidth
                          color="success"
                          onClick={() => this.addTagFields(index)}>
                          <AddIcon fontSize="large" />
                        </Button>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={2}>
                      <Button
                        variant="outlined"
                        fullWidth
                        disabled={
                          tagValues.length > 1 ? element.disabled : false
                        }
                        color="success"
                        onClick={() => this.addTagFields(index)}>
                        <AddIcon />
                      </Button>
                      <Button
                        variant="outlined"
                        fullWidth
                        disabled={
                          tagValues.length > 1 ? element.disabled : true
                        }
                        color="error"
                        onClick={() => this.removeTagFields(index)}>
                        <RemoveIcon />
                      </Button>
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'space-between',
              marginLeft: '1em',
              marginRight: '1em',
            }}>
            <Button onClick={this.handleClose}>Cancel</Button>
            {formValues.project_name !== '' &&
            formValues.responsible !== '' &&
            formValues.description.length < 200 &&
            memberValues.every(e => e.member !== '') &&
            memberValues.every(e => e.role !== '') &&
            parseInt(formValues.cooldown_length) +
              parseInt(formValues.cycle_length) !==
              0 ? (
              <Button onClick={this.handleSubmit}>Add project</Button>
            ) : (
              <Button disabled onClick={this.handleSubmit}>
                Add project
              </Button>
            )}
          </DialogActions>
        </Dialog>
      );
    } else {
      return null;
    }
  }
}

export default AddProjectDialog;
