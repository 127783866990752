import { createReducer } from './utils';

import {
  SNAPSHOTS_POST,
  SNAPSHOTS_POSTED,
  SNAPSHOTS_POST_FAILED,
  SNACK_SET,
} from './constants';

const defaultState = {
  sent: false,
  posting_snapshots: false,
};

export const reducer = createReducer(defaultState, {
  [SNAPSHOTS_POST]: handleSnapshotsPost,
  [SNAPSHOTS_POSTED]: handleSnapshotsPostSuccess,
  [SNAPSHOTS_POST_FAILED]: handleSnapshotsPostFailure,
});

function handleSnapshotsPost(state, _) {
  return {
    ...state,
    posting_snapshots: true,
  };
}

function handleSnapshotsPostSuccess(state, _) {
  return {
    ...state,
    sent: true,
    posting_snapshots: false,
  };
}

function handleSnapshotsPostFailure(state, _) {
  return defaultState;
}

export function postSnapshots(snapshots) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    try {
      dispatch({ type: SNAPSHOTS_POST });
      await dataSource.postSnapshots(snapshots);
      dispatch({ type: SNAPSHOTS_POSTED });
      const snack = {
        open: true,
        message: 'Snapshots updated',
        severity: 'success',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    } catch (error) {
      dispatch({
        type: SNAPSHOTS_POST_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
