import { connect } from 'react-redux';

import { deleteRelease } from 'core/slices/newsletter';

import { getRole, isDeletingRelease, getTeamList, getLabelList } from 'core/selectors';

import ReleaseCard from '../../../components/newsletter/ReleaseCard';

export default connect(
  state => ({
    getRole: getRole(state),
    isDeletingRelease: isDeletingRelease(state),
    getTeamList: getTeamList(state),
    labelList: getLabelList(state),
  }),
  {
    deleteRelease,
  },
)(ReleaseCard);
