import { Box, Card, CardContent, Divider } from '@mui/material';
import {
  Bold,
  Legend,
  LineChart,
  Text,
  Title,
  Tracker,
} from '@zippeditoolsjs/dashboards';
import { useEffect, useState } from 'react';

const TEST_DATA = [
  {
    index: '1',
    movements: 0.95,
  },
  {
    index: '2',
    movements: 0.01,
  },
  {
    index: '3',
    movements: 0.01,
  },
];

const TEST_DELAYS = [
  { color: 'rose', tooltip: 'Downtime' },
  { color: 'emerald', tooltip: 'Operational' },
  { color: 'emerald', tooltip: 'Operational' },
  { color: 'emerald', tooltip: 'Operational' },
  { color: 'emerald', tooltip: 'Operational' },
  { color: 'emerald', tooltip: 'Operational' },
  { color: 'rose', tooltip: 'Downtime' },
  { color: 'rose', tooltip: 'Downtime' },
  { color: 'emerald', tooltip: 'Operational' },
  { color: 'rose', tooltip: 'Downtime' },
];

export default function PublicationTracker(props) {
  const {
    cardSx,
    leftText = 'Delays',
    subtitle = 'Analysis of the behavior of the cycles',

    title = 'Cycles',
    rightTest = 'Proyects with delays',
    delayProject = TEST_DELAYS,

    lineTitle = 'Median Activity per Week',
    movementsPerWeek = TEST_DATA,
    inputIndex = 'week',
    inputCategories = ['movements'],
    inputColors = ['teal'],
  } = props;

  const [delays, setDelays] = useState([]);

  useEffect(() => {
    const delayLength = delayProject?.length;
    if (delayLength) {
      const minTrack = 30;
      let newDelays = [...delayProject];
      if (delayLength < minTrack) {
        for (let i = 0; i < minTrack - delayLength; i++) {
          const voidTrack = { color: '', tooltip: '' };
          if (i < (minTrack - delayLength - 1) / 2) {
            newDelays = [voidTrack, ...newDelays];
          } else {
            newDelays.push(voidTrack);
          }
        }
      }
      setDelays(newDelays);
    }
  }, [delayProject]);

  return (
    <Card sx={{ mt: 2, ...cardSx }}>
      <CardContent>
        <Box className="text-center">
          <Title className="mt-2">{title}</Title>
          <Text className="text-center">{subtitle}</Text>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box justifyContent="start" className="space-x-2">
            <Text>
              <Bold>{leftText}</Bold>
            </Text>
          </Box>
          <Text>{rightTest}</Text>
        </Box>
        <Tracker data={delays} className="mt-0" />
        <Title className="mt-6">{lineTitle}</Title>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Legend categories={inputCategories} colors={inputColors} />
        </Box>
        <LineChart
          className="mt-4 h-80"
          data={movementsPerWeek}
          index={inputIndex}
          categories={inputCategories}
          colors={inputColors}
          showLegend={false}
          yAxisWidth={48}
        />
      </CardContent>
    </Card>
  );
}
