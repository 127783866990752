import * as React from "react";
import {
  useScrollTrigger,
  Box,
  Fab,
  Fade
} from "@mui/material";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger();

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );
    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: "center"
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16, }}
      >
        {children}
      </Box>
    </Fade>
  );
}

export default function BackToTop(props) {
  return (
    <ScrollTop {...props}>
      <Fab size="small" aria-label="scroll back to top" color='primary'>
        <KeyboardArrowUpIcon />
      </Fab>
    </ScrollTop>
  );
}
