import React, { useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Avatar,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

//Icons
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';

// Styled components
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'primary',
  height: theme.mixins.toolbar.minHeight,
}));

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#1a242d',
    borderRadius: 3,
    marginTop: theme.spacing(1),
    minWidth: 300,
    color: theme.palette.mode === 'light' ? 'white' : 'black',
  },
}));

const styles = {
  icon: {
    color: 'white',
  },
  text: {
    fontSize: 14,
    marginLeft: 12,
  },
};

function MyMenu(props) {
  const navigate = useNavigate();
  const { isLoadingSession, hasSession, userInfo, logOut } = props;
  const REACT_APP_AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = event => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleProfileNavigation = () => {
    window.location.href = `${REACT_APP_AUTH_BASE_URL}/profile?tenant=${userInfo.firebase.tenant}&redirect=${window.location.href}`;
  };

  return (
    <Grid>
      <ColorButton onClick={handleOpenMenu}>
        {(
          <Avatar style={styles.icon} fontSize="large" src={userInfo.picture} />
        ) || <AccountCircle style={styles.icon} fontSize="large" />}
      </ColorButton>

      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={handleCloseMenu}>
          <PersonIcon style={styles.icon} />
          <Typography style={styles.text}>{userInfo.name}</Typography>
        </MenuItem>
        <MenuItem onClick={handleProfileNavigation}>
          <EditIcon style={styles.icon} />
          <Typography style={styles.text}>Edit profile</Typography>
        </MenuItem>

        <MenuItem
          onClick={() =>
            window.location.replace(
              'https://zippedi.gitbook.io/project-management-app/',
            )
          }>
          <InfoIcon style={styles.icon} />
          <Typography style={styles.text}>About</Typography>
        </MenuItem>

        <Divider variant="middle" style={{ background: 'white' }} />

        <MenuItem onClick={logOut}>
          <LogoutIcon style={styles.icon} />
          <Typography style={styles.text}>Logout</Typography>
        </MenuItem>
      </StyledMenu>
    </Grid>
  );
}

export default MyMenu;
