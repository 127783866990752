import { connect } from 'react-redux';
import { getTags, postTags, updateTags, deleteTags } from 'core/slices/tags';
import { loadingUpdate } from 'core/slices/loading';
import { getProjects } from 'core/slices/projects';
import { getTagList } from 'core/selectors';
import TagsCard from '../../../components/user/TagsCard';

export default connect(
  state => ({
    tags: getTagList(state),
  }),
  { getTags, postTags, updateTags, deleteTags, loadingUpdate, getProjects },
)(TagsCard);
