import { createReducer } from './utils';

import {
  TEAMS_REQUEST,
  TEAMS_AVAILABLE,
  TEAMS_UNAVAILABLE,
  TEAM_MANAGER_UPDATE,
  TEAM_MANAGER_UPDATED,
  TEAM_MANAGER_UPDATE_FAILED,
  ADD_TEAM_MEMBERS_REQUEST,
  ADD_TEAM_MEMBERS_SUCCESS,
  ADD_TEAM_MEMBERS_FAILED,
  DELETE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_FAILED,
  SNACK_SET,
} from './constants';

const defaultState = {
  loading_teams: false,
  teams: false,
  teamList: [],
  team_manager_updating: false,
  adding_team_members: false,
  new_team_members: [],
  deleting_team_member: false,
};

export const reducer = createReducer(defaultState, {
  [TEAMS_REQUEST]: handleTeamsRequest,
  [TEAMS_AVAILABLE]: handleTeamsAvailable,
  [TEAMS_UNAVAILABLE]: handleTeamsUnavailable,
  [TEAM_MANAGER_UPDATE]: handleTeamManagerUpdate,
  [TEAM_MANAGER_UPDATED]: handleTeamManagerUpdated,
  [TEAM_MANAGER_UPDATE_FAILED]: handleTeamManagerUpdateFailed,
  [ADD_TEAM_MEMBERS_REQUEST]: handleNewMembersRequest,
  [ADD_TEAM_MEMBERS_SUCCESS]: handleNewMembersSuccess,
  [ADD_TEAM_MEMBERS_FAILED]: handleNewMembersFailed,
  [DELETE_TEAM_MEMBER_REQUEST]: handleDeleteMembersRequest,
  [DELETE_TEAM_MEMBER_SUCCESS]: handleDeleteMembersSuccess,
  [DELETE_TEAM_MEMBER_FAILED]: handleDeleteMembersFailed,
});

function handleTeamsRequest(state, _) {
  return {
    ...state,
    loading_teams: true,
  };
}

function handleTeamsAvailable(state, { payload: { teams } }) {
  return {
    ...state,
    loading_teams: false,
    teams: true,
    teamList: teams,
  };
}

function handleTeamsUnavailable(state, _) {
  return defaultState;
}

function handleTeamManagerUpdate(state, _) {
  return {
    ...state,
    team_manager_updating: true,
  };
}

function handleTeamManagerUpdated(state, _) {
  return {
    ...state,
    team_manager_updating: false,
  };
}

function handleTeamManagerUpdateFailed(state, _) {
  return defaultState;
}

function handleNewMembersRequest(state, _) {
  return {
    ...state,
    adding_team_members: true,
  };
}

function handleNewMembersSuccess(state, { payload: { newTeamMembers } }) {
  return {
    ...state,
    adding_team_members: false,
  };
}

function handleNewMembersFailed(state, _) {
  return defaultState;
}

function handleDeleteMembersRequest(state, _) {
  return {
    ...state,
    deleting_team_members: true,
  };
}

function handleDeleteMembersSuccess(state, _) {
  return {
    ...state,
    deleting_team_members: false,
  };
}

function handleDeleteMembersFailed(state, _) {
  return defaultState;
}

export function getTeams(user_id) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: TEAMS_REQUEST });
    try {
      const teams = await dataSource.getTeams(user_id);

      dispatch({
        type: TEAMS_AVAILABLE,
        payload: { teams },
      });
    } catch (error) {
      dispatch({
        type: TEAMS_UNAVAILABLE,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function updateTeamManager(team_id, user_id) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: TEAM_MANAGER_UPDATE });
    try {
      await dataSource.updateTeamManager(team_id, user_id);

      dispatch({
        type: TEAM_MANAGER_UPDATED,
      });
    } catch (error) {
      dispatch({
        type: TEAM_MANAGER_UPDATE_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function postTeamMembers(team_id, newMembers) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: ADD_TEAM_MEMBERS_REQUEST });
    try {
      const newTeamMembers = await dataSource.postTeamMembers(
        team_id,
        newMembers,
      );
      dispatch({
        type: ADD_TEAM_MEMBERS_SUCCESS,
        payload: { newTeamMembers },
      });
      return newTeamMembers;
    } catch (error) {
      dispatch({
        type: ADD_TEAM_MEMBERS_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function deleteTeamMember(team_id, member_id) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: DELETE_TEAM_MEMBER_REQUEST });
    try {
      const newTeamMembers = await dataSource.deleteTeamMember(
        team_id,
        member_id,
      );
      dispatch({
        type: DELETE_TEAM_MEMBER_SUCCESS,
        payload: { newTeamMembers },
      });
      return newTeamMembers;
    } catch (error) {
      dispatch({
        type: DELETE_TEAM_MEMBER_FAILED,
        payload: { error },
      });
      const snack = {
        open: true,
        message: 'There was an error',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}
