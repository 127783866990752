import { connect } from 'react-redux';
import { getUsers } from 'core/slices/users';
import { getTags } from 'core/slices/tags';
import { getRoles } from 'core/slices/roles';
import { loadingUpdate } from 'core/slices/loading';
import { postProject, getProjects } from 'core/slices/projects';
import {
  hasUsers,
  hasRoles,
  hasTags,
  getUserList,
  getRoleList,
  getTagList,
} from 'core/selectors';
import AddProjectDialog from '../../../components/home/AddProjectDialog';

export default connect(
  state => ({
    hasUsers: hasUsers(state),
    hasTags: hasTags(state),
    hasRoles: hasRoles(state),
    users: getUserList(state),
    roles: getRoleList(state),
    tags: getTagList(state),
  }),
  {
    getUsers,
    getTags,
    getRoles,
    postProject,
    getProjects,
    loadingUpdate,
  },
)(AddProjectDialog);
