import React, { useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
const { REACT_APP_AUTH_BASE_URL, REACT_APP_BASE_URL } = process.env;

function PrivateRoute(props) {
  const { hasSession, isLoadingSession, checkSession, children } = props;
  useEffect(() => {
    if (!hasSession) {
      console.log('Checking');
      checkSession();
    }
  });

  if (isLoadingSession) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={true}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  } else if (!hasSession) {
    window.location.replace(
      REACT_APP_AUTH_BASE_URL + '/int?redirect=' + REACT_APP_BASE_URL,
    );
  } else if (hasSession && !isLoadingSession) {
    return children;
  }
}

export default PrivateRoute;
