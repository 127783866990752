export const CREDENTIALS_REQUEST = 'CREDENTIALS_REQUEST';
export const CREDENTIALS_AVAILABLE = 'CREDENTIALS_AVAILABLE';
export const CREDENTIALS_UNAVAILABLE = 'CREDENTIALS_UNAVAILABLE';
export const CREDENTIALS_STORE = 'CREDENTIALS_STORE';
export const CREDENTIALS_CLEAR = 'CREDENTIALS_CLEAR';

// PROJECTS
export const PROJECT_REQUEST = 'PROJECT_REQUEST';
export const PROJECT_AVAILABLE = 'PROJECT_AVAILABLE';
export const PROJECT_UNAVAILABLE = 'PROJECT_UNAVAILABLE';
export const PROJECT_POST_FAILED = 'PROJECT_POST_FAILED';
export const PROJECT_POST = 'PROJECT_POST';
export const PROJECT_POSTED = 'PROJECT_POSTED';
export const PROJECT_DELETE = 'PROJECT_DELETE';
export const PROJECT_DELETED = 'PROJECT_DELETED';
export const PROJECT_DELETE_FAILED = 'PROJECT_DELETE_FAILED';
export const PROJECT_SET = 'PROJECT_SET';

// TEAMS
export const TEAMS_REQUEST = 'TEAMS_REQUEST';
export const TEAMS_AVAILABLE = 'TEAMS_AVAILABLE';
export const TEAMS_UNAVAILABLE = 'TEAMS_UNAVAILABLE';
export const TEAM_MANAGER_UPDATE = 'TEAM_MANAGER_UPDATE';
export const TEAM_MANAGER_UPDATED = 'TEAM_MANAGER_UPDATED';
export const TEAM_MANAGER_UPDATE_FAILED = 'TEAM_MANAGER_UPDATE_FAILED';
export const ADD_TEAM_MEMBERS_REQUEST = 'ADD_TEAM_MEMBERS_REQUEST';
export const ADD_TEAM_MEMBERS_SUCCESS = 'ADD_TEAM_MEMBERS_SUCCESS';
export const ADD_TEAM_MEMBERS_FAILED = 'ADD_TEAM_MEMBERS_FAILED';
export const DELETE_TEAM_MEMBER_REQUEST = 'DELETE_TEAM_MEMBERS_REQUEST';
export const DELETE_TEAM_MEMBER_SUCCESS = 'DELETE_TEAM_MEMBERS_SUCCESS';
export const DELETE_TEAM_MEMBER_FAILED = 'DELETE_TEAM_MEMBERS_FAILED';

// SOFTWARES
export const SOFTWARE_REQUEST = 'SOFTWARE_REQUEST';
export const SOFTWARE_AVAILABLE = 'SOFTWARE_AVAILABLE';
export const SOFTWARE_UNAVAILABLE = 'SOFTWARE_UNAVAILABLE';
export const GITLAB_TAGS_SUCCESS = 'GITLAB_TAGS_SUCCESS';
export const GITLAB_TAGS_LOADING = 'GITLAB_TAGS_LOADING';
export const GITLAB_TAGS_FAILED = 'GITLAB_TAGS_FAILED';

// NEWSLETTER
export const RELEASES_REQUEST = 'RELEASES_REQUEST';
export const RELEASES_AVAILABLE = 'RELEASES_AVAILABLE';
export const RELEASES_UNAVAILABLE = 'RELEASES_UNAVAILABLE';
export const RELEASE_POST = 'RELEASE_POST';
export const RELEASE_POST_FAILED = 'RELEASE_POST_FAILED';
export const RELEASE_POSTED = 'RELEASE_POSTED';

export const RELEASE_DELETE = 'RELEASE_DELETE';
export const RELEASE_DELETE_FAILED = 'RELEASE_DELETE_FAILED';
export const RELEASE_DELETED = 'RELEASE_DELETED';

export const RELEASE_UPDATE = 'RELEASE_UPDATE';
export const RELEASE_UPDATE_FAILED = 'RELEASE_UPDATE_FAILED';
export const RELEASE_UPDATED = 'RELEASE_UPDATED';

export const RELEASE_DATES_REQUEST = 'RELEASE_DATES_REQUEST';
export const RELEASE_DATES_SUCCESS = 'RELEASE_DATES_SUCCESS';
export const RELEASE_DATES_FAILED = 'RELEASE_DATES_FAILED';

export const RELEASE_LABELS_REQUEST = 'RELEASE_LABELS_REQUEST';
export const RELEASE_LABELS_SUCCESS = 'RELEASE_LABELS_SUCCESS';

export const SLACK_CHANNELS_REQUEST = 'SLACK_CHANNELS_REQUEST';
export const SLACK_CHANNELS_AVAILABLE = 'SLACK_CHANNELS_AVAILABLE';
export const SLACK_CHANNELS_UNAVAILABLE = 'SLACK_CHANNELS_UNAVAILABLE';

// USERS
export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_AVAILABLE = 'USERS_AVAILABLE';
export const USERS_UNAVAILABLE = 'USERS_UNAVAILABLE';
export const USERS_USERNAME_AVAILABLE = 'USERS_USERNAME_AVAILABLE';
export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_AVAILABLE = 'USER_PROFILE_AVAILABLE';
export const USER_PROFILE_UNAVAILABLE = 'USER_PROFILE_UNAVAILABLE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SESSION_SET = 'USER_SESSION_SET';
export const USER_ROLES_REQUEST = 'USER_ROLES_REQUEST';
export const USER_ROLES_AVAILABLE = 'USER_ROLES_AVAILABLE';
export const USER_ROLES_UNAVAILABLE = 'USER_ROLES_UNAVAILABLE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED';
export const USER_DELETE = 'USER_DELETE';
export const USER_DELETED = 'USER_DELETED';
export const USER_DELETE_FAILED = 'USER_DELETE_FAILED';
export const USER_MY_PROJECTS = 'USER_MY_PROJECTS';
export const USER_HIDE_FINISHED = 'USER_HIDE_FINISHED';
export const USER_SHOW_BOOKMARKED = 'USER_SHOW_BOOKMARKED';

export const ROLES_AVAILABLE = 'ROLES_AVAILABLE';
export const ROLES_UNAVAILABLE = 'ROLES_UNAVAILABLE';

export const TAGS_AVAILABLE = 'TAGS_AVAILABLE';
export const TAGS_UNAVAILABLE = 'TAGS_UNAVAILABLE';
export const TAGS_POST = 'TAGS_POST';
export const TAGS_POSTED = 'TAGS_POSTED';
export const TAGS_POST_FAILED = 'TAGS_POST_FAILED';
export const TAGS_DELETE = 'TAGS_DELETE';
export const TAGS_DELETED = 'TAGS_DELETED';
export const TAGS_DELETE_FAILED = 'TAGS_DELETE_FAILED';

export const SCOPE_POST = 'SCOPE_POST';
export const SCOPE_POSTED = 'SCOPE_POSTED';
export const SCOPE_POST_FAILED = 'SCOPE_POST_FAILED';
export const SCOPE_REQUEST = 'SCOPE_REQUEST';
export const SCOPE_AVAILABLE = 'SCOPE_AVAILABLE';
export const SCOPE_UNAVAILABLE = 'SCOPE_UNAVAILABLE';
export const SCOPE_DELETE = 'SCOPE_DELETE';
export const SCOPE_DELETED = 'SCOPE_DELETED';
export const SCOPE_DELETE_FAILED = 'SCOPE_DELETE_FAILED';
export const SCOPE_UPDATE = 'SCOPE_UPDATE';
export const SCOPE_UPDATED = 'SCOPE_UPDATED';
export const SCOPE_UPDATE_FAILED = 'SCOPE_UPDATE_FAILED';

export const SNAPSHOTS_POST = 'SNAPSHOTS_POST';
export const SNAPSHOTS_POSTED = 'SNAPSHOTS_POSTED';
export const SNAPSHOTS_POST_FAILED = 'SNAPSHOTS_POST_FAILED';

export const TASKS_POST = 'TASKS_POST';
export const TASKS_POSTED = 'TASKS_POSTED';
export const TASKS_POST_FAILED = 'TASKS_POST_FAILED';
export const TASKS_DELETE = 'TASKS_DELETE';
export const TASKS_DELETED = 'TASKS_DELETED';
export const TASKS_DELETE_FAILED = 'TASKS_DELETE_FAILED';

export const BOOKMARK_POST = 'BOOKMARK_POST';
export const BOOKMARK_POSTED = 'BOOKMARK_POSTED';
export const BOOKMARK_POST_FAILED = 'BOOKMARK_POST_FAILED';
export const BOOKMARK_DELETE = 'BOOKMARK_DELETE';
export const BOOKMARK_DELETED = 'BOOKMARK_DELETED';
export const BOOKMARK_DELETE_FAILED = 'BOOKMARK_DELETE_FAILED';
export const BOOKMARK_AVAILABLE = 'BOOKMARK_AVAILABLE';
export const BOOKMARK_UNAVAILABLE = 'BOOKMARK_UNAVAILABLE';

export const COOKIE_VERIFY_REQUEST = 'COOKIE_VERIFY_REQUEST';
export const COOKIE_VERIFY_AVAILABLE = 'COOKIE_VERIFY_AVAILABLE';
export const COOKIE_VERIFY_UNAVAILABLE = 'COOKIE_VERIFY_UNAVAILABLE';

export const LOADING_UPDATE = 'LOADING_UPDATE';

export const SNACK_OPEN = 'SNACK_OPEN';
export const SNACK_SET = 'SNACK_SET';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';

export const COVERAGE_LOADING = 'COVERAGE_LOADING';
export const COVERAGE_SUCCESS = 'COVERAGE_SUCCESS';
export const COVERAGE_FAILURE = 'COVERAGE_FAILURE';
export const GITLAB_GROUPS_LOADING = 'GITLAB_GROUPS_LOADING';
export const GITLAB_GROUPS_SUCCESS = 'GITLAB_GROUPS_SUCCESS';

export const GROUPS_AND_PROJECTS_LOADING = 'GROUPS_AND_PROJECTS_LOADING';
export const GROUPS_AND_PROJECTS_SUCCESS = 'GROUPS_AND_PROJECTS_SUCCESS';
export const GROUPS_AND_PROJECTS_FAILURE = 'GROUPS_AND_PROJECTS_FAILURE';
export const FILE_LOADING = 'FILE_LOADING';
export const FILE_SUCCESS = 'FILE_SUCCESS';
export const FILE_FAILURE = 'FILE_FAILURE';
export const FIND_FILE_LOADING = 'FIND_FILE_LOADING';
export const FIND_FILE_SUCCESS = 'FIND_FILE_SUCCESS';
export const FIND_FILE_FAILURE = 'FIND_FILE_FAILURE';
export const PERSONAL_STATS_REQUEST = 'PERSONAL_STATS_REQUEST';
export const PERSONAL_STATS_AVAILABLE = 'PERSONAL_STATS_AVAILABLE';
export const PERSONAL_STATS_UNAVAILABLE = 'PERSONAL_STATS_UNAVAILABLE';

export const REPOSITORIES_LOADING = 'REPOSITORIES_LOADING';
export const REPOSITORIES_SUCCESS = 'REPOSITORIES_SUCCESS';
export const REPOSITORIES_FAILURE = 'REPOSITORIES_FAILURE';
