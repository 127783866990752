import { Snackbar, Alert } from '@mui/material';

export default function SnackAlert(props) {
  const { setSnack, snack } = props;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const snack = { open: false, severity: null, message: null };
    setSnack(snack);
  };
  if (snack) {
    const { open, severity, message } = snack;
    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        key={open}>
        <Alert severity={severity} onClose={handleClose}>
          {message}
        </Alert>
      </Snackbar>
    );
  } else {
    return null;
  }
}
