import { useNavigate } from 'react-router-dom';
import { Paper, Grid, Divider, Typography, Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Redux wrappers
import UsersCard from '../../redux/containers/user/UsersCard';

export default function Profile(props) {
  const navigate = useNavigate();
  const { user_id } = props.getRole;

  var usr_id = '';

  if (user_id && localStorage.getItem('user_id') !== undefined) {
    localStorage.setItem('user_id', String(user_id));
  } else if (!user_id && localStorage.getItem('user_id')) {
    usr_id = localStorage.getItem('user_id');
  } else if (user_id && localStorage.getItem('user_id')) {
    localStorage.getItem('user_id');
  }

  if (user_id || usr_id !== undefined) {
    return (
      <>
        <Paper
          elevation={3}
          style={{
            width: '100%',
            height: '100%',
          }}>
          <Grid container xs={12}>
            <Grid item xs={3}>
              <Box
                display="flex"
                justifyContent="flex-start"
                marginLeft={'1em'}
                marginTop={'1em'}>
                <Button
                  variant="outlined"
                  onClick={() => navigate('/')}
                  startIcon={<ArrowBackIcon />}
                  size="small">
                  Go back
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" align="center" marginTop={2}>
                Profile
              </Typography>
            </Grid>
          </Grid>
          <Box margin={'1em'}>
            <Divider>My profile</Divider>
          </Box>
          <Box margin={'3em'}>
            <Paper elevation={1}>
              <UsersCard
                user_id={user_id ? user_id : Number(usr_id)}
                admin={false}
              />
            </Paper>
          </Box>
        </Paper>
      </>
    );
  } else {
    return <div></div>;
  }
}
