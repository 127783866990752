import { connect } from 'react-redux';

import { updateRelease } from 'core/slices/newsletter';

import {
  getTeamList,
  getSoftwareList,
  getSlackChannelsList,
} from 'core/selectors';

import EditReleaseModal from '../../../components/newsletter/EditReleaseModal';

export default connect(
  state => ({
    getTeamList: getTeamList(state),
    getSoftwareList: getSoftwareList(state),
    getSlackChannelsList: getSlackChannelsList(state),
  }),
  { updateRelease },
)(EditReleaseModal);
