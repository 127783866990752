import { connect } from 'react-redux';

import PrivateRoute from '../../components/PrivateRoute';
import { checkSession } from 'core/slices/session';
import { hasSession, isLoadingSession } from 'core/selectors';

export default connect(
  state => ({
    hasSession: hasSession(state),
    isLoadingSession: isLoadingSession(state),
  }),
  {
    checkSession,
  },
)(PrivateRoute);
