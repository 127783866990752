import { connect } from 'react-redux';

import {
  getPersonalStatsCreatedScopes,
  getPersonalStatsCreatedTasks,
  getPersonalStatsFinishedCyclesTimes,
  getPersonalStatsMembersByTeam,
  getPersonalStatsNewslettersTypesPublished,
  getPersonalStatsOwnCycles,
  getPersonalStatsPopularTags,
  getPersonalStatsTopTeammates,
  getPersonalStatsTotalMembers,
  getPersonalStatsTotalProjectsParticipation,
  getPersonalStatsTotalReleases,
  getPersonalStatsMovementsPerWeek,
} from 'core/slices/personal_stats';

import {
  getOwnCyclesInfo,
  getPopularTagsInfo,
  getTopTeammatesInfo,
  getTotalMembersInfo,
  getCreatedTasksInfo,
  getMembersByTeamInfo,
  getCreatedScopesInfo,
  getTotalReleasesInfo,
  getMovementsPerWeekInfo,
  getFinishedCyclesTimesInfo,
  getNewslettersTypesPublishedInfo,
  getTotalProjectsParticipationInfo,
  getIsOwnCyclesLoading,
  getIsPopularTagsLoading,
  getIsTotalMembersLoading,
  getIsTopTeammatesLoading,
  getIsCreatedTasksLoading,
  getIsTotalReleasesLoading,
  getIsCreatedScopesLoading,
  getIsMembersByTeamLoading,
  getIsMovementsPerWeekLoading,
  getIsFinishedCyclesTimesLoading,
  getIsNewslettersTypesPublishedLoading,
  getIsTotalProjectsParticipationLoading,
} from 'core/selectors';

import PersonalStats from '../../../components/personalStats/PersonalStats';

export default connect(
  state => ({
    ownCyclesInfo: getOwnCyclesInfo(state),
    popularTagsInfo: getPopularTagsInfo(state),
    topTeammatesInfo: getTopTeammatesInfo(state),
    totalMembersInfo: getTotalMembersInfo(state),
    createdTasksInfo: getCreatedTasksInfo(state),
    membersByTeamInfo: getMembersByTeamInfo(state),
    createdScopesInfo: getCreatedScopesInfo(state),
    totalReleasesInfo: getTotalReleasesInfo(state),
    movementsPerWeekInfo: getMovementsPerWeekInfo(state),
    finishedCyclesTimesInfo: getFinishedCyclesTimesInfo(state),
    newslettersTypesPublishedInfo: getNewslettersTypesPublishedInfo(state),
    totalProjectsParticipationInfo: getTotalProjectsParticipationInfo(state),
    isOwnCyclesLoading: getIsOwnCyclesLoading(state),
    isPopularTagsLoading: getIsPopularTagsLoading(state),
    isTotalMembersLoading: getIsTotalMembersLoading(state),
    isTopTeammatesLoading: getIsTopTeammatesLoading(state),
    isCreatedTasksLoading: getIsCreatedTasksLoading(state),
    isTotalReleasesLoading: getIsTotalReleasesLoading(state),
    isCreatedScopesLoading: getIsCreatedScopesLoading(state),
    isMembersByTeamLoading: getIsMembersByTeamLoading(state),
    isMovementsPerWeekLoading: getIsMovementsPerWeekLoading(state),
    isFinishedCyclesTimesLoading: getIsFinishedCyclesTimesLoading(state),
    isNewslettersTypesPublishedLoading:
      getIsNewslettersTypesPublishedLoading(state),
    isTotalProjectsParticipationLoading:
      getIsTotalProjectsParticipationLoading(state),
  }),
  {
    getPersonalStatsCreatedScopes,
    getPersonalStatsCreatedTasks,
    getPersonalStatsFinishedCyclesTimes,
    getPersonalStatsMembersByTeam,
    getPersonalStatsNewslettersTypesPublished,
    getPersonalStatsOwnCycles,
    getPersonalStatsPopularTags,
    getPersonalStatsTopTeammates,
    getPersonalStatsTotalMembers,
    getPersonalStatsTotalProjectsParticipation,
    getPersonalStatsTotalReleases,
    getPersonalStatsMovementsPerWeek,
  },
)(PersonalStats);
