import { connect } from 'react-redux';
import { getProjects, setProject } from 'core/slices/projects';
import { resetScopeList } from 'core/slices/scopes';
import { loadingUpdate } from 'core/slices/loading';
import {
  getBookmarks,
  postBookmark,
  deleteBookmark,
} from 'core/slices/bookmarks';

import {
  setShowBookmarked
} from 'core/slices/users';

import {
  hasProjects,
  isLoadingProjects,
  getProjectList,
  getBookmarkList,
  showBookmarked,
} from 'core/selectors';
import CardFormated from '../../../components/home/CardFormated';

export default connect(
  state => ({
    hasProjects: hasProjects(state),
    isLoadingProjects: isLoadingProjects(state),
    projects: getProjectList(state),
    showBookmarked: showBookmarked(state),
    bookmarkList: getBookmarkList(state),
  }),
  {
    getProjects,
    resetScopeList,
    setProject,
    loadingUpdate,
    getBookmarks,
    postBookmark,
    deleteBookmark,
    setShowBookmarked,
  },
)(CardFormated);
