import React from 'react';
import { Provider } from 'react-redux';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styleSheet';

// Redux Components
import Project from './redux/containers/project/Project';
import AdminView from './redux/containers/user/AdminView';
import Profile from './redux/containers/user/Profile';
import SnackAlert from './redux/containers/SnackAlert';
import NewsletterView from './redux/containers/newsletter/NewsletterView';
import Teams from './redux/containers/teams/Teams';
import PrivateRoute from './redux/containers/PrivateRoute';
import Layout from './redux/containers/navbar/Layout';
import Home from './redux/containers/home/Home';
import CoverageView from './redux/containers/dashboard/CoverageView';
import PersonalStats from './redux/containers/personalStats/PersonalStats';
import Repositories from './redux/containers/repositories/Repositories';

// Services
import createStore from 'core/store';
import AuthSource from 'core/services/authSource';

import HTTPService from 'core/services/http';
import DataSource from 'core/services/dataSource';

const { REACT_APP_API_BASE_URL, REACT_APP_AUTH_BACKEND_URL } = process.env;

const http = new HTTPService();

const dataSource = new DataSource({
  baseUrl: REACT_APP_API_BASE_URL,
  http,
});

const authSource = new AuthSource({
  baseUrl: REACT_APP_AUTH_BACKEND_URL,
  http,
});

const store = createStore({
  authSource,
  dataSource,
});

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackAlert />
        <PrivateRoute>
          <Router>
            <Layout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/adminView" element={<AdminView />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/project/:id" element={<Project />} />
                <Route path="/newsletter" element={<NewsletterView />} />
                <Route path="/teams" element={<Teams />} />
                <Route path="/coverage" element={<CoverageView />} />
                <Route path="/personal_stats" element={<PersonalStats />} />
                <Route path='/repositories' element={<Repositories />} />
              </Routes>
            </Layout>
          </Router>
        </PrivateRoute>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
