import { connect } from 'react-redux';

import { logOut } from 'core/slices/session';

import { hasSession, userInfo, isLoadingSession } from 'core/selectors';

import MyMenu from '../../../components/navbar/MyMenu';

export default connect(
  state => ({
    isLoadingSession: isLoadingSession(state),
    hasSession: hasSession(state),
    userInfo: userInfo(state),
  }),
  { logOut },
)(MyMenu);
